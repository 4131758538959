<template>
  <v-app-bar
    v-if="showConfirmUserBar"
    color="warning"
    elevation="0"
    height="40"
  >
    <v-toolbar-title class="white-toolbar-title" align="center">
      <v-icon class="mr-2" color="white" size="small">mdi-alert </v-icon>
      {{ $t('app.pleaseConfirmUser') }}
      {{
        dateFormatter.dateWithoutSeconds(
          dateFormatter.addDaysToDate(loggedInUser.createdAt, 30)
        )
      }}.
      <a class="toolbar-link pointer-cursor" @click="requestConfirmationLink">
        {{ $t('app.requestNewConfirmationLink') }}
      </a>
    </v-toolbar-title>
  </v-app-bar>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';
import { dateFormatter } from '@/util/dateFormatter';

export default defineComponent({
  name: 'ConfirmUserBar',
  data() {
    return {
      dateFormatter
    };
  },
  computed: {
    ...mapState(authenticationStore, ['loggedInUser', 'showConfirmUserBar'])
  },
  methods: {
    ...mapActions(authenticationStore, ['requestConfirmationLink'])
  }
});
</script>
<style lang="scss" scoped>
.toolbar-link {
  color: white;
  text-decoration: underline;
}
</style>
