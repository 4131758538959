<template>
  <v-row class="btn-row" data-cy="icon-button-group">
    <v-col
      v-for="button in mutateableConfig ?? config"
      :key="button.id + keyCounter"
      align-self="center"
      class="btn-col px-2 align"
    >
      <IconButton :config="button" :disabled="button.disabled" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import IconButton from '@/components/common/table/IconButton.vue';
import { IconButtonWithCallback } from '@/components/types/DataTable';
import { eventBus } from '@/main';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconButtonGroup',
  components: {
    IconButton
  },
  props: {
    config: {
      type: Array as () => IconButtonWithCallback[],
      required: true
    }
  },
  data() {
    return {
      mutateableConfig: undefined as IconButtonWithCallback[] | undefined,
      keyCounter: 1
    };
  },
  created() {
    eventBus.$on('updateButtonConfig', (data: IconButtonWithCallback[]) => {
      this.mutateableConfig = data;
      this.keyCounter++;
    });
  },
  unmounted() {
    eventBus.$off('updateButtonConfig');
  }
});
</script>

<style lang="scss" scoped>
.btn-row {
  max-width: fit-content;
  align-content: center;
}

.btn-col {
  max-width: fit-content !important;
}
</style>
