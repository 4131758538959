/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class Deployment {
  constructor({
    uuid = '',
    createdAt = '',
    updatedAt = '',
    name = '',
    description = '',
    targetType = '',
    subject = '',
    method = '',
    target = [],
    updatePacket = {},
    autoUpdate = {},
    cliTemplate = {},
    updateJob = DeploymentUpdateJob
  } = {}) {
    this.uuid = uuid;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.name = name;
    this.description = description;
    this.targetType = targetType;
    this.subject = subject;
    this.method = method;
    this.target = target;
    this.updatePacket = updatePacket;
    this.autoUpdate = autoUpdate;
    this.cliTemplate = cliTemplate;
    this.updateJob = updateJob;
  }
}

export function immutableDeployment(data) {
  return Object.freeze(new Deployment(data));
}

//

export class DeploymentUpdateJob {
  constructor({ schedule = DeploymentUpdateJobSchedule } = {}) {
    this.schedule = schedule;
  }
}

export class DeploymentUpdateJobSchedule {
  constructor({ runNow = false }) {
    this.runNow = runNow;
  }
}
