/* eslint-disable @typescript-eslint/naming-convention */
export const certificateUploadFileTypes = {
  X_PEM_FILE: 'application/x-pem-file',
  X_X509_CA_CERT: 'application/x-x509-ca-cert',
  PKIX_CERT: 'application/pkix-cert',
  X_PKCS12: 'application/x-pkcs12',
  PEM_CERTIFICATE_CHAIN: 'application/pem-certificate-chain'
};

export const certificateAuthoritiesState = {
  CERTIFICATE_AUTHORITY_STATE_ACTIVE: 'ACTIVE'
};
export const certificatesState = {
  CERTIFICATE_STATE_ACTIVE: 'ACTIVE',
  CERTIFICATE_STATE_DEFAULT: '-'
};

export const certificateAuthorityTypes = {
  rootCA: 'ROOT_CA'
};
