/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable max-lines */
import DeploymentApi from '../../services/rollout/DeploymentApi';
import {
  DEPLOYMENT_METHOD_UPDATE_JOB,
  DEPLOYMENT_SUBJECT_UPDATE_PACKET,
  UPDATE_JOB_PROGRESS_FAILED,
  UPDATE_JOB_PROGRESS_PARTIALLY_SUCCEEDED,
  UPDATE_JOB_PROGRESS_READY,
  UPDATE_JOB_PROGRESS_RUNNING,
  UPDATE_JOB_PROGRESS_SUCCEEDED
} from '@/models/rollouts/constants';
import UpdatePacketApi from '../../services/autoupdate/UpdatePacketApi';
import UpdateJobApi from '../../services/rollout/UpdateJobApi';
import {
  DeploymentType,
  ServiceApiPayload,
  ServiceApiType,
  UpdateJobsActionType,
  UpdateJobsStateType,
  UpdateJobType,
  UpdatePacketType,
  UpdateType
} from '@/store/types';
import { immutableUpdatePacket } from '@/models/autoupdate/UpdatePacket';
import deleteTarget from '@/store/modules/updateJobs/deleteTarget';

export default {
  namespaced: true,

  modules: {
    deleteTarget
  },

  // initial state
  state: (): UpdateJobsStateType => ({
    updateJobs: [] as DeploymentType[],
    updatePackets: [] as UpdatePacketType[],

    status: [],

    filteredUpdateJobs: [] as DeploymentType[], // update jobs after filtering them (e.g. location, group)
    visibleUpdateJobs: [] as DeploymentType[], // filtered update jobs + search is applied on filtered devices
    selectedUpdateJobs: [] as DeploymentType[],

    visibilityFilter: false,

    // filter is used against all update jobs to get filtered update jobs
    // filters could be saved to the database
    filter: {
      status: []
    },
    search: ''
  }),

  // getters
  getters: {
    updateJobs(state: UpdateJobsStateType) {
      return state.updateJobs;
    },
    updateJobById: (state: UpdateJobsStateType) => (updateJobId: string) =>
      state.updateJobs?.find((updateJob) => updateJob.uuid === updateJobId),
    visible(state: UpdateJobsStateType) {
      return state.visibleUpdateJobs;
    },
    selected(state: UpdateJobsStateType) {
      return state.selectedUpdateJobs;
    },
    // 'updateJobs/status'
    status(state: UpdateJobsStateType) {
      return state.status;
    },
    filteredStatus(state: UpdateJobsStateType) {
      return state.filter.status;
    },
    showFilter(state: UpdateJobsStateType) {
      return state.visibilityFilter;
    },
    showDetails(state: UpdateJobsStateType) {
      return state.selectedUpdateJobs.length > 0;
    },
    updatePacket: (state: UpdateJobsStateType) => (updatePacketId: string) =>
      state.updatePackets.find(
        (updatePacket) => updatePacket.uuid === updatePacketId
      ),
    lastUpdateJob: (state: UpdateJobsStateType) => (updateJobId: string) => {
      const deployment = state.updateJobs.find(
        (updateJob) => updateJob.uuid === updateJobId
      );

      const mostRecentDate = new Date(
        Math.max.apply(
          null,
          deployment?.updateJob?.updateJobs?.map((updateJob: any) =>
            new Date(
              updateJob.createdAt ? updateJob.createdAt : new Date()
            ).getTime()
          ) as number[]
        )
      );

      return deployment?.updateJob?.updateJobs?.find((updateJob: any) => {
        const d = new Date(
          updateJob.createdAt ? updateJob.createdAt : new Date()
        );
        return d.getTime() === mostRecentDate.getTime();
      });
    },
    search: (state: UpdateJobsStateType) => state.search
  },

  // actions
  actions: {
    load(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit, getters, dispatch, state }: UpdateJobsActionType,
      payload?: ServiceApiPayload
    ) {
      let deploymentService: ServiceApiType<any>;
      let updatePackageService: ServiceApiType<any>;
      if (payload) {
        deploymentService = payload.deploymentService;
        updatePackageService = payload.updatePackageService;
      } else {
        deploymentService = DeploymentApi as unknown as ServiceApiType<any>;
        updatePackageService =
          UpdatePacketApi as unknown as ServiceApiType<any>;
      }

      return (deploymentService ? deploymentService : DeploymentApi)
        .find()
        .then((r) => r.data.members)
        .then((member) => {
          const deployments = member.filter(
            (deployment: DeploymentType) =>
              deployment.method === DEPLOYMENT_METHOD_UPDATE_JOB
          );
          commit('SET_UPDATE_JOBS', deployments);

          const getUpdatePacketPromises: any[] = [];
          state.updateJobs.forEach((deployment) => {
            if (deployment.subject === DEPLOYMENT_SUBJECT_UPDATE_PACKET) {
              getUpdatePacketPromises.push(
                (updatePackageService
                  ? updatePackageService
                  : UpdatePacketApi
                ).getById(deployment?.updatePacket?.commonUpdatePacket || '') // TODO: look at this!
              );
              if (deployment?.updatePacket?.targetUpdatePackets !== undefined) {
                for (const [, value] of Object.entries(
                  deployment.updatePacket?.targetUpdatePackets
                )) {
                  getUpdatePacketPromises.push(
                    (updatePackageService
                      ? updatePackageService
                      : UpdatePacketApi
                    ).getById(value)
                  );
                }
              }
            }
          });

          return Promise.allSettled(getUpdatePacketPromises)
            .then((res) => {
              res.forEach((r) => {
                if (r.status !== 'rejected') {
                  const updatePacket = immutableUpdatePacket(r.value?.data);
                  commit('ADD_UPDATE_PACKET', updatePacket);
                }
              });
            })
            .then(() => {
              commit('RESET_SELECTED');
              commit('RESET_FILTER');
              const updateJobsWithProgress = state.updateJobs.map(
                (updateJob) => {
                  const lastUpdateJob = getters.lastUpdateJob(updateJob.uuid);
                  updateJob.status = lastUpdateJob.progress;
                  updateJob.executedAt = lastUpdateJob.executedAt;
                  return updateJob;
                }
              );
              commit(
                'UPDATE_STATUS',
                state.updateJobs.map((updateJob) => updateJob?.status)
              );
              commit('SET_UPDATE_JOBS', updateJobsWithProgress);
              commit('FILTER_UPDATE_JOBS');
              commit('SEARCH_UPDATE_JOBS', '');
            });
        });
    },
    addSelectedUpdateJobs: (
      { commit }: UpdateJobsActionType,
      data: UpdateJobType[]
    ) => {
      commit('ADD_SELECTED_UPDATE_JOBS', data);
    },
    updateVisibilityFilter: (
      { commit }: UpdateJobsActionType,
      isVisible: boolean
    ) => {
      commit('SET_VISIBILITY_FILTER', isVisible);
    },
    searchUpdateJobs: ({ commit }: UpdateJobsActionType, data: string) => {
      commit('SEARCH_UPDATE_JOBS', data);
    },
    updateFilterStatus: ({ commit }: UpdateJobsActionType, data: string[]) => {
      commit('SET_FILTER_STATUS', data);
      commit('FILTER_UPDATE_JOBS');
    },
    resetFilter: ({ commit }: UpdateJobsActionType) => {
      commit('RESET_FILTER');
      commit('FILTER_UPDATE_JOBS');
    },
    selectUpdateJob: (
      { commit }: UpdateJobsActionType,
      data: UpdateJobType
    ) => {
      commit('ADD_SELECTED_UPDATE_JOB', data);
    },
    unselectUpdateJob: (
      { commit }: UpdateJobsActionType,
      data: UpdateJobType
    ) => {
      commit('REMOVE_SELECTED_UPDATE_JOB', data);
    },
    resetSelected: ({ commit }: UpdateJobsActionType) => {
      commit('RESET_SELECTED_UPDATE_JOBS');
    },
    runUpdateJob: async (
      { dispatch }: UpdateJobsActionType,
      data: UpdateJobType
    ) =>
      UpdateJobApi.run(data).then(() => {
        dispatch('load');
      }),
    retryUpdateJob: async (
      { dispatch }: UpdateJobsActionType,
      data: UpdateJobType
    ) =>
      UpdateJobApi.retry(data).then(() => {
        dispatch('load');
      }),
    update: async (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { dispatch, commit }: UpdateJobsActionType,
      payload: UpdateType
    ) => {
      payload.body.autoUpdate = undefined;
      const response = await DeploymentApi.update(
        payload.deploymentId,
        payload.body
      );
      const data = response.data;
      commit('ADD_UPDATE_JOB', data);
      commit('ADD_SELECTED_UPDATE_JOB', data);
    },
    delete({ commit }: UpdateJobsActionType, payload: UpdateJobType[]) {
      const promises: Promise<any>[] = [];
      const ujToDelete = [...payload];
      ujToDelete.forEach((updateJob) => {
        promises.push(
          DeploymentApi.deleteById(updateJob.uuid).then(() => {
            commit('DELETE_UPDATE_JOB', updateJob);
          })
        );
      });
      return Promise.all(promises);
    }
  },

  // mutations
  mutations: {
    ['SET_UPDATE_JOBS'](state: UpdateJobsStateType, payload: DeploymentType[]) {
      // sort by created at
      state.updateJobs = payload;
    },
    ['ADD_UPDATE_JOB'](state: UpdateJobsStateType, payload: DeploymentType) {
      const index = state.updateJobs.findIndex(
        (updateJob) => updateJob.uuid === payload.uuid
      );
      if (index === -1) {
        state.updateJobs.push(payload);
      } else {
        state.updateJobs.splice(index, 1, payload);
      }
    },
    ['SET_VISIBILITY_FILTER'](state: UpdateJobsStateType, payload: boolean) {
      state.visibilityFilter = payload;
    },
    ['RESET_SELECTED'](state: UpdateJobsStateType) {
      state.selectedUpdateJobs = [];
    },
    ['RESET_FILTER'](state: UpdateJobsStateType) {
      state.filter = {
        status: []
      };
    },
    ['UPDATE_STATUS'](state: UpdateJobsStateType, payload: string[]) {
      state.status = payload;
    },
    ['FILTER_UPDATE_JOBS'](state: any) {
      // first filter the status
      let filteredStatus = state.updateJobs;
      if (state.filter.status.length > 0) {
        filteredStatus = state.updateJobs.filter((updateJob: any) => {
          const mostRecentDate = new Date(
            Math.max.apply(
              null,
              updateJob.updateJob.updateJobs.map(
                (job: any) => new Date(job.createdAt)
              )
            )
          );

          const lastUpdateJob = updateJob.updateJob.updateJobs.find(
            (job: any) => {
              const d = new Date(job.createdAt);
              return d.getTime() === mostRecentDate.getTime();
            }
          );

          return state.filter.status.includes(
            state.status.indexOf(lastUpdateJob.progress)
          );
        });
      }

      // filter the rest later

      // merge filtered lists
      state.filteredUpdateJobs = filteredStatus;
      state.visibleUpdateJobs = state.filteredUpdateJobs;
    },
    ['SET_FILTER_STATUS'](state: UpdateJobsStateType, payload: string[]) {
      state.filter.status = payload;
    },
    ['SEARCH_UPDATE_JOBS'](state: UpdateJobsStateType, payload: string) {
      state.search = payload;
      if (payload === '' || !payload) {
        state.visibleUpdateJobs = state.filteredUpdateJobs;
      } else {
        payload = payload.replace(/ /g, '').toLowerCase();
        const searchName = state.filteredUpdateJobs.filter((updateJob) =>
          updateJob.name.replace(/ /g, '').toLowerCase().includes(payload)
        );

        const searchCommonImage = state.filteredUpdateJobs
          .filter(
            (updateJob) =>
              updateJob.subject === DEPLOYMENT_SUBJECT_UPDATE_PACKET
          )
          .filter((updateJob) => {
            const commonImage = state.updatePackets.find(
              (updatePacket) =>
                updatePacket.uuid === updateJob.updatePacket?.commonUpdatePacket
            );

            return commonImage?.description
              .replace(/ /g, '')
              .toLowerCase()
              .includes(payload);
          });

        const searchStatus = state.filteredUpdateJobs.filter((deployment) => {
          const mostRecentDate = new Date(
            Math.max.apply(
              null,
              deployment?.updateJob?.updateJobs?.map((updateJob) =>
                new Date(
                  updateJob.createdAt ? updateJob.createdAt : new Date()
                ).getTime()
              ) as number[]
            )
          );

          const lastUpdateJob = deployment?.updateJob?.updateJobs?.find(
            (updateJob: any) => {
              const d = new Date(
                updateJob.createdAt ? updateJob.createdAt : new Date()
              );
              return d.getTime() === mostRecentDate.getTime();
            }
          );

          return lastUpdateJob?.progress
            ?.replace(UPDATE_JOB_PROGRESS_READY, 'Bereit')
            .replace(UPDATE_JOB_PROGRESS_RUNNING, 'Aktiv')
            .replace(UPDATE_JOB_PROGRESS_SUCCEEDED, 'Erfolgreich ausgeführt')
            .replace(
              UPDATE_JOB_PROGRESS_PARTIALLY_SUCCEEDED,
              'Teilweise ausgeführt'
            )
            .replace(UPDATE_JOB_PROGRESS_FAILED, 'Fehlgeschlagen')
            .replace(/ /g, '')
            .toLowerCase()
            .includes(payload);
        });

        state.visibleUpdateJobs = [
          ...new Set([...searchName, ...searchCommonImage, ...searchStatus])
        ];
      }
    },
    ['ADD_SELECTED_UPDATE_JOB'](
      state: UpdateJobsStateType,
      payload: DeploymentType
    ) {
      const index = state.selectedUpdateJobs.findIndex(
        (updateJob) => updateJob.uuid === payload.uuid
      );
      if (index === -1) {
        state.selectedUpdateJobs.push(payload);
      } else {
        state.selectedUpdateJobs.splice(index, 1, payload);
      }
    },
    ['REMOVE_SELECTED_UPDATE_JOB'](
      state: UpdateJobsStateType,
      payload: DeploymentType
    ) {
      state.selectedUpdateJobs.splice(
        state.selectedUpdateJobs.indexOf(payload),
        1
      );
    },
    ['DELETE_UPDATE_JOB'](state: UpdateJobsStateType, payload: DeploymentType) {
      state.selectedUpdateJobs.splice(
        state.selectedUpdateJobs.indexOf(payload),
        1
      );
      state.updateJobs.splice(state.updateJobs.indexOf(payload), 1);
    },
    ['RESET_SELECTED_UPDATE_JOBS'](state: UpdateJobsStateType) {
      state.selectedUpdateJobs = [];
    },
    ['ADD_UPDATE_PACKET'](
      state: UpdateJobsStateType,
      payload: UpdatePacketType
    ) {
      const index = state.updatePackets.findIndex(
        (updatePacket) => updatePacket.uuid === payload.uuid
      );
      if (index === -1) {
        state.updatePackets.push(payload);
      } else {
        state.updatePackets.splice(index, 1, payload);
      }
    },
    ['ADD_SELECTED_UPDATE_JOBS'](
      state: UpdateJobsStateType,
      payload: DeploymentType[]
    ) {
      state.selectedUpdateJobs = payload;
    }
  }
};
