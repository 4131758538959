<template>
  <v-menu
    v-model="menuExpanded"
    data-cy="table-actions-button-menu"
    transition="slide-y-transition"
  >
    <template #activator="{ props }">
      <v-btn-group variant="flat" density="compact" class="btn-height">
        <v-btn
          v-if="showDeviceSelectionCount"
          data-cy="table-actions-button-selection"
          color="primary"
          class="selection-button text-none"
          prepend-icon="mdi-playlist-check"
          append-icon="mdi-close-circle"
        >
          <template #prepend>
            <v-icon />
          </template>
          {{ $t('common.numSelected', { count: numSelectedElements }) }}
          <template #append>
            <v-icon @click="() => clearTableSelection()" />
          </template>
        </v-btn>
        <v-btn
          data-cy="table-actions-button"
          :color="hasSelectedElements ? colors.primary : colors.tableHeader"
          class="text-none action-button"
          :class="
            hasSelectedElements ? 'text-color-white' : 'text-color-secondary'
          "
          v-bind="props"
        >
          {{ $t(text) }}
          <template #append>
            <v-icon
              end
              :class="
                menuExpanded ? 'turn-180 ma-0' : 'ease-in-out-animation ma-0'
              "
            >
              mdi-menu-down
            </v-icon>
          </template>
        </v-btn>
      </v-btn-group>
    </template>
    <ActionButtonList :config="configWithEnabledActions" :item="item" />
  </v-menu>
</template>

<script lang="ts">
import { colors } from '@/styles/colors';
import { eventBus } from '@/main';
import { defineComponent } from 'vue';
import {
  ActionsButtonWithCallback,
  ActionTypes,
  ButtonCallbacks
} from '../../types/DataTable';
import ActionButtonList from './ActionButtonList.vue';

export default defineComponent({
  name: 'DataTableActionButton',
  components: {
    ActionButtonList
  },

  props: {
    numSelectedElements: {
      type: Number,
      default: 0
    },
    dataTableId: {
      type: String,
      default: ''
    },
    config: {
      type: Array as () => ActionsButtonWithCallback[],
      required: true
    },
    item: {
      type: Object as () => Record<string, unknown>,
      required: false
    },
    lightningIcon: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: 'common.actions'
    },
    reactsOnEventBus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      colors,
      menuExpanded: false,
      mutateableConfig: undefined as ActionsButtonWithCallback[] | undefined
    };
  },

  computed: {
    selectionType(): ActionTypes {
      switch (this.numSelectedElements) {
        case 0:
          return ActionTypes.NONE;
        case 1:
          return ActionTypes.SINGLE;
        default:
          return ActionTypes.MULTIPLE;
      }
    },
    hasSelectedElements(): boolean {
      return this.selectionType !== ActionTypes.NONE;
    },
    filteredActionsButtonConfig(): ActionsButtonWithCallback[] {
      return (this.mutateableConfig ?? this.config).filter((actionButton) =>
        actionButton.types.includes(this.selectionType)
      );
    },
    configWithEnabledActions() {
      return (
        this.filteredActionsButtonConfig.filter(
          (action) => !!action.disabled === false
        ) ?? []
      );
    },
    showDeviceSelectionCount(): boolean {
      return this.selectionType === ActionTypes.MULTIPLE;
    }
  },
  created() {
    if (this.reactsOnEventBus) {
      eventBus.$on(
        'updateActionsButton',
        (data: ActionsButtonWithCallback[]) => {
          this.mutateableConfig = data;
        }
      );
    }
  },
  unmounted() {
    eventBus.$off('updateActionsButton');
  },
  methods: {
    isAction(action: ActionsButtonWithCallback): boolean {
      return action.id !== ButtonCallbacks.NONE;
    },
    clearTableSelection(): void {
      eventBus.$emit(
        'updateSelectedItemsOfExtendedDataTable',
        this.dataTableId,
        []
      );
    }
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.ease-in-out-animation {
  transition: all 0.2s ease-in-out;
}
.btn-height {
  height: 40px;
}

.action-button {
  color: $secondary !important;
}

.selection-button {
  margin-right: 2px;
}

.text-color-secondary {
  color: $secondary !important;
}

.text-color-white {
  color: white !important;
}
.v-btn::before {
  color: $light-shadow;
}

.turn-180 {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}
.v-menu__content {
  box-shadow: 0 0.1 0 0.1 !important;
}

.action-list {
  background-color: $table-header;
}
</style>
