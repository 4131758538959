/* eslint-disable @typescript-eslint/naming-convention */

// Vue.use(VueI18n);

import de from '@/languages/german.json';
import en from '@/languages/english.json';

const messages = {
  'de-DE': de,
  'en-GB': en
} as const;

// export const i18n = new VueI18n({
//   locale: localStorage.getItem('currentLang') || 'de-DE', // set locale
//   fallbackLocale: 'en-GB',
//   messages // set messages
// });

import { createI18n } from 'vue-i18n';

export const vuei18n = createI18n({
  locale: localStorage.getItem('currentLang') || 'de-DE',
  fallbackLocale: 'en-GB',
  messages: messages,
  legacy: true
});

export const i18n = vuei18n.global;

export const i18nViteTest = vuei18n;
