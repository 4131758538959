<template>
  <tr class="filter-row">
    <td v-if="considerSelected">
      <v-icon :color="colors.darkSecondary"> mdi-filter </v-icon>
    </td>
    <td
      v-for="(header, index) in enrichedHeadersWithFilterValues"
      :key="header.title"
      :style="{ width: originalTableCellWidths[index] }"
      class="py-2"
    >
      <v-btn
        v-if="index === 0"
        class="text-none"
        variant="flat"
        @click="resetFilters"
      >
        {{ $t('common.resetFilter') }}
      </v-btn>

      <v-autocomplete
        v-if="header.filterable && mount"
        :ref="('table-filter-' + header.value).split('.').join('-')"
        class="filter-autocomplete rounded-corners text-none"
        :placeholder="$t('common.all')"
        :no-data-text="$t('common.noFilterData')"
        bg-color="white"
        base-color="white"
        color="primary"
        :items="header.filterValues"
        clear-icon="mdi-close"
        multiple
        auto-select-first
        persistent-clear
        variant="outlined"
        hide-details
        clearable
        density="compact"
        :data-cy="('table-filter-' + header.value).split('.').join('-')"
        @update:model-value="collectFilterValues(header.value, $event)"
      />
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { colors } from '@/styles/colors';
import {
  DataTableHeader,
  DataTableHeaderWithFilterValues
} from '../../types/DataTable';
import { returnFilterValues } from '@/util/dataTable';
import { eventBus } from '@/main';

export default defineComponent({
  name: 'FilterRow',
  props: {
    items: {
      type: Array<Record<string, unknown>>,
      required: true
    },
    enrichedHeaders: {
      type: Array<DataTableHeader>,
      required: true
    },
    // this 'mount' prop ensures, that the inputs fields are cleared after hiding the FilterRow
    mount: {
      type: Boolean,
      required: true
    },
    considerSelected: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    tableId: {
      type: String,
      required: true
    }
  },
  emits: ['collectFilterValues', 'clearFilter'],
  data() {
    return {
      colors,
      enrichedHeadersWithFilterValues: [] as DataTableHeaderWithFilterValues[],
      originalTableCellWidths: [] as string[]
    };
  },
  watch: {
    items: {
      handler() {
        this.calculateFilterValues();
      },
      deep: true
    },
    loading(val) {
      // get the original width of the table cells after data loading finished
      // calculate width once to prevent the table columns from jumping around
      if (!val && this.originalTableCellWidths.length === 0) {
        let startIndex = 0;
        if (this.considerSelected) startIndex = 1;
        const tableCells = document.querySelectorAll(`#${this.tableId} th`);
        tableCells.forEach((cell, index) => {
          if (index >= startIndex) {
            this.originalTableCellWidths.push(cell.offsetWidth + 'px');
          }
        });
      }
    }
  },
  created() {
    // Debounce function
    const debounce = (func, wait) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    };
    // Redefine performSearch with a debounced version
    this.performFilter = debounce(this.performFilter.bind(this), 50);
  },
  methods: {
    resetFilters() {
      this.enrichedHeaders.map((header: DataTableHeaderWithFilterValues) => {
        const automcompleteId = ('table-filter-' + header.value)
          .split('.')
          .join('-');
        if (header.filterable) {
          const autocomplete = this.$refs[automcompleteId] as any;
          autocomplete[0].reset();
        }
      });
      this.$emit('clearFilter');
    },
    returnFilterValues,
    collectFilterValues(header: string, values: string[]) {
      eventBus.$emit('activateTableLoading', this.tableId);
      this.performFilter(header, values);
    },
    performFilter(header: string, values: string[]) {
      this.$emit('collectFilterValues', header, values);
    },
    calculateFilterValues() {
      this.enrichedHeadersWithFilterValues = this.enrichedHeaders.map(
        (header: DataTableHeaderWithFilterValues) => {
          if (header.filterable) {
            return {
              ...header,
              filterValues: this.returnFilterValues(header, this.items)
            };
          }
          return header;
        }
      );
    }
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/colors.scss';
.filter-row {
  background-color: $table-header;
}
.filter-autocomplete {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.filter-autocomplete:deep(.v-field) {
  padding: 0;
}
.filter-autocomplete:deep(.v-field__append-inner) {
  width: 0px; // hide spinning button
}
</style>
