/* eslint-disable no-shadow*/
/* eslint-disable @typescript-eslint/naming-convention*/
export enum TargetType {
  TYPE_MANAGED_DEVICES = 'MANAGED_DEVICES',
  TYPE_DEVICE_GROUPS = 'DEVICE_GROUPS',
  TYPE_ALL = 'ALL'
}

export enum SubjectType {
  USER_UPDATE_PACKETS,
  INSYS_UPDATE_PACKETS,
  CLI_TEMPLATES
}

export enum IndividualUpdate {}

export enum ChooseSubject {
  FIRMWARE_OR_SOFTWARE = 'firmware-or-software',
  INDIVIDUAL_UPDATE = 'individual-update'
}

export enum ChooseMethod {
  UPDATE_JOB = 'update-job',
  AUTO_UPDATE = 'auto-update'
}

export enum UpdateWizardStep {
  CHOOSE_TARGET = 'choose-target',
  CHOOSE_SUBJECT = 'choose-subject',
  CHOOSE_METHOD = 'choose-method',
  FINISH_UPDATE = 'finish-update',
  RESET = 'reset'
}

export enum UpdateWizardStepState {
  INIT = 'init',
  ERROR = 'error',
  SUCCESS = 'success'
}
