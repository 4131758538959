const TOKEN_VALIDITY_IN_MINUTES = 1000 * 60 * 15;
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const ACCESS_TOKEN_KEY = 'access_token';
const ACCESS_TOKEN_KEY_EXP = 'access_token_exp';

const TokenService = {
  getAccessToken: (): string | undefined => sessionStorage.getItem(ACCESS_TOKEN_KEY) ?? undefined,

  getAccessTokenExpiration: (): string | undefined => sessionStorage.getItem(ACCESS_TOKEN_KEY_EXP) ?? undefined,

  saveAccessToken: (accessToken: string) => {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    // set timestamp, needed for onPrem. Times between onPrem backend and frontend could differ
    sessionStorage.setItem(ACCESS_TOKEN_KEY_EXP, String(Date.now() + TOKEN_VALIDITY_IN_MINUTES));
  },

  removeAccessToken:() => {
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
    sessionStorage.removeItem(ACCESS_TOKEN_KEY_EXP);
  },

  getRefreshToken:(): string | undefined => localStorage.getItem(REFRESH_TOKEN_KEY) ?? undefined,

  saveRefreshToken:(refreshToken: string) => {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken:() => {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }
};

export { TokenService };
