
import VueCookies from 'vue3-cookies';

export const vueCookies = VueCookies;
import { globalCookiesConfig } from 'vue3-cookies';

globalCookiesConfig({
  expireTimes: '30d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'None'
});
