import {
  IconButton,
  ButtonCallbacks,
  DataTable,
  TableStatusChip
} from '@/components/types/DataTable';
import { AccountTierLevel } from '@/store/types/admin/index';
import { TIER_LEVEL_COLORS } from '@/util/accounts';
import { TIER_LEVEL_NAME } from '../../util/accounts';

export const accountTablebuttons: IconButton[] = [
  {
    id: ButtonCallbacks.EDIT,
    icon: 'mdi-pencil',
    title: 'common.edit'
  },
  {
    id: ButtonCallbacks.REFRESH,
    icon: 'mdi-refresh',
    title: 'common.refresh'
  }
];

export const accountTableConfig: DataTable = {
  tableId: 'accountTable',
  toolbarConfig: {
    titleCounterConfig: { title: 'administration.accounts' },
    iconButtonConfig: []
  }
};
export const changeAccountTableConfig: DataTable = {
  tableId: 'changeAccountTable',
  toolbarConfig: {
    titleCounterConfig: { title: 'administration.accounts' },
    iconButtonConfig: [],
    hasFilterFunctionality: false
  }
};

export const addUserToAccountTableConfig: DataTable = {
  tableId: 'addUserToAccountTable',
  toolbarConfig: {
    titleCounterConfig: { title: 'administration.users' },
    iconButtonConfig: [
      {
        id: ButtonCallbacks.ADD,
        icon: 'mdi-plus',
        title: 'common.add',
        callback: () => undefined
      }
    ],
    hasFilterFunctionality: false
  }
};

export const trialLevelStatusConfig: TableStatusChip[] = [
  {
    statusString: AccountTierLevel.UNLICENSED,
    translationKey: TIER_LEVEL_NAME[AccountTierLevel.UNLICENSED],
    color: TIER_LEVEL_COLORS[AccountTierLevel.UNLICENSED]
  },
  {
    statusString: AccountTierLevel.TRIAL,
    translationKey: TIER_LEVEL_NAME[AccountTierLevel.TRIAL],
    color: TIER_LEVEL_COLORS[AccountTierLevel.TRIAL]
  },
  {
    statusString: AccountTierLevel.FREE,
    translationKey: TIER_LEVEL_NAME[AccountTierLevel.FREE],
    color: TIER_LEVEL_COLORS[AccountTierLevel.FREE]
  },
  {
    statusString: AccountTierLevel.BASIC,
    translationKey: TIER_LEVEL_NAME[AccountTierLevel.BASIC],
    color: TIER_LEVEL_COLORS[AccountTierLevel.BASIC]
  },
  {
    statusString: AccountTierLevel.INTERNAL,
    translationKey: TIER_LEVEL_NAME[AccountTierLevel.INTERNAL],
    color: TIER_LEVEL_COLORS[AccountTierLevel.INTERNAL]
  }
];
