import Api from '@/services/ApiService';
import { AxiosRequestConfig } from 'axios';
import { downloadFile } from '../../util/downloadUtil';

export const findLogs = () => Api.get('audit/logs');
export const exportLogs = () => {
  const config: AxiosRequestConfig = {
    responseType: 'blob'
  };
  return Api.get('audit/logs/export', config);
};

export const findArchivedLogs = async () =>
  (await Api.get('audit/logs/archived')).data as { fileName: string }[];
export const downloadArchivedLog = async (fileName: string) => {
  const resp = await Api.get(`audit/logs/archived/${fileName}`, {
    responseType: 'blob'
  });
  downloadFile(resp.data as BlobPart, fileName);
};
