<template>
  <div v-if="expandNavigationDrawer">
    <template v-for="item in navigationItems" :key="item.title">
      <v-list-item
        v-if="
          !item.children &&
          navigationItemVisible(item.featureFlags, licensedFeatures)
        "
        :active="currentPath.includes(item.path)"
        :title="$t(item.title)"
        :prepend-icon="item.icon"
        :value="item"
        color="primary"
        @click="$router.push(item.path)"
      />
      <v-list-group
        v-if="
          item.children &&
          navigationItemVisible(item.featureFlags, licensedFeatures)
        "
        :value="item.title"
      >
        <template #activator="{ props }">
          <v-list-item
            :title="$t(item.title)"
            :prepend-icon="item.icon"
            v-bind="props"
            :value="item"
          >
          </v-list-item>
        </template>
        <NavigationGroup
          :navigation-items="item.children"
          :expand-navigation-drawer="true"
        />
      </v-list-group>
    </template>
  </div>

  <!-- collapsed NavigationDrawer-->
  <div v-else>
    <template v-for="item in navigationItems" :key="item.title">
      <v-menu open-on-hover location="right" close-on-content-click>
        <template #activator="{ props }">
          <v-list-item
            v-if="navigationItemVisible(item.featureFlags, licensedFeatures)"
            :active="currentPathIncludesItemOrChild(item)"
            :title="$t(item.title)"
            :prepend-icon="item.icon"
            :value="item"
            color="primary"
            v-bind="props"
          />
        </template>
        <v-list v-if="item.children">
          <v-list-item
            v-for="(child, index) in item.children"
            :key="index"
            :active="currentPath.includes(child.path)"
            :title="$t(child.title)"
            color="primary"
            @click="$router.push(child.path)"
          />
        </v-list>
        <v-list v-else>
          <v-list-item
            :active="currentPath.includes(item.path)"
            :title="$t(item.title)"
            color="primary"
            @click="$router.push(item.path)"
          />
        </v-list>
      </v-menu>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import {
  NavigationItem,
  navigationItemVisible
} from '../../../navigationDrawer/navigationDrawer';
import { mapState } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';

const NavigationGroup = defineComponent({
  name: 'NavigationGroup',
  props: {
    navigationItems: {
      type: Array<NavigationItem>,
      required: true
    },
    expandNavigationDrawer: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      currentPath: this.$route.path
    };
  },
  computed: {
    ...mapState(authenticationStore, ['licensedFeatures']),
    navigationItemVisible(): typeof navigationItemVisible {
      return navigationItemVisible;
    }
  },
  watch: {
    $route: function (to) {
      this.currentPath = to.path;
    }
  },
  methods: {
    currentPathIncludesItemOrChild(item: NavigationItem) {
      if (item.children) {
        return item.children.some((child) =>
          this.currentPath.includes(child.path)
        );
      } else {
        return this.currentPath.includes(item.path);
      }
    }
  }
});
export default NavigationGroup;
</script>
