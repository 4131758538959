<template>
  <v-menu
    v-model:model-value="showMenu"
    class="menu"
    location="right"
    transition="slide-y-transition"
  >
    <template #activator="{ props: menu }">
      <v-tooltip location="bottom" open-delay="100" :text="$t('notification.title')">
        <template #activator="{ props : tooltip }">
          <v-badge
            v-bind="mergeProps(menu, tooltip)"
            :content="notificationCount"
            :model-value="notificationCount !== 0"
            class="notification-bell mx-3"
            data-cy="notification-badge"
          >
            <v-icon
              size="large"
              color="white"
              data-cy="notification-bell"
              icon="mdi-bell"
            />
          </v-badge>
        </template>
      </v-tooltip>
    </template>
    <NotificationList :notifications="notifications" class="list" />
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, mergeProps } from 'vue';
import gql from 'graphql-tag';
import NotificationList from '@/components/app/appBar/Notifications/NotificationList.vue';
import { Notification } from '@/shared/notifications';

export default defineComponent({
  name: 'NotificationBell',
  components: {
    NotificationList
  },
  props: {
    loggedIn: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      notifications: [] as Notification[],
      showMenu: false,
      mergeProps
    };
  },
  computed: {
    notificationCount(): number {
      return this.unreadNotifications.length;
    },
    unreadNotifications(): Notification[] {
      return this.notifications.filter(
        (notification) => !notification.hasBeenRead
      );
    }
  },
  watch: {
    showMenu() {
      if (!this.showMenu) {
        void this.readNotifications();
      }
    }
  },
  mounted() {
    void this.$apollo.queries.notifications.refetch();
  },
  methods: {
    readNotifications() {
      this.unreadNotifications.map((notification) => {
        // We clear it early to give the UI a snappy feel
        notification.hasBeenRead = true;

        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($input: NotificationToRead!) {
                ReadNotification(input: $input)
              }
            `,
            // Parameters
            variables: {
              input: {
                id: notification.id,
                hasBeenRead: notification.hasBeenRead
              }
            },
            // Update the cache with the result
            // The query will be updated with the optimistic response
            // and then with the real result of the mutation
            // Optimistic UI
            // Will be treated as a 'fake' result as soon as the request is made
            // so that the UI can react quickly and the user be happy
            optimisticResponse: {
              ReadNotification: 'Success'
            }
          })
          .then(() => {
            // Result
          })
          .catch(() => {
            // Error
            // We restore the initial user input
            notification.hasBeenRead = false;
          });
      });
    }
  },
  apollo: {
    notifications: {
      query: gql`
        {
          notifications {
            id
            createdAt
            notificationType
            status
            data
            hasBeenRead
          }
        }
      `,
      skip() {
        return !this.loggedIn;
      },
      result({ data }: { data: { notifications: Notification[] } }) {
        this.notifications = data.notifications;
      }
    },
    $subscribe: {
      notification: {
        query: gql`
          subscription {
            receivedNotification {
              id
              notificationType
              status
              data
              createdAt
              hasBeenRead
            }
          }
        `,
        result({
          data
        }: {
          data: {
            receivedNotification: {
              notificationType: string;
              status: string;
              data: object;
              createdAt: string;
            };
          };
        }) {
          this.notifications.unshift(data.receivedNotification);
        }
      }
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/styles/colors.scss';
.v-menu__content {
  box-shadow: 0 0 0 0 !important;
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: -4px;
}
.menu {
  height: '100%';
}
.v-card {
  border-color: $light-border !important;
  border-width: 1px 2px 2px 1px !important;
  border: solid;
  overflow: scroll;
  overflow-y: auto;
}
.list {
  overflow: scroll;
  overflow-y: auto;
}
</style>
