<template>
  <ExtendedDialog
    :show-dialog="show"
    :process-action="processing"
    :config="feedbackDialog"
    @execute-action="execute($event)"
  >
    <v-container class="dialog-content">
      <p>{{ $t('app.feedbackInfo') }}</p>
      <p class="pt-2">{{ $t('app.feedbackRating') }}</p>
      <v-rating
        v-model="rating"
        empty-icon="mdi-star-outline"
        full-icon="mdi-star"
        hover
        length="5"
        size="large"
        color="primary"
      />
      <p>{{ $t('app.feedbackComment') }}</p>
      <v-textarea
        v-model="text"
        class="mt-2 text-body-2"
        variant="outlined"
        density="compact"
        hide-details
      />
      <v-alert
        v-if="showNoRatingAlert"
        class="pa-2"
        density="compact"
        type="warning"
        variant="tonal"
        :text="$t('app.feedbackAlert')"
      >
      </v-alert>
    </v-container>
  </ExtendedDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';
import { userStore } from '@/store/pinia/UserStore';
import ExtendedDialog from '@/components/common/dialog/ExtendedDialog.vue';
import { feedbackDialog } from '@/models/settings/settingsDialogConfig';
import { ButtonCallbacks } from '@/components/types/Dialog';

export default defineComponent({
  name: 'FeedbackDialog',
  components: { ExtendedDialog },
  emits: ['update:showDialog'],
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      rating: 0,
      showNoRatingAlert: false,
      text: '',
      processing: false,
      feedbackDialog
    };
  },
  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit('update:showDialog', value);
      }
    }
  },
  watch: {
    rating() {
      if (this.rating !== 0) {
        this.showNoRatingAlert = false;
      }
    }
  },
  methods: {
    ...mapActions(userStore, ['sendReceivedFeedback']),
    async sendFeedback() {
      if (this.rating !== 0) {
        this.processing = true;
        await this.sendReceivedFeedback(this.text, this.rating).then(() => {
          this.processing = false;
          this.closeDialog();
        });
      } else {
        this.showNoRatingAlert = true;
      }
    },
    execute(callbackID) {
      if (callbackID === ButtonCallbacks.SAVE) {
        this.sendFeedback();
      } else {
        this.closeDialog();
      }
    },
    closeDialog(): void {
      this.$emit('update:showDialog', false);
      this.resetDialog();
    },
    resetDialog(): void {
      this.rating = 0;
      this.showNoRatingAlert = false;
      this.text = '';
    }
  }
});
</script>
