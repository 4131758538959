export const colors = {
  primary : '#00a5df',
  primary75 : '#28c7ff',
  primary50 : '#6FDAFF',
  primary25 : '#B7ECFF',
  secondary : '#0D447A',
  darkSecondary:'#222D43',
  danger: '#E74C3C',
  success: '#0A9A33',
  warning: '#FBBA00',
  warningLight: '#FBBA0055',
  mediumShadow: '#e0e0e0',
  darkShadow: '#b5b5b5',
  tableHeader: '#e6edf0',
  primaryBackground: '#00a5dc25',
  notConfigured: '#fdd835',
  free: '#00cf9f',
  trial: '#ff8c00',
  internal: '#000000d9',
  info: '#00c1c1',
  topHeader: '#424242',
  body: '#f5f5f5',
  navigation: '#eeeeee'
};
