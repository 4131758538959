/* eslint-disable @typescript-eslint/naming-convention */
export const UNRELEASED_CODE_ERRCODE = 1100032;
export const UNRELEASED_CODE_MESSAGE = 'license is not released';

export const DUPED_FREE_LICENSE_ERRCODE = 1100033;
export const DUPED_FREE_LICENSE_ERRMSG =
  'only a single active license of this type is allowed';

export const DEPLOYMENT_UPDATE_TYPE_REGULAR = 'REGULAR';
export const DEPLOYMENT_UPDATE_TYPE_INDIVIDUAL = 'INDIVIDUAL';

export const DEPLOYMENT_UPDATE_PACKET_FLAVOR_AUTOUPDATE_FULL =
  'AUTOUPDATE_FULL';
export const DEPLOYMENT_UPDATE_PACKET_FLAVOR_AUTOUPDATE_INCREMENTAL =
  'AUTOUPDATE_INCREMENTAL';
export const DEPLOYMENT_UPDATE_PACKET_FLAVOR_UPDATE_FULL =
  'UPDATE_FULL';
export const DEPLOYMENT_UPDATE_PACKET_FLAVOR_UPDATE_INCREMENTAL =
  'UPDATE_INCREMENTAL';

export const DEPLOYMENT_UPDATE_PACKET_CATEGORY_FIRMWARE = 'FIRMWARE';

export const OAM_CONNECTION_PROFILE = 'ConnectionProfile';
export const AUTO_UPDATE_SERVER_CONFIG_NAME = 'AutoUpdateServerConfig';

export const ACCESS_TOKEN_TYPE_STATIC = 'STATIC';

export enum VALIDATION_TYPE {
  SUBMIT = 'submit',
  INPUT = 'input'
}
