/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { CliTemplateType, DryRunCliTemplateType } from '@/store/types';

export default {
  add(data: CliTemplateType) {
    return Api.post('inventory/cli-templates', data);
  },
  update(data: CliTemplateType) {
    return Api.put(`inventory/cli-templates/${data.uuid}`, data);
  },
  async getById(id: string): Promise<CliTemplateType> {
    const response = await Api.get(`ui/inventory/cli-templates/${id}`);
    return response.data;
  },
  find() {
    return Api.get('ui/inventory/cli-templates');
  },
  delete(id: string | any) {
    return Api.delete(`inventory/cli-templates/${id}`);
  },
  apply(data: DryRunCliTemplateType) {
    return Api.post(`inventory/cli-templates/${data.uuid}/apply`, data.values, {
      params: {
        deviceUuid: data.deviceUuid
      }
    });
  }
};
