/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';

export default {
  find() {
    return Api.get('admin/settings');
  },
  updateSetting(setting: any) {
    return Api.patch('admin/settings', setting);
  }
};
