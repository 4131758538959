<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-icon size="large">mdi-sleep</v-icon>
    </v-col>
    <v-col cols="auto" align-self="center">
      <span>{{ $t('common.noNotifications') }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'NoNotifications'
};
</script>

<style scoped lang="scss">
span {
  color: lightgray;
  font-size: large;
}

.v-icon {
  color: lightgray;
}
</style>
