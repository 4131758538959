<template>
  <v-card
    flat
    class="text-body-2 pt-3 mt-10 scrollable"
    width="500px"
    height="750px"
    data-cy="notification-list"
  >
    <v-card-title class="pt-0"> {{ $t('notification.title') }} </v-card-title>
    <v-divider class="mx-4 mb-4"></v-divider>
    <v-list v-if="notifications.length > 0">
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="list mb-2"
      >
        <NotificationCard :notification="notification" />
      </div>
    </v-list>
    <v-container v-else class="no-notifications">
      <NoNotifications />
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NotificationCard from '@/components/app/appBar/Notifications/NotificationCard.vue';
import { Notification } from '@/shared/notifications';
import NoNotifications from '@/components/app/appBar/Notifications/NoNotifications.vue';
export default defineComponent({
  name: 'NotificationList',
  components: {
    NotificationCard,
    NoNotifications
  },
  props: {
    notifications: {
      type: Array as () => Notification[],
      default: () => []
    }
  }
});
</script>

<style lang="scss" scoped>
.scrollable {
  overflow-y: auto !important;
}
.list {
  height: '80%' !important;
  overflow-x: hidden !important;
}

.no-notifications {
  height: 90% !important;
  display: flex;
  align-items: center;
}
</style>
