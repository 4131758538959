<template>
  <v-tooltip location="bottom">
    <template #activator="{ props }">
      <v-btn
        class="table-action-btn"
        :data-cy="(config.title + '-table-action-button').split('.').join('-')"
        :color="active ? colors.primaryBackground : colors.tableHeader"
        variant="flat"
        :disabled="disabled"
        v-bind="props"
        @click="config.callback()"
      >
        <v-icon
          size="large"
          :color="active ? colors.primary : colors.secondary"
          :icon="active && config.activeIcon ? config.activeIcon : config.icon"
        />
      </v-btn>
    </template>
    <span> {{ $t(config.title) }} </span>
  </v-tooltip>
</template>

<script lang="ts">
import { colors } from '@/styles/colors';
import { IconButtonWithCallback } from '@/components/types/DataTable';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'IconButton',
  props: {
    config: {
      type: Object as () => IconButtonWithCallback,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      colors
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.table-action-btn {
  border-radius: 6px;
  min-width: 50px !important;
  width: 50px;
  height: 40px;
}
.v-btn::before {
  color: $light-shadow;
}
</style>
