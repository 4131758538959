/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
export default {
  get() {
    return Api.get(
      'admin/software-version',
      {
        headers: {
          Authorization: ''
        }
      }
    );
  }
};
