/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AxiosResponse } from 'axios';
import DeploymentApi from '../../services/rollout/DeploymentApi';
import { immutableDeployment } from '@/models/rollouts/Deployment';
import { DEPLOYMENT_METHOD_AUTO_UPDATE } from '@/models/rollouts/constants';
import UpdatePacketApi from '../../services/autoupdate/UpdatePacketApi';
import { immutableUpdatePacket } from '@/models/autoupdate/UpdatePacket';
import deleteTarget from './updateServer/deleteTarget';
import { DeploymentType, UpdatePacketType } from '@/store/types';
import {
  UpdateServerActionType,
  UpdateServerStateType,
  UpdateServerUpdateType
} from '../types/rollouts/UpdateServers';

export default {
  namespaced: true,

  modules: {
    deleteTarget
  },

  // initial state
  state: (): UpdateServerStateType => ({
    deployments: [],
    updatePackets: [],
    serverToDelete: null
  }),

  // getters
  getters: {
    autoUpdateServers(state: UpdateServerStateType) {
      return state.deployments.filter(
        (deployment) => deployment.method === DEPLOYMENT_METHOD_AUTO_UPDATE
      );
    },
    allAliases(state: UpdateServerStateType) {
      return state.deployments
        .filter(
          (deployment) => deployment.method === DEPLOYMENT_METHOD_AUTO_UPDATE
        )
        .map((deployment) => deployment.autoUpdate.serverAlias) as string[];
    },
    updatePacket: (state: UpdateServerStateType) => (updatePacketId: string) =>
      state.updatePackets.find(
        (updatePacket) => updatePacket.uuid === updatePacketId
      ),
    serverToDelete(state: UpdateServerStateType) {
      return state.serverToDelete;
    }
  },

  // actions
  actions: {
    load({ commit }: UpdateServerActionType) {
      return DeploymentApi.find()
        .then((r) => r.data.members)
        .then((member) => {
          const deployments = member.map((el: DeploymentType) =>
            immutableDeployment(el)
          );
          commit('SET_DEPLOYMENTS', deployments);

          const getUpdatePacketPromises: Promise<AxiosResponse<unknown>>[] = [];
          deployments.forEach((deployment: DeploymentType) => {
            if (deployment.method === DEPLOYMENT_METHOD_AUTO_UPDATE) {
              if (deployment.updatePacket?.commonUpdatePacket !== undefined) {
                getUpdatePacketPromises.push(
                  UpdatePacketApi.getById(
                    deployment.updatePacket?.commonUpdatePacket
                  )
                );
              }

              Object.values(
                deployment.updatePacket?.targetUpdatePackets || []
              ).forEach((value) => {
                getUpdatePacketPromises.push(UpdatePacketApi.getById(value));
              });
            }
          });
          return Promise.all(getUpdatePacketPromises).then((r) => {
            r.forEach((resp) => {
              const updatePacket = immutableUpdatePacket(resp.data);
              commit('ADD_UPDATE_PACKET', updatePacket);
            });
          });
        });
    },
    loadUpdatePacket({ commit }: UpdateServerActionType, payload: string) {
      return UpdatePacketApi.getById(payload)
        .then((r) => r.data)
        .then((data) => {
          const updatePacket = immutableUpdatePacket(data);
          commit('ADD_UPDATE_PACKET', updatePacket);
        });
    },
    update(
      { commit }: UpdateServerActionType,
      payload: UpdateServerUpdateType
    ) {
      return DeploymentApi.update(payload.deploymentId, payload.body)
        .then((r) => r.data)
        .then((data) => {
          const deployment = immutableDeployment(data);
          commit('ADD_DEPLOYMENT', deployment);
        });
    },
    deleteDeployment({ commit }: UpdateServerActionType, payload: string) {
      return DeploymentApi.deleteById(payload).then(() => {
        commit('REMOVE_DEPLOYMENT_BY_ID', payload);
        commit('SET_SERVER_TO_DELETE', '');
      });
    },
    updateServerToDelete(
      { commit }: UpdateServerActionType,
      payload: DeploymentType
    ) {
      commit('SET_SERVER_TO_DELETE', payload);
    }
  },

  // mutations
  mutations: {
    ['SET_DEPLOYMENTS'](
      state: UpdateServerStateType,
      payload: DeploymentType[]
    ) {
      // sort by created at
      state.deployments = payload.sort((a, b) =>
        a.createdAt && b.createdAt
          ? a.createdAt > b.createdAt
            ? 1
            : b.createdAt > a.createdAt
              ? -1
              : 0
          : 0
      );
    },
    ['ADD_DEPLOYMENT'](state: UpdateServerStateType, payload: DeploymentType) {
      const index = state.deployments.findIndex(
        (deployment) => deployment.uuid === payload.uuid
      );
      if (index === -1) {
        state.deployments.push(payload);
      } else {
        state.deployments.splice(index, 1, payload);
      }
      // sort by created at
      state.deployments = state.deployments.sort((a, b) =>
        a.createdAt && b.createdAt
          ? a.createdAt > b.createdAt
            ? 1
            : b.createdAt > a.createdAt
              ? -1
              : 0
          : 0
      );
    },
    ['ADD_UPDATE_PACKET'](
      state: UpdateServerStateType,
      payload: UpdatePacketType
    ) {
      if (
        state.updatePackets.filter(
          (updatePacket) => updatePacket.uuid === payload.uuid
        ).length === 0
      ) {
        state.updatePackets.push(payload);
      }
    },
    ['REMOVE_DEPLOYMENT_BY_ID'](state: UpdateServerStateType, payload: string) {
      const index = state.deployments.findIndex(
        (deployment) => deployment.uuid === payload
      );
      if (index !== -1) {
        state.deployments.splice(index, 1);
      }
    },
    ['SET_SERVER_TO_DELETE'](
      state: UpdateServerStateType,
      payload: DeploymentType
    ) {
      state.serverToDelete = payload;
    }
  }
};
