/* eslint-disable prefer-arrow/prefer-arrow-functions */
import AutoUpdateServerApi from '@/services/autoupdateServer/AutoUpdateServerApi';
import {
  AutoUpdateServerActionType,
  AutoUpdateServerState,
  AutoUpdateServer
} from '@/store/types/autoupdate/AutoUpdateServer';
import { i18n } from '@/plugins/i18n';
import { AUTO_UPDATE_SERVER_CONFIG_NAME } from '@/shared/constants';
import { createErrorMessage, showApiErrorSnackbar } from '@/util/snackBarUtil';
import { downloadFile } from '@/util/downloadUtil';

export default {
  namespaced: true,
  state: {
    loading: false,
    servers: [],
    selectedServers: [] as AutoUpdateServer[]
  },
  getters: {
    servers(state: AutoUpdateServerState): AutoUpdateServer[] {
      return state.servers;
    },
    isLoading(state: AutoUpdateServerState): boolean {
      return state.loading;
    },
    selected: (state: AutoUpdateServerState) => state.selectedServers,
    showDetails: (state: AutoUpdateServerState) =>
      state.selectedServers.length > 0,
    serverById: (state: AutoUpdateServerState) => (id: string) =>
      state.servers.find((server: AutoUpdateServer) => server.uuid === id)
  },
  actions: {
    async loadServers({ commit }: AutoUpdateServerActionType) {
      commit('SET_LOADING', true);
      try {
        commit(
          'SET_SERVERS',
          await AutoUpdateServerApi.getAutoUpdateServerList()
        );
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    selectServer: (
      { commit }: AutoUpdateServerActionType,
      data: AutoUpdateServer[]
    ) => {
      commit('SELECT_AUTO_UPDATE_SERVERS', data);
    },
    resetSelected: ({ commit }: AutoUpdateServerActionType) => {
      commit('RESET_SELECTED_AUTO_UPDATE_SERVER');
    },
    unselectAutoUpdateServer: (
      { commit }: AutoUpdateServerActionType,
      data: AutoUpdateServer
    ) => {
      commit('REMOVE_SELECTED_AUTO_UPDATE_SERVER', data);
    },
    async deleteAutoUpdateServer(
      { dispatch, commit }: AutoUpdateServerActionType,
      data: AutoUpdateServer | AutoUpdateServer[]
    ) {
      if (!Array.isArray(data)) {
        data = [data];
      }

      const promises = data.map((server) =>
        AutoUpdateServerApi.delete(server).catch((error) => {
          showApiErrorSnackbar(`${i18n.t('autoUpdateServer.errorDeleteServer')}`, error);
        })
      );
      return Promise.all(promises).then(() => {
        dispatch('loadServers');
        commit('RESET_SELECTED_AUTO_UPDATE_SERVER');
      });
    },
    updateServer: (
      { commit, dispatch }: AutoUpdateServerActionType,
      payload: AutoUpdateServer
    ) => {
      commit('SET_LOADING', true);

      return AutoUpdateServerApi.updateServer(payload)
        .then((res) => {
          // reload all data
          void dispatch('loadServers');
          return res;
        })
        .catch((error) => {
          showApiErrorSnackbar(
            i18n.t('common.serverError', {
              msg: createErrorMessage(error)
            }).toString(), error
          );
          throw error;
        })
        .finally(() => {
          commit('CLEAR_LOADING');
        });
    },
    downloadConfig: async (
      { commit }: AutoUpdateServerActionType,
      updateServer: AutoUpdateServer
    ) => {
      commit('SET_LOADING', true);
      try {
        const response = await AutoUpdateServerApi.getUpdateServerProfile(
          updateServer
        );
        const fileName =
          AUTO_UPDATE_SERVER_CONFIG_NAME + '_' + updateServer.name + '.tar';
        downloadFile(response.data, fileName);
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      } finally {
        commit('CLEAR_LOADING');
      }
    }
  },

  mutations: {
    ['SET_LOADING'](state: AutoUpdateServerState, isLoading: boolean) {
      state.loading = isLoading;
    },
    ['SET_SERVERS'](state: AutoUpdateServerState, payload: AutoUpdateServer[]) {
      state.servers = payload;
    },
    ['CLEAR_LOADING'](state: AutoUpdateServerState) {
      state.loading = false;
    },
    ['RESET_SELECTED_AUTO_UPDATE_SERVER'](state: AutoUpdateServerState) {
      state.selectedServers = [];
    },
    ['SELECT_AUTO_UPDATE_SERVERS'](
      state: AutoUpdateServerState,
      payload: AutoUpdateServer[]
    ) {
      state.selectedServers = payload;
    },
    ['REMOVE_SELECTED_AUTO_UPDATE_SERVER'](
      state: AutoUpdateServerState,
      unselected: AutoUpdateServer
    ) {
      state.selectedServers = state.selectedServers.filter(
        (server) => server.uuid !== unselected.uuid
      );
    }
  }
};
