<template>
  <v-app-bar
    v-if="showExpiredBar"
    color="danger"
    class="pointer-cursor"
    elevation="0"
    height="40"
    @click="showLicenseMenu"
  >
    <v-toolbar-title class="white-toolbar-title" align="center">
      <v-icon start size="small" class="mr-2" color="white">mdi-alert </v-icon>
      {{ $t('app.licenseExpired') }}
    </v-toolbar-title>
  </v-app-bar>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import { mapState } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';

export default defineComponent({
  name: 'ExpiredBar',
  computed: {
    ...mapState(authenticationStore, ['showExpiredBar'])
  },
  methods: {
    showLicenseMenu() {
      if (this.$route.path !== '/user-menu/licenses')
        void this.$router.push('/user-menu/licenses');
    }
  }
});
</script>
