export interface Dialog {
  buttonConfig: DialogButton[];
  title: string;
  dialogWidth: string;
  processText?: string;
  actionFlagLabel?: string;
}

export interface DialogButton {
  callbackID: ButtonCallbacks;
  text: string;
  outlined?: boolean;
  icon?: string;
}

export enum ButtonCallbacks {
  ADD,
  CANCEL,
  DELETE,
  OK,
  DOWNLOAD,
  SAVE,
  ACTION
}
