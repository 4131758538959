/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  AuditLogActionType,
  AuditLogStateType,
  AuditLogType
} from '@/store/types/audit';
import { findLogs, exportLogs } from '@/services/audit/LogApi';
import { createErrorMessage, showApiErrorSnackbar } from '@/util/snackBarUtil';
import { downloadFile } from '@/util/downloadUtil';
import { dateFormatter } from '@/util/dateFormatter';

export default {
  namespaced: true,

  state: {
    logs: [],
    visibleAuditLogs: []
  },

  getters: {
    logs: (state: AuditLogStateType) => state.logs,
    visibleAuditLogs: (state: AuditLogStateType) => state.visibleAuditLogs,
    formattedAuditLogs: (state: AuditLogStateType) => state.visibleAuditLogs.map((auditLog) => {
      auditLog.createdAt = dateFormatter.dateWithSeconds(auditLog.createdAt ?? '');
      return auditLog;
    })
  },

  actions: {
    async load({ commit }: AuditLogActionType) {
      commit('SET_LOADING');
      try {
        const response = await findLogs();
        commit('ADD_LOGS', response.data.members);
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },
    download: async ({ commit }: AuditLogActionType) => {
      commit('SET_LOADING');

      try {
        const response = await exportLogs();
        const fileName = 'auditLogs.csv';
        downloadFile(response.data, fileName);
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      } finally {
        commit('CLEAR_LOADING');
      }
    },
    search({ commit }: AuditLogActionType, payload: string) {
      commit('SEARCH', payload);
    }
  },

  mutations: {
    ['SET_LOADING'](state: AuditLogStateType) {
      state.loading = true;
    },
    ['CLEAR_LOADING'](state: AuditLogStateType) {
      state.loading = false;
    },
    ['ADD_LOGS'](state: AuditLogStateType, payload: AuditLogType[]) {
      payload.forEach((log: AuditLogType) => {
        const index = state.logs.findIndex(
          (existing) => existing.uuid === log.uuid
        );
        if (index === -1) {
          state.logs.push(log);
        } else {
          state.logs.splice(index, 1, log);
        }
      });

      state.logs = payload.sort((a, b) =>
        a.createdAt && b.createdAt
          ? a.createdAt < b.createdAt
            ? 1
            : b.createdAt < a.createdAt
              ? -1
              : 0
          : 0
      );

      state.visibleAuditLogs = state.logs;
    },
    ['SEARCH'](state: AuditLogStateType, payload: string) {
      if (payload === null || payload === '') {
        state.visibleAuditLogs = state.logs;
      } else {
        payload = payload.replace(/ /g, '').toLowerCase();
        const searchDescription = state.logs.filter((log) =>
          log.description.replace(/ /g, '').toLowerCase().includes(payload)
        );

        const searchCreatedAt = state.logs.filter((log) =>
          log.createdAt
            ? log.createdAt.replace(/ /g, '').toLowerCase().includes(payload)
            : ''
        );

        const searchOperation = state.logs.filter((log) =>
          log.operation.replace(/ /g, '').toLowerCase().includes(payload)
        );

        const searchUrn = state.logs.filter((log) =>
          log.urn.replace(/ /g, '').toLowerCase().includes(payload)
        );

        const searchUsername = state.logs.filter((log) =>
          log.credentials.username
            ? log.credentials.username
              .replace(/ /g, '')
              .toLowerCase()
              .includes(payload)
            : ''
        );

        const searchToken = state.logs.filter((log) =>
          log.credentials.tokenName
            ? log.credentials.tokenName
              .replace(/ /g, '')
              .toLowerCase()
              .includes(payload)
            : ''
        );

        state.visibleAuditLogs = [
          ...new Set([
            ...searchDescription,
            ...searchCreatedAt,
            ...searchOperation,
            ...searchUrn,
            ...searchUsername,
            ...searchToken
          ])
        ];
      }
    }
  }
};
