import { AccountTierLevel } from '@/store/types/admin/index';
import { colors } from '@/styles/colors';
import { i18n } from '@/plugins/i18n';
export interface TierLevelObject { tier: AccountTierLevel }
export interface CheckPasswordObject { passwordConfirmed: boolean }

export const TIER_LEVEL_COLORS: { readonly [K in AccountTierLevel]: string} = {
  [AccountTierLevel.UNLICENSED]: colors.mediumShadow,
  [AccountTierLevel.TRIAL]: colors.trial,
  [AccountTierLevel.FREE]: colors.free,
  [AccountTierLevel.BASIC]: colors.primary25,
  [AccountTierLevel.INTERNAL]: colors.internal
};

export const TIER_LEVEL_NAME: { readonly [K in AccountTierLevel]: string} = {
  [AccountTierLevel.UNLICENSED]: i18n.t('administration.trialLevel.unlicensed').toString(),
  [AccountTierLevel.TRIAL]: i18n.t('administration.trialLevel.trial').toString(),
  [AccountTierLevel.FREE]: i18n.t('administration.trialLevel.free').toString(),
  [AccountTierLevel.BASIC]: i18n.t('administration.trialLevel.basic').toString(),
  [AccountTierLevel.INTERNAL]: i18n.t('administration.trialLevel.internal').toString()
};
