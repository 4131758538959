/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  SystemSettingsActionType,
  SystemSettingsStateType,
  CustomFieldType
} from '@/store/types/identity';
import SettingsApi from '@/services/admin/SettingsApi';
import { createErrorMessage, showApiErrorSnackbar } from '@/util/snackBarUtil';
import { i18n } from '../../plugins/i18n';
import { Setting } from '../types/identity/index';

export default {
  namespaced: true,

  // initial state
  state: {
    settings: [] as Setting[],
    settingTypes: {} as Record<string, string>,
    customFields: [],
    selectedCustomFields: []
  },

  // getters
  getters: {
    settings(state: SystemSettingsStateType) {
      return state.settings;
    },
    certificateAuthorityInSystemSettings:
      (state: SystemSettingsStateType) => (uuid: string) => {
        const deviceControlSetting = state.settings.find(
          (element) => element.name === 'DEVICECONTROL_CLIENT_CERT_CA_ID'
        );
        const autoUpdateSetting = state.settings.find(
          (element) => element.name === 'AUTOUPDATE_CLIENT_CERT_CA_ID'
        );
        return (
          deviceControlSetting?.value === uuid ||
          autoUpdateSetting?.value === uuid
        );
      },
    isServerCert: (state: SystemSettingsStateType) => (uuid: string) => {
      const autoUpdateServerCertIdPref = state.settings.find(
        (element) => element.name === 'AUTOUPDATE_SERVER_CERT_ID'
      );
      const deviceControlServerCertIdPref = state.settings.find(
        (element) => element.name === 'DEVICECONTROL_SERVER_CERT_ID'
      );
      return (
        autoUpdateServerCertIdPref?.value === uuid ||
        deviceControlServerCertIdPref?.value === uuid
      );
    },
    isClientCA: (state: SystemSettingsStateType) => (uuid: string) => {
      const autoUpdateClientCaIdPref = state.settings.find(
        (element) => element.name === 'AUTOUPDATE_CLIENT_CERT_CA_ID'
      );
      const deviceControlClientCaIdPref = state.settings.find(
        (element) => element.name === 'DEVICECONTROL_CLIENT_CERT_CA_ID'
      );
      return (
        autoUpdateClientCaIdPref?.value === uuid ||
        deviceControlClientCaIdPref?.value === uuid
      );
    },
    customFields(state: SystemSettingsStateType) {
      return state.customFields;
    },
    selectedCustomFields(state: SystemSettingsStateType) {
      return state.selectedCustomFields;
    }
  },

  // actions
  actions: {
    async load({ commit }: SystemSettingsActionType) {
      try {
        const response = await SettingsApi.find();
        commit('ADD_SETTINGS', response.data);
      } catch (error) {

        showApiErrorSnackbar(createErrorMessage(i18n.tc('errors.fetchSystemSettings')), error);
        throw error;
      }
    },
    async update({ state, commit }: SystemSettingsActionType, data: Record<string, unknown>) {
      try {
        data = Object.entries(data)
          .reduce((acc, [key, value]) =>
            Object.assign(acc, {
              [key]: (() => {
                if (state.settingsTypes[key] === 'boolean' && value === 'true') {
                  return true;
                }
                if (state.settingsTypes[key] === 'boolean' && value === 'false') {
                  return false;
                }
                if (state.settingsTypes[key] === 'number') {
                  return Number.parseInt(`${value as string}`, 10);
                }
                return value;
              })()
            }),
          {}) as Record<string, unknown>;
        const response = await SettingsApi.updateSetting(data);
        commit('ADD_SETTINGS', response.data);
        return response;
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
        return error;
      }
    },
    selectCustomFields(
      { commit }: SystemSettingsActionType,
      data: CustomFieldType[]
    ) {
      commit('ADD_SELECTED_CUSTOM_FIELD', data);
    },
    toggleSingleCustomField: (
      { commit }: SystemSettingsActionType,
      data: CustomFieldType
    ) => {
      commit('TOGGLE_SINGLE_CUSTOM_FIELD', data);
    },
    resetSelectedCustomFields: ({ commit }) => {
      commit('RESET_SELECTED_CUSTOM_FIELDS');
    },
    setCustomFields: async (
      { commit }: SystemSettingsActionType,
      data: CustomFieldType[]
    ) => {
      try {
        // Take the array and process for PATCH request
        // Name is attr. name; remove name from obj
        const tempJsonObj = {} as Record<string, unknown>;
        const patchObj = {} as Record<string, unknown>;
        data.forEach((item) => {
          const { name, ...fieldRest } = item;
          tempJsonObj[name] = fieldRest;
        });
        patchObj.CUSTOM_FIELDS = tempJsonObj;
        const response = await SettingsApi.updateSetting(patchObj);
        commit('ADD_SETTINGS', response.data);
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    }
  },
  // mutations
  mutations: {
    ['ADD_SETTINGS'](state: SystemSettingsStateType, payload: Record<string, unknown>) {
      if (payload.CUSTOM_FIELDS) {
        const customFields = payload.CUSTOM_FIELDS;
        state.customFields = Object.keys(customFields).map((i) => ({
          ...customFields[i],
          name: i
        }));
        delete payload.CUSTOM_FIELDS;
      }
      // Display quickstart setting (even if it's not in account) to avoid Djordje messing with the database... (thank me later)
      // **if** he complains that the setting should not be visible blablala just set it to 'undefined' here.
      if (payload.QUICKSTART_ENABLED === undefined || payload.QUICKSTART_ENABLED === '') {
        payload.QUICKSTART_ENABLED = false;
      }
      const entries = Object.entries(payload);
      entries.forEach((entry: [string, unknown]) => {
        const setting = {
          name: entry[0],
          value: entry[1]
        };

        const index = state.settings.findIndex(
          (existing) => existing.name === setting.name
        );
        if (index === -1) {
          state.settings.push(setting);
        } else {
          state.settings.splice(index, 1, setting);
        }
      });

      state.settingsTypes = state.settings
        .reduce((acc, s) =>
          Object.assign(acc, { [s.name]: typeof s.value })
        , {}
        );

    },
    ['ADD_SELECTED_CUSTOM_FIELD'](
      state: SystemSettingsStateType,
      customFields: CustomFieldType[]
    ) {
      state.selectedCustomFields = customFields;
    },
    ['RESET_SELECTED_CUSTOM_FIELDS'](state: SystemSettingsStateType) {
      state.selectedCustomFields = [];
    },
    ['TOGGLE_SINGLE_CUSTOM_FIELD'](
      state: SystemSettingsStateType,
      payload: CustomFieldType
    ) {
      const index = state.selectedCustomFields.findIndex(
        (customField: CustomFieldType) => customField.name === payload.name
      );
      if (index === -1) {
        state.selectedCustomFields = [];
        state.selectedCustomFields.push(payload);
      } else {
        state.selectedCustomFields.splice(index, 1);
      }
    }
  }
};
