/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  CertificateAuthoritiesStateType,
  CertificateAuthorityActionType,
  CertificateAuthorityImportType,
  CertificateAuthorityType
} from '@/store/types/pki';
import CertificateAuthorityApi from '@/services/pki/CertificateAuthorityApi';
import { i18n } from '@/plugins/i18n';
import { certificateUploadFileTypes } from '@/models/pki/constants';
import { createErrorMessage, showErrorSnackbar, showApiErrorSnackbar, showSnackbar } from '@/util/snackBarUtil';
import { downloadFile } from '@/util/downloadUtil';

export default {
  namespaced: true,

  state: {
    loading: false,
    cas: [],
    selectedCertificateAuthorities: []
  },

  getters: {
    cas: (state: CertificateAuthoritiesStateType) => state.cas,
    loading: (state: CertificateAuthoritiesStateType) => state.loading,
    caById:
      (state: CertificateAuthoritiesStateType) =>
        (certificateAuthoritiesUuid: string) =>
          state.cas.find(
            (authority) => authority.uuid === certificateAuthoritiesUuid
          ),
    selectedCertificateAuthorities: (state: CertificateAuthoritiesStateType) =>
      state.selectedCertificateAuthorities,
    showDetails: (state: CertificateAuthoritiesStateType) =>
      state.selectedCertificateAuthorities.length > 0,
    certificateAuthoritiesSignedByIssuerUuid:
      (state: CertificateAuthoritiesStateType) => (issuerUuid: string) =>
        state.cas.filter(
          (ca) => ca.issuerUuid === issuerUuid && ca.uuid !== issuerUuid
        )
  },
  actions: {
    async load({ commit }: CertificateAuthorityActionType) {
      commit('SET_LOADING', true);
      try {
        const response = await CertificateAuthorityApi.find();
        commit('ADD_CAS', response.data.members);
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async importCertificateAuthority(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { dispatch, commit, state }: CertificateAuthorityActionType,
      data: CertificateAuthorityImportType
    ) {
      commit('SET_LOADING', true);
      let isSuccessful = false;
      let response;
      try {
        if (
          data.file.type === certificateUploadFileTypes.X_X509_CA_CERT ||
          data.file.type === certificateUploadFileTypes.X_PEM_FILE ||
          data.file.type === certificateUploadFileTypes.PKIX_CERT
        ) {
          response = await CertificateAuthorityApi.importExternal(
            data.file,
            data.name
          );
        }
        if (data.file.type === certificateUploadFileTypes.X_PKCS12) {
          response = await CertificateAuthorityApi.import(
            data.file,
            data.name,
            data.password
          );
        }
        if (response && response.status === 201) {
          isSuccessful = true;
          showSnackbar(`${i18n.t('certificate.uploadSuccessfully')}`);
        } else {
          showErrorSnackbar(`${i18n.t('certificate.uploadFailedUnknownType')}`);
        }
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      } finally {
        // dispatch Load
        commit('SET_LOADING', false);
      }
      return isSuccessful;
    },
    selectCertificateAuthorities: (
      { commit }: CertificateAuthorityActionType,
      data: CertificateAuthorityType[]
    ) => {
      commit('ADD_SELECTED_CERTIFICATE_AUTHORITIES', data);
    },
    toggleSingleCertificateAuthority: (
      { commit }: CertificateAuthorityActionType,
      data: CertificateAuthorityType
    ) => {
      commit('TOGGLE_SINGLE_CERTIFICATE_AUTHORITY', data);
    },
    resetSelectedCertificateAuthorities: ({ commit }) => {
      commit('RESET_SELECTED_CERTIFICATE_AUTHORITIES');
    },
    async deleteCertificateAuthority(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { dispatch, commit, state }: CertificateAuthorityActionType,
      certificateAuthority: CertificateAuthorityType
    ) {
      await CertificateAuthorityApi.delete(certificateAuthority);
    },
    async deleteSelectedCertificateAuthorities(
      { dispatch, commit }: CertificateAuthorityActionType, payload: CertificateAuthorityType[]) {
      const promises: Promise<any>[] = [];
      payload.forEach((ca) => {
        promises.push(CertificateAuthorityApi.delete(ca));
      });

      return Promise.all(promises).then(() => {
        commit('RESET_SELECTED_CERTIFICATE_AUTHORITIES');
        commit('SET_CERTIFICATE_AUTHORITIES', []);
        dispatch('load');
      });
    },
    async updateCertificateAuthority(
      { commit }: CertificateAuthorityActionType,
      data: CertificateAuthorityType
    ) {
      commit('SET_LOADING', true);
      let isSuccessful = false;
      try {
        await CertificateAuthorityApi.updateCA(data);
        isSuccessful = true;
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(i18n.t('certificate.errorChangingName')), error);
      } finally {
        // dispatch Load
        commit('SET_LOADING', false);
      }
      return isSuccessful;
    },
    async downloadCertificateAuthority(
      { commit }: CertificateAuthorityActionType,
      ca: CertificateAuthorityType
    ) {
      commit('SET_LOADING', true);
      const isSuccessful = false;
      let response;
      try {
        response = await CertificateAuthorityApi.download(ca.uuid);
        downloadFile(response.data, ca.name + '.crt');
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      } finally {
        commit('SET_LOADING', false);
      }
      return isSuccessful;
    }
  },

  mutations: {
    ['SET_LOADING'](
      state: CertificateAuthoritiesStateType,
      isLoading: boolean
    ) {
      state.loading = isLoading;
    },
    ['ADD_CAS'](
      state: CertificateAuthoritiesStateType,
      payload: CertificateAuthorityType[]
    ) {
      payload.forEach((ca: CertificateAuthorityType) => {
        const index = state.cas.findIndex(
          (existing) => existing.uuid === ca.uuid
        );
        if (index === -1) {
          state.cas.push(ca);
        } else {
          state.cas.splice(index, 1, ca);
        }
      });
      /* state.cas = payload.sort((a, b) =>
        a.createdAt && b.createdAt
          ? a.createdAt < b.createdAt
            ? 1
            : b.createdAt < a.createdAt
            ? -1
            : 0
          : 0
      ); */
    },
    ['TOGGLE_SINGLE_CERTIFICATE_AUTHORITY'](
      state: CertificateAuthoritiesStateType,
      payload: CertificateAuthorityType
    ) {
      const index = state.selectedCertificateAuthorities.findIndex(
        (ca: CertificateAuthorityType) => ca.uuid === payload.uuid
      );
      if (index === -1) {
        state.selectedCertificateAuthorities = [];
        state.selectedCertificateAuthorities.push(payload);
      } else {
        state.selectedCertificateAuthorities.splice(index, 1);
      }
    },
    ['ADD_SELECTED_CERTIFICATE_AUTHORITIES'](
      state: CertificateAuthoritiesStateType,
      payload: CertificateAuthorityType[]
    ) {
      state.selectedCertificateAuthorities = payload;
    },
    ['RESET_SELECTED_CERTIFICATE_AUTHORITIES'](
      state: CertificateAuthoritiesStateType
    ) {
      state.selectedCertificateAuthorities = [];
    },
    ['SET_CERTIFICATE_AUTHORITIES'](
      state: CertificateAuthoritiesStateType,
      cas: CertificateAuthorityType[]
    ) {
      state.cas = cas;
    }
  }
};
