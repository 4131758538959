import { Dict, MergeDictionaries } from './types';

export const hasField = <T, K extends string>(
  o: T,
  field: keyof T | K
): o is { [F in typeof field]: unknown } & T => Object.keys(o).includes(String(field));


export const hasTruthyField = <T, K extends string>(
  o: T,
  field: keyof T | K
): o is { [F in typeof field]: unknown } & T => !!(o as { [F in typeof field]: unknown } & T)[field];

export const omitKeys = <T, K extends readonly string[]>(
  source: T,
  keys: K
): string extends K[number] ? T : Omit<T, K[number]> => (
  Object.keys(source).reduce((acc, key) => (
    (keys).includes(key) ? acc : { ...acc, [key]: (source as Record<string, unknown>)[key] }
  ), {}) as string extends K[number] ? T : Omit<T, K[number]>
);

/**
 *
 * @param enom enum object
 * @param value value of a member of the enum
 * @returns the key of the enum member
 */
export const reverseStringEnum = (enom: Record<string, unknown>, value: unknown) =>
  Object.entries(enom).find(([/* key*/, tierLevel]) =>
    tierLevel === value
  )?.[0];

export const pascalCase = (str?: string) => str === undefined ? undefined : str[0].toUpperCase() + str.slice(1).toLowerCase();

export const camelCase = (str?: string) => str === undefined ? undefined : str.replace(/(-|_)([a-z])/g, (g) => g[1].toUpperCase());

export const mergeObjects = <T extends Dict,S extends Dict>(
  target: T, source: S
): MergeDictionaries<T,S> => [...new Set([...Object.keys(target), ...Object.keys(source)])]
  .reduce<MergeDictionaries<T,S>>(
  (acc, key) => {
    if (source[key] === undefined) {
    // stays initial value
      return Object.assign(acc, { [key]: target[key] });
    }
    // necessary to remove values from object
    // eslint-disable-next-line no-null/no-null
    if (source[key] === null) {
      return Object.assign(acc, { [key]: undefined });
    }
    if (typeof target[key] === 'object' && typeof source[key] === 'object') {
    // merges objects
      return Object.assign(acc, { [key] : mergeObjects(target[key] as Dict, source[key] as Dict) });
    }
    return Object.assign(acc, { [key]: source[key] });
  },
  {} as MergeDictionaries<T,S>
);

/**
 * Deep copy of an object if it does not contain Dates, functions, undefined, regExp or Infinity
 * @param obj object to copy
 * @returns deep copy of the object
 */
export const deepCopy = <T> (obj: T) => JSON.parse(JSON.stringify(obj)) as T;
