<template>
  <v-app-bar
    v-if="showNotHomeAccountBar"
    color="primary"
    elevation="0"
    height="40"
  >
    <v-toolbar-title class="white-toolbar-title" align="center">
      {{ $t('app.accountNotHomeAccount', { msg: loggedInAccount.name }) }}
      <a class="toolbar-link pointer-cursor" @click="switchAccount('')">
        {{ $t('app.switchBackToHomeAccount') }}
      </a>
    </v-toolbar-title>
  </v-app-bar>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';

export default defineComponent({
  name: 'NotHomeAccountBar',
  computed: {
    ...mapState(authenticationStore, [
      'loggedInAccount',
      'showNotHomeAccountBar'
    ])
  },
  methods: {
    ...mapActions(authenticationStore, ['switchAccount'])
  }
});
</script>
<style lang="scss" scoped>
.toolbar-link {
  color: white;
  text-decoration: underline;
}
</style>
