<template>
  <v-snackbar
    v-if="!loggedIn"
    v-model="showCookieSnackbar"
    :timeout="-1"
    vertical
  >
    <v-card-title>{{ $t('app.cookieWarningTitle') }}</v-card-title>
    <v-card-text
      >{{ $t('app.cookieWarning') }}
      <a
        :href="$t('administration.linkDataPrivacyStatement')"
        target="_blank"
        class="white-text"
      >
        {{ $t('administration.dataPrivacyStatement') }}</a
      >.
    </v-card-text>
    <v-card-actions>
      <v-btn
        id="accept-cookie-button"
        color="primary"
        variant="text"
        class="text-none"
        @click="closeCookieSnackbar()"
      >
        {{ $t('app.accept') }}
      </v-btn>
    </v-card-actions>
  </v-snackbar>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';
import { VueCookies } from 'vue-cookies';

export default defineComponent({
  name: 'CookieSnackbar',
  data: () => ({
    showCookieSnackbar: false
  }),
  computed: {
    ...mapState(authenticationStore, ['loggedIn']),

    cookies(): VueCookies & {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      set: (key: string, value: any, options: string) => void;
    } {
      return this.$cookies as any & { set: () => void };
    }
  },
  methods: {
    ...mapActions(authenticationStore, ['setCookiesAccepted']),
    closeCookieSnackbar(): void {
      this.cookies.set('cookiesAccepted', true, '365d');
      this.setCookiesAccepted(true);
      this.showCookieSnackbar = false;
    }
  },
  mounted(): void {
    if (!this.cookies?.isKey('cookiesAccepted')) {
      this.setCookiesAccepted(false);
      this.showCookieSnackbar = true;
    }
  }
});
</script>
