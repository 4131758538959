/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable no-shadow */
import SoftwareVersionApi from '../../services/admin/SoftwareVersionApi';
import {
  immutableSoftwareVersion,
  SoftwareVersion
} from '@/models/admin/SoftwareVersion';
import { AppActionType, AppStateType } from '../types/app';
import InstallerApi from '@/services/admin/InstallerApi';
import { showErrorSnackbar } from '@/util/snackBarUtil';
import { createErrorMessage } from '@/util/snackBarUtil';

const initialState = {
  appLoading: true,
  loading: false,
  error: undefined,
  fatalError: undefined,
  softwareVersion: {
    buildVersion: '',
    buildTime: '',
    buildHash: ''
  },
  installed: false
};

// getters
const getters = {
  appLoading(state: AppStateType) {
    return state.appLoading;
  },
  loading(state: AppStateType) {
    return state.loading;
  },
  hasError(state: AppStateType) {
    return state.error !== undefined;
  },
  error(state: AppStateType) {
    return state.error;
  },
  hasFatalError(state: AppStateType) {
    return state.fatalError !== undefined;
  },
  fatalError(state: AppStateType) {
    return state.fatalError;
  },
  softwareVersion(state: AppStateType) {
    return state.softwareVersion;
  },
  installed(state: AppStateType) {
    return state.installed;
  },
  onpremises() {
    return import.meta.env.VITE_VERSION === 'onpremises';
  },
  impossibleSignup() {
    return import.meta.env.VITE_SIGNUP_IMPOSSIBLE === 'true';
  }
};

// actions
const actions = {
  setAppLoading({ commit }: AppActionType) {
    commit('SET_APP_LOADING');
  },
  clearAppLoading({ commit }: AppActionType) {
    commit('CLEAR_APP_LOADING');
  },
  setLoading({ commit }: AppActionType) {
    commit('SET_LOADING');
  },
  clearLoading({ commit }: AppActionType) {
    commit('CLEAR_LOADING');
  },
  setError({ commit }: AppActionType, data: any) {
    let error = 'An unknown error occurred. Please contact support.';
    if (data.hint !== undefined) {
      error = data.hint;
    } else if (data.description !== undefined) {
      error = data.description;
    } else if (data.error !== undefined) {
      error = data.error;
    } else if (data.message !== undefined) {
      error = data.message;
    }
    commit('SET_ERROR', error);
  },
  clearError({ commit }: AppActionType) {
    commit('CLEAR_ERROR');
  },
  setFatalError({ commit }: AppActionType, data: any) {
    commit('SET_FATAL_ERROR', data);
  },
  clearFatalError({ commit }: AppActionType) {
    commit('CLEAR_FATAL_ERROR');
  },
  loadSoftwareVersion({ commit }: AppActionType) {
    commit('SET_LOADING');
    return SoftwareVersionApi.get()
      .then((r) => r.data)
      .then((data) => {
        const softwareVersion = immutableSoftwareVersion(data);
        commit('SET_SOFTWARE_VERSION', softwareVersion);
      })
      .finally(() => {
        commit('CLEAR_LOADING');
      });
  },
  async init({ commit }: AppActionType) {
    commit('SET_LOADING');
    try {
      const result = await InstallerApi.init();
      if (result.status === 200) {
        commit('SET_INSTALLED', true);
      }
    } catch (err) {
      showErrorSnackbar(createErrorMessage(err));
    } finally {
      commit('CLEAR_LOADING');
    }
  }
};

// mutations
const mutations = {
  ['SET_APP_LOADING'](state: AppStateType) {
    state.appLoading = true;
  },
  ['CLEAR_APP_LOADING'](state: AppStateType) {
    state.appLoading = false;
  },
  ['SET_LOADING'](state: AppStateType) {
    state.loading = true;
  },
  ['CLEAR_LOADING'](state: AppStateType) {
    state.loading = false;
  },
  ['SET_ERROR'](state: AppStateType, payload: any) {
    state.error = payload;
  },
  ['CLEAR_ERROR'](state: AppStateType) {
    state.error = undefined;
  },
  ['SET_FATAL_ERROR'](state: AppStateType, payload: any) {
    state.fatalError = payload;
  },
  ['CLEAR_FATAL_ERROR'](state: AppStateType) {
    state.fatalError = undefined;
  },
  ['SET_SOFTWARE_VERSION'](state: AppStateType, payload: SoftwareVersion) {
    state.softwareVersion = payload;
  },
  ['SET_INSTALLED'](state: AppStateType, payload: boolean) {
    state.installed = payload;
  }
};

export default {
  state: initialState,
  getters,
  actions,
  mutations
};
