export const MANAGED_DEVICE_ADMIN_STATUS_COMMISSIONING = 'COMMISSIONING';
export const MANAGED_DEVICE_ADMIN_STATUS_MANAGED = 'MANAGED';
export const MANAGED_DEVICE_ADMIN_STATUS_SERVICE = 'SERVICE';
export const MANAGED_DEVICE_ADMIN_STATUS_ERROR = 'ERROR';

export const MANAGED_DEVICE_CONNECTIVITY_STATUS_ONLINE = 'ONLINE';
export const MANAGED_DEVICE_CONNECTIVITY_STATUS_OFFLINE = 'OFFLINE';

export const MANAGED_DEVICE_EVENT_LOG_CATEGORY_DEVICE_EVENT = 'DEVICE_EVENT';
export const MANAGED_DEVICE_EVENT_LOG_CATEGORY_CONTROL_CHANNEL = 'CONTROL_CHANNEL';

export const MANAGED_DEVICE_EVENT_LOG_SEVERITY_INFO = 'INFO';
export const MANAGED_DEVICE_EVENT_LOG_SEVERITY_WARNING = 'WARNING';
export const MANAGED_DEVICE_EVENT_LOG_SEVERITY_ERROR = 'ERROR';
export const MANAGED_DEVICE_EVENT_LOG_SEVERITY_CRITICAL = 'CRITICAL';

export const STATUS_ERROR_INFO_NETWORK = 'INFO_NETWORK';
export const STATUS_ERROR_INFO_INVALID = 'INFO_INVALID';
export const STATUS_ERROR_INFO_TIMEOUT = 'INFO_TIMEOUT';

export const SMART_UPDATE_PROGRESS_SUCCESS = 'SUCCEEDED';
export const SMART_UPDATE_PROGRESS_FAILED = 'FAILED';
export const SMART_UPDATE_PROGRESS_PENDING = 'PENDING',
  SMART_UPDATE_PROGRESS_LATEST = 'LATEST',
  SMART_UPDATE_PROGRESS_SCHEDULED = 'SCHEDULED';
