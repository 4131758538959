/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  CliTemplateType,
  DryRunCliTemplateType,
  KeysConfiguration,
  TemplateActionType,
  TemplateStateType
} from '@/store/types';
import ConfigChangeTemplateHelper from '@/util/ConfigChangeTemplateHelper';
import { i18n } from '@/plugins/i18n';
import CliTemplateApi from '@/services/inventory/CliTemplateApi';
import { constants } from '@/models/autoupdate/constants';
import { getUnixTime } from 'date-fns';
import { showApiErrorSnackbar, showSnackbar, createErrorMessage } from '@/util/snackBarUtil';

export default {
  namespaced: true,
  state: () => ({
    loading: false,
    cliTemplates: [],
    cliTemplate: {} as CliTemplateType,
    selectedCliTemplates: [],
    visibleCliTemplates: [],
    visibilityFilter: false,
    search: ''
  }),

  getters: {
    templates(state: TemplateStateType): CliTemplateType[] {
      return state.cliTemplates;
    },
    template(state: TemplateStateType): CliTemplateType {
      return state.cliTemplate;
    },
    selected(state: TemplateStateType) {
      return state.selectedCliTemplates;
    },
    visible(state: TemplateStateType) {
      return state.visibleCliTemplates;
    },
    showDetails(state: TemplateStateType) {
      return state.selectedCliTemplates.length > 0;
    },
    showFilter(state: TemplateStateType) {
      return state.visibilityFilter;
    },
    search: (state: TemplateStateType) => state.search,
    templateById: (state: TemplateStateType) => (id: string) => state.cliTemplates.find((template) => template.uuid === id),
    hasDeletedItems: (state: TemplateStateType) =>{
      if (state.cliTemplate.keysConfiguration && state.cliTemplate.keysConfiguration.some((config) => config.isDeleted)) {
        return true;
      }
      return (
        state.cliTemplates.filter((cliTemplates) => cliTemplates.keysConfiguration.some(
          (keyConfig) => keyConfig.isDeleted
        )).length > 0
      );
    }
    ,
    isEditable:
      (state: TemplateStateType) =>
        (id: string): boolean => {
          const cliTemplate = state.cliTemplates.find(
            (template) => template.uuid === id
          );
          if (cliTemplate) {
            return (
              cliTemplate?.status !== constants.UPDATE_PACKET_STATUS_LINKED &&
            !cliTemplate.isComplex
            );
          }
          return true;
        }
  },

  actions: {
    restoreEntry: ({ commit }: TemplateActionType, index: number) => {
      commit('RESET_DELETED_ENTRY', index);
    },
    createTemporary: ({ commit }: TemplateActionType) => {
      commit('CREATE_TEMPORARY_CLI_TEMPLATE');
    },
    updateTemporary: (
      { commit }: TemplateActionType,
      data: CliTemplateType
    ) => {
      commit('UPDATE_TEMPORARY_CLI_TEMPLATE', data);
    },
    resetTemporaryTemplate: ({ commit }: TemplateActionType) => {
      commit('UPDATE_TEMPORARY_CLI_TEMPLATE', {});
    },
    load: async ({ commit }: TemplateActionType) => {
      commit('SET_LOADING');
      commit('RESET_SELECTED_CLI_TEMPLATES');
      try {
        const response = await CliTemplateApi.find();
        const cliTemplates = response.data.members;
        await cliTemplates.forEach(
          (cliTemplate: CliTemplateType, index: number) => {
            if (cliTemplate.keysConfiguration) {
              cliTemplates[index].isComplex =
                cliTemplate.keysConfiguration.some(
                  (keyConfiguration) =>
                    keyConfiguration.valueType === 'object' ||
                    keyConfiguration.valueType === 'array'
                );
            } else {
              cliTemplate.keysConfiguration = [];
            }
          }
        );
        commit('SET_CLI_TEMPLATES', cliTemplates);
        commit('SEARCH_CLI_TEMPLATES');
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      } finally {
        commit('CLEAR_LOADING');
      }
    },
    // TODO: Is add actually being used?!
    add: async ({ commit }: TemplateActionType, data: CliTemplateType) => {
      commit('SET_LOADING');

      return new Promise((resolve, reject) => {
        try {
          commit('RESET_SELECTED_CLI_TEMPLATES');
          commit('ADD_CLI_TEMPLATE', data);
          // tell user
          showSnackbar(
            `${i18n.t('templates.configChangeTemplateAdded', {
              msg: data.name
            })}`
          );
          resolve(data);
        } catch (error) {
          showApiErrorSnackbar(
            `${i18n.t('templates.configChangeTemplateAddFailed')}`, error
          );
          reject(error);
        }

        commit('CLEAR_LOADING');
      });
    },
    // eslint-disable-next-line @typescript-eslint/require-await
    update: async ({ commit }: TemplateActionType) => {
      commit('SET_LOADING');

      try {
        commit('RESET_SELECTED_CLI_TEMPLATES');
      } catch (error) {
        showApiErrorSnackbar(
          `${i18n.t('templates.configChangeTemplateEditFailed')}`, error
        );
        commit('CLEAR_LOADING');
        throw error;
      }

      commit('CLEAR_LOADING');
    },
    save: async ({ commit }: TemplateActionType, data: CliTemplateType) => {
      commit('SET_LOADING');

      try {
        data.keysConfiguration = data.keysConfiguration.filter(
          (keysConfiguration) => !keysConfiguration.isDeleted
        );
        if (data.uuid) {
          await CliTemplateApi.update(data);
          commit('RESET_SELECTED_CLI_TEMPLATES');
          showSnackbar(
            `${i18n.t('templates.configChangeTemplateEdited', {
              msg: data.name
            }).toString()}`
          );
        } else {
          const response = await CliTemplateApi.add(data);
          commit('RESET_SELECTED_CLI_TEMPLATES');
          // TODO remove this by removing temporary logic and work directly on the store for adding
          commit('ADD_CLI_TEMPLATE', response.data);

          showSnackbar(
            `${i18n.t('templates.configChangeTemplateAdded', {
              msg: data.name
            })}`
          );
        }
      } catch (error) {
        if (error.data.source?.attribute === 'name') {
          showApiErrorSnackbar(
            `${i18n.t('templates.configChangeTemplateDuplicateName', {
              msg: JSON.parse(error.config.data).name
            })}`, error
          );
        } else {
          if (data.uuid) {
            showApiErrorSnackbar(
              `${i18n.t('templates.configChangeTemplateEditFailed')}`, error
            );
          } else {
            showApiErrorSnackbar(
              `${i18n.t('templates.configChangeTemplateAddFailed')}`, error
            );
          }
        }
      }
      commit('CLEAR_LOADING');
    },
    deleteEntryOne: ({ commit }: TemplateActionType, data) => {
      commit('ADD_DELETE_KEY_TO_ENTRY', data);
    },
    undoLastAction: ({ commit, state }: TemplateActionType) => {
      if (Object.keys(state.cliTemplate).length !== 0) {
        commit('UNDO_LAST_DELETED_CONFIG_ITEM_IN_DIALOG');
      } else {
        commit('UNDO_LAST_DELETED_CONFIG_ITEM');
      }
    },
    dryRun: async (
      { commit }: TemplateActionType,
      data: DryRunCliTemplateType
    ) => {
      commit('SET_LOADING');

      try {
        const response = await CliTemplateApi.apply(data);
        commit('CLEAR_LOADING');

        return response.data;
      } catch (error) {
        showApiErrorSnackbar(
          `${i18n.t('templates.configChangeTemplateDryRunFailed')}`, error
        );

        commit('CLEAR_LOADING');
        throw error;
      }
    },
    updateVisibilityFilter: ({ commit }: TemplateActionType, data: boolean) => {
      commit('SET_VISIBILITY_FILTER', data);
    },
    searchTemplates({ commit }: TemplateActionType, data: string) {
      commit('SEARCH_CLI_TEMPLATES', data);
    },
    selectTemplate: ({ commit }: TemplateActionType, data: CliTemplateType) => {
      commit('ADD_SELECTED_CLI_TEMPLATE', data);
    },
    unselectTemplate: (
      { commit }: TemplateActionType,
      data: CliTemplateType
    ) => {
      commit('REMOVE_SELECTED_CLI_TEMPLATE', data);
    },
    resetSelected: ({ commit }: TemplateActionType) => {
      commit('RESET_SELECTED_CLI_TEMPLATES');
    },
    delete: ({ dispatch }: TemplateActionType,selectedCliTemplates: CliTemplateType[]) => {
      const promises: Promise<any>[] = [];
      selectedCliTemplates.forEach((template) => {
        promises.push(CliTemplateApi.delete(template.uuid));
      });

      return Promise.all(promises).then(() => {
        dispatch('load');
      });
    }
  },

  mutations: {
    ['RESET_DELETED_ENTRY'](state: TemplateStateType, index: number) {
      state.cliTemplates = state.cliTemplates.map(
        (cliTemplate: CliTemplateType) => {
          cliTemplate.keysConfiguration.forEach(
            (keyConfiguration, keyConfigIndex) => {
              if (keyConfiguration.isDeleted && keyConfigIndex === index) {
                return (keyConfiguration.isDeleted = false);
              }
            }
          );
          return cliTemplate;
        }
      );
    },
    ['SET_LOADING'](state: TemplateStateType) {
      state.loading = true;
    },
    ['CLEAR_LOADING'](state: TemplateStateType) {
      state.loading = false;
    },
    ['CREATE_TEMPORARY_CLI_TEMPLATE'](state: TemplateStateType) {
      state.cliTemplate = {
        name: '',
        tags: null,
        content: [''],
        keysConfiguration: []
      };
    },
    ['UPDATE_TEMPORARY_CLI_TEMPLATE'](
      state: TemplateStateType,
      data: CliTemplateType
    ) {
      state.cliTemplate = data;
    },
    ['SET_CLI_TEMPLATES'](state: TemplateStateType, data: CliTemplateType[]) {
      state.cliTemplates = data.sort((a, b) => a.name.localeCompare(b.name));
    },
    ['ADD_CLI_TEMPLATE'](state: TemplateStateType, data: CliTemplateType) {
      state.cliTemplates.push(data);
    },
    ['EDIT_CLI_TEMPLATE'](state: TemplateStateType, data: CliTemplateType) {
      state.cliTemplates = state.cliTemplates.map(
        (cliTemplate: CliTemplateType) => {
          if (cliTemplate.uuid === data.uuid) {
            cliTemplate.keysConfiguration.forEach((keyConfiguration, index) => {
              if (
                keyConfiguration.isDeleted !==
                data.keysConfiguration[index].isDeleted
              ) {
                return keyConfiguration.isDeleted;
              }
            });
          }
          return cliTemplate;
        }
      );
      state.cliTemplates = state.cliTemplates.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      state.visibleCliTemplates = state.cliTemplates;
    },
    ['ADD_SELECTED_CLI_TEMPLATE'](
      state: TemplateStateType,
      payload: CliTemplateType[]
    ) {
      state.selectedCliTemplates = payload;
    },
    ['REMOVE_SELECTED_CLI_TEMPLATE'](
      state: TemplateStateType,
      payload: CliTemplateType
    ) {
      state.selectedCliTemplates.splice(
        state.selectedCliTemplates.indexOf(payload),
        1
      );
    },
    ['RESET_SELECTED_CLI_TEMPLATES'](state: TemplateStateType) {
      state.selectedCliTemplates = [];
    },
    ['SET_VISIBILITY_FILTER'](state: TemplateStateType, payload: boolean) {
      state.visibilityFilter = payload;
    },
    ['SEARCH_CLI_TEMPLATES'](state: TemplateStateType, payload: string) {
      state.search = payload;
      if (payload === '' || !payload) {
        state.visibleCliTemplates = state.cliTemplates;
      } else {
        payload = payload.replace(/ /g, '').toLowerCase();
        const searchName = state.cliTemplates.filter((template) =>
          template.name.replace(/ /g, '').toLowerCase().includes(payload)
        );

        state.visibleCliTemplates = [...new Set([...searchName])];
      }
    },
    ['ADD_DELETE_KEY_TO_ENTRY'](state: TemplateStateType, data) {
      // this explicitly just works on the temporary store state
      let templateToOperateOn = state.cliTemplates.find(
        (cliTemplate) => cliTemplate.uuid === data.uuid
      );
      if (data?.temporary) {
        templateToOperateOn = state.cliTemplate;
      }
      if (templateToOperateOn !== undefined) {
        templateToOperateOn.keysConfiguration =
          templateToOperateOn?.keysConfiguration.map(
            (keysConfiguration: KeysConfiguration) => {
              if (keysConfiguration.key === data.entry.key) {
                keysConfiguration.isDeleted = true;
                keysConfiguration.deletedAt = getUnixTime(new Date());
              }
              return keysConfiguration;
            }
          );
      }
    },
    ['UNDO_LAST_DELETED_CONFIG_ITEM'](state: TemplateStateType) {
      state.cliTemplates.forEach((cliTemplate) => {
        ConfigChangeTemplateHelper.storeHelper(cliTemplate);
      });
    },
    ['UNDO_LAST_DELETED_CONFIG_ITEM_IN_DIALOG'](state: TemplateStateType) {
      ConfigChangeTemplateHelper.storeHelper(state.cliTemplate);
    }
  }
};
