/* eslint-disable prefer-arrow/prefer-arrow-functions */

import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

export interface SnackbarItem {
  readonly id: string;
  text: string;
  timeout?: number;
  error: boolean;
  buttonText?: string;
}

const DEFAULT_TIMEOUT = 6000;

export const snackbarStore = defineStore('snackbarStore', {
  state: () => ({
    snackbarItems: [] as SnackbarItem[]
  }),
  getters: {
    getSnackbarItems: (state) => state.snackbarItems
  },
  actions: {
    addSnackbarItem(snackbarItem: Omit<SnackbarItem, 'id'>) {
      Object.assign(snackbarItem, { id: uuidv4() });
      if (!snackbarItem.timeout) {
        snackbarItem.timeout = snackbarItem.error ? -1 : DEFAULT_TIMEOUT;
      }
      if (snackbarItem.timeout > -1) {
        setTimeout(() => {
          this.removeSnackbarItem((snackbarItem as SnackbarItem).id);
        }, snackbarItem.timeout);
      }
      this.snackbarItems.push((snackbarItem as SnackbarItem));
    },
    removeSnackbarItem(id: string) {
      this.snackbarItems = (this.snackbarItems as SnackbarItem[]).filter(
        (snackbarItem) => snackbarItem.id !== id
      );
    }
  }
});

