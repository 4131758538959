/* eslint-disable prefer-arrow/prefer-arrow-functions */
export class UpdatePacket {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor({
    uuid = '',
    createdAt = null,
    updatedAt = null,
    category = '',
    description = '',
    objectPrefix = '',
    fileName = '',
    status = ''
  } = {}) {
    this.uuid = uuid;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.category = category;
    this.description = description;
    this.objectPrefix = objectPrefix;
    this.fileName = fileName;
    this.status = status;
  }
}

export function immutableUpdatePacket(data) {
  return Object.freeze(new UpdatePacket(data));
}
