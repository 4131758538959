import { DataTableHeader } from '@/components/types/DataTable';
import Fuse, { FuseResult } from 'fuse.js';
import { deepCopy } from './index';

export type CustomSortFunc = () => (
  items: unknown[],
  sortBy: string[],
  sortDesc: boolean[],
  locale: string,
  customSorters?: Record<
  string,
  ((a: unknown, b: unknown) => number) | undefined
  >
) => unknown[];

/**
 * Update a nested property in an object.
 * @param obj - The object to update.
 * @param keys - Array of keys representing the path to the nested property.
 * @param value - The new value to set for the nested property.
 * @returns The modified object.
 */
export const transformNestedProp = <T extends Record<string, unknown>>(
  obj: T,
  keys: string[],
  value: string | string[]
): T => {
  if (keys.length === 0) {
    return obj;
  }
  const [currentKey, ...remainingKeys] = keys;
  // If the current key doesn't exist, return the original object
  if (!Object.hasOwn(obj, currentKey)) {
    return obj;
  }
  // If the current key is the last key in the keys array, update the value
  if (remainingKeys.length === 0) {
    obj[currentKey] = value;
  } else {
    // Recursively traverse the nested object
    obj[currentKey] = transformNestedProp(obj[currentKey], remainingKeys, value);
  }
  return obj;
};

/**
 * Transforms item values based on specified headers with valueTransformers.
 * @param items Array of items to transform.
 * @param headers Array of headers specifying transformations.
 * @returns Transformed array of items.
 */
export const transformItemValues = <T extends Record<string, unknown>>(
  items: T[],
  headers: DataTableHeader[]
): T[] => {
  // Deep copy of the items to avoid modifying the original data
  const transformedItems = deepCopy(items);
  const headersWithValueTransformers = headers.filter((header) => header.valueTransformer);
  transformedItems.forEach((item) => {
    headersWithValueTransformers.forEach((header) => {
      const keys = header.value.split('.'); // Split the value path into keys
      if (header.valueTransformer !== undefined) {
        item = transformNestedProp(item, keys, header.valueTransformer('', item));
      }
    });
  });
  return transformedItems;
};

/**
 * Searches for items in a list based on a search string and returns matching items.
 * @param items An array of items to search through.
 * @param search The search string to match against.
 * @param headers The headers to search in.
 * @param additionalHeaderKeys Additional headers to search, which are not part of the DataTableHeader.
 * @returns Array of matching items.
 */
export const searchItems = <T extends Record<string, unknown>>(
  items: T[],
  search: string | null | undefined,
  headers: DataTableHeader[],
  additionalHeaderKeys: string[]
): T[] => {
  if (!search) {
    return items;
  } else {
    // Extract header keys from DataTableHeader
    const headerKeys: string[] = headers.map((header) => header.value);
    const fuseOptions = {
      shouldSort: false,
      findAllMatches: true,
      threshold: 0,
      ignoreLocation: true,
      // useExtendedSearch: false, // Maybe?
      keys: additionalHeaderKeys?.length > 0 ?
        headerKeys.concat(additionalHeaderKeys) : headerKeys
    };
    const fuseInstance = new Fuse(items, fuseOptions);
    const searchResults: FuseResult<T>[] = fuseInstance.search(search);
    // Extract the original 'item' from each `FuseResult<T>` object
    const matchingItems: T[] = searchResults.map((result) => result.item);
    let customSearchedItems: T[] = [];
    // enables custom search function by specifying a filter function in the header (filter: (value, search, item) => boolean)
    headers.map((header) => {
      if (header.filter !== undefined) {
        customSearchedItems = items.filter((item) => header.filter && header?.filter(item[header.value], search, item));
      }
    });
    const returnVal = [...new Set(matchingItems.concat(customSearchedItems))];
    return returnVal;
  }
};

/**
 * Filters items by headers using filter values for each header
 * Values for each header are filtered with OR
 * Multiple headers are combined with AND
 * @param items The array of items to filter.
 * @param headerFilterValues An object containing filter values for each header.
 * @returns The filtered array of items.
 */
export const filterItemsByHeaders = <T extends Record<string, unknown>>(
  items: T[],
  headerFilterValues: { [key in keyof T]?: unknown[] }
): T[] => {
  // If no header filter values are provided, return the original items
  if (headerFilterValues === undefined || Object.keys(headerFilterValues).length === 0) {
    return items;
  } else {
    const headerKeys: string[] = Object.keys(headerFilterValues);
    // Deep copy of the items array
    let filteredItems: T[] = deepCopy(items);

    // Iterate over each header key
    headerKeys.forEach((key) => {
      const filterObjectArray = headerFilterValues[key];
      if (filterObjectArray !== undefined && filterObjectArray.length > 0) {
        const searchStrings: string[] = []; // Store search strings for explicit header
        // Convert filter values to search strings
        filterObjectArray.forEach(
          (element) => searchStrings.push(`="${String(element)}"`) // Only perfect matches, notation: ="searchString"
        );
        const searchString = searchStrings.join(' | '); // Combine search strings with OR operator

        if (searchStrings.length > 0) {
          const fuseOptions = {
            shouldSort: false,
            threshold: 0,
            ignoreLocation: true,
            useExtendedSearch: true,
            keys: [key]
          };
          const fuseInstance = new Fuse(filteredItems, fuseOptions);

          // Perform search and update filteredItems for next header
          filteredItems = fuseInstance.search(searchString).map((result) => result.item);
        }
      }
    });
    return filteredItems;
  }
};

/**
 * Filters items based on search query and header filter values.
 * @param originalItems The original array of items.
 * @param transformedItems The transformed array of items.
 * @param itemKey Unique id for item
 * @param search
 * @param headers The headers used for filtering.
 * @param headerFilterValues Values inside of headerFilter.
 * @param additionalSearchHeaderKeys Searchable keys that are not a seperate column in table.
 * @returns The filtered array of items.
 */
export const filterItems = <T extends Record<string, unknown>>(
  originalItems: T[],
  transformedItems: T[],
  itemKey: string,
  search: string | null | undefined,
  headers: DataTableHeader[],
  headerFilterValues: { [key in keyof T]?: unknown[] },
  additionalSearchHeaderKeys: string[]
): T[] => {
    if (!search && Object.keys(headerFilterValues).length === 0) {
      return originalItems;
    }
    // Deep copy of transformedItems to avoid mutation
    const transformedItemsCopy = deepCopy(transformedItems);
    const searchResults = searchItems(transformedItemsCopy, search?.trim(), headers, additionalSearchHeaderKeys);
    const filterResults = filterItemsByHeaders(searchResults, headerFilterValues);
    // Create a Map for quick lookup
    const itemMap = new Map(originalItems.map((item) => [item[itemKey], item]));
    return filterResults
      .map((r) => itemMap.get(r[itemKey]))
      .filter(Boolean) as T[];
};

export const replaceDelimiters = (value: string) => {
  const delimiters = ['.', '-', '/', ' '];
  return delimiters.reduce((acc, delimiter) => acc.replaceAll(delimiter, ''), value);
};

export const sortItems = <T extends Record<string, string | boolean | number | object>>(
  items: T[],
  sortBy: string[],
  sortDesc: boolean[],
  locale: string,
  customSorters?: Record<string, ((a: T, b: T) => number) | undefined>
): T[] => {
  sortBy?.forEach((column, idx) => {
    items = items.sort((a, b) => {
      const desc = sortDesc ? (sortDesc[idx] ? -1 : 1 ) : 1;
      const customSorter = customSorters?.[column];
      if (customSorter) {
        return customSorter(a, b) * desc;
      }
      return replaceDelimiters(String(column.split('.').reduce((o, i) => o[i], a)).toLowerCase()).localeCompare(
        replaceDelimiters(String(column.split('.').reduce((o, i) => o[i], b)).toLowerCase()),
        undefined,
        { numeric: true }
      ) * desc;
    });
  });
  return items;
};

export const mergeObjectArraysOnId = <
  T extends { id: symbol | string | number },
  S extends { id: symbol | string | number }
>(arrayOne: T[], arrayTwo: S[]) => {
  const mergedArrays = arrayOne.map((element) => {
    const callback = arrayTwo.find((el) => el.id === element.id);
    return { ...element, ...callback };
  });
  return mergedArrays as (T & S)[];
};

// recursive type for dot notation object
export const extractAndApplyKeyOnDotNotation = (item: Record<string, unknown> | string, value: string): string => {
  if (value.includes('.')) {
    // Type conversion ok because it will checked and converted in the next line
    const nextItem = item?.[value.split('.')?.[0]] as Record<string, unknown> | string;
    return extractAndApplyKeyOnDotNotation(
      typeof nextItem === 'object' ? nextItem : String(nextItem), value.split('.').slice(1).join('.')
    );
  }
  // if undefined or null will return, it will be replaced by an empty string which will be cut out of the filter later on
  return String(item[value.toString()]);
};

const removeDuplicateTuples = (array: Record<string, unknown>[]) => {
  const uniqueSet = new Set();
  return array.filter((tuple) => {
    const tupleString = JSON.stringify(tuple);
    if (uniqueSet.has(tupleString)) {
      return false;
    } else {
      uniqueSet.add(tupleString);
      return true;
    }
  });
};

const sortFilterValues = (array: string[] | string[][]) => array.sort((a: string | string[], b: string | string[]) => {
  if (a instanceof Array) {
    return a.join(',')
      .localeCompare(
        b instanceof Array ? b.join(',').toLowerCase() : b.toLowerCase()
      );
  } else {
    return a.toLowerCase()
      .localeCompare(
        b instanceof Array ? b.join(',').toLowerCase() : b.toLowerCase()
      );
  }
});

const removeUndefinedValues = (array) => array.filter((value) => value !== '' && value !== 'undefined' && value !== null);

export const returnFilterValues = (
  header: DataTableHeader,
  items: Record<string, unknown>[]
) => {
  // TODO: Delete the Array.isArray part once the group is a string instead of an array
  const filterValues = (items.map(
    (item) => header.valueTransformer
      ? header.valueTransformer(extractAndApplyKeyOnDotNotation(item, header.value), item)
      : Array.isArray(
        extractAndApplyKeyOnDotNotation(item, header.value))
        ? extractAndApplyKeyOnDotNotation(item, header.value)[0]
        : extractAndApplyKeyOnDotNotation(item, header.value)
  ));
  // make entries of filterValues unique
  const uniqueFilterValues = [...new Set(filterValues[0] instanceof Array ? filterValues.flat() : filterValues)];
  const sortedUniqueFilterValues = sortFilterValues(uniqueFilterValues);
  // Typecheck ok, filter undefined from localeCompare and empty strings/null
  // eslint-disable-next-line no-null/no-null

  // TODO: Sort Alphabetically, lower upper case indifferent, TDD please
  return removeDuplicateTuples(removeUndefinedValues(sortedUniqueFilterValues));
};
