/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { Account } from '@/store/types/identity';

export default {
  find() {
    return Api.get('identity/accounts');
  },
  getByName(name: string) {
    return Api.get('identity/accounts', {
      params: { name: name }
    });
  },
  getById(uuid: string) {
    return Api.get(`identity/accounts/${uuid}`);
  },
  create(account: Account) {
    return Api.post('identity/accounts', account);
  },
  update(account: Account) {
    return Api.put(`identity/accounts/${account.uuid}`, account);
  },
  // TODO delete not yet implemented in backend
  delete(uuid: string) {
    return Api.delete(`identity/accounts/${uuid}`);
  },
  linkUsersToAccount(uuid: string, body: any) {
    return Api.post(`identity/accounts/${uuid}/members`, body);
  },
  toggleInternalAccount(uuid: string) {
    return Api.put(`identity/accounts/${uuid}/internal`, {});
  }
};
