import { i18n } from '@/plugins/i18n';
import router from '@/router';
import { showErrorSnackbar, showSnackbar } from '@/util/snackBarUtil';
import axios, { AxiosRequestConfig } from 'axios';
import { TokenService } from './auth/TokenService';
import RefreshService from './RefreshService';

const baseURL = import.meta.env.VITE_API_BASE_URL as string;
let refreshPromise: Promise<unknown> | null;

const checkIfAccessTokenIsExpiring = () => {
  const tokenExpirationInMilliSeconds = Number(TokenService.getAccessTokenExpiration());
  const nowInMilliSeconds = Date.now();
  const minutesLeftUntilExpiration = (tokenExpirationInMilliSeconds - nowInMilliSeconds) / (60 * 1000) ;
  return minutesLeftUntilExpiration < 1;
};

const resendOriginalRequestWithNewToken = async (error: any) => {
  try {
    if (!refreshPromise){
      refreshPromise = RefreshService.getNewAccessToken();
    }
    return await refreshPromise
      .then(async () => {
      // Resend the original request with updated token
        const updatedAccessToken = TokenService.getAccessToken();
        const originalConfig = error.config as AxiosRequestConfig;
        if (originalConfig && originalConfig.headers && updatedAccessToken) {
          originalConfig.headers.Authorization = `Bearer ${updatedAccessToken}`;
          return axios.request(originalConfig)
            .then((response) => {
              refreshPromise = null;
              return response;
            });
        }
      });
  } catch (e) {
    // Resending failed again, log out
    refreshPromise = null;
    await RefreshService.cleanUpAndRedirectToLogin();
    return Promise.reject(error);
  }
};

const axiosInstance = axios.create({
  baseURL: baseURL
});

/**
 * Axios request interceptor to handle access token expiration and refresh.
 * The first two if cases cover two special cases when no access token is required
 * If the access token is expired, it automatically refreshes the token and retries the request.
 * If refreshing fails, the request is aborted.
 */
axiosInstance.interceptors.request.use(async (req) => {
  const accessToken = TokenService.getAccessToken();
  // Auth header is specified by the sender (AuthService)
  if (req.auth) {
    return req;
  }

  // NO Auth. Special case for 'getUserByResetPasswordCode' and 'SoftwareVersionApi.ts'
  else if (req.headers.Authorization === '') {
    delete req.headers.Authorization;
    return req;
  }

  else if (accessToken && !checkIfAccessTokenIsExpiring()) {
    req.headers.Authorization = `Bearer ${accessToken}`;
    return req;
  }

  else {
    if (!refreshPromise){
      refreshPromise = RefreshService.getNewAccessToken();
    }
    await refreshPromise
      .then(() => {
      // Return the original request with updated token
        const updatedAccessToken = TokenService.getAccessToken();
        req.headers.Authorization = `Bearer ${updatedAccessToken}`;
      })
      .catch(() => {
      // Abort the request, when refreshing failed
        const controller = new AbortController();
        req.signal = controller.signal;
        controller.abort();
      })
      .finally(() =>
        refreshPromise = null);
    return req;
  }
});


axiosInstance.interceptors.response.use(
  (response) => {
    // Reset to baseURL for next request, in case it was changed to a individualURL
    axiosInstance.defaults.baseURL = baseURL;
    return response;
  },
  async (error) => {
    if (axios.isCancel(error)) {
      // Nothing to do. RefreshService already clears and logs out, if refreshing failed
    }
    switch (error.response.status) {
      case 400:
        // caller should handle this, should use function "showApiErrorSnackbar"
        break;
      case 401:
        // should only happen if the token validity time span in the BE is shorter than in the FE
        if (error.config.baseURL.includes('login')) {
          throw error;
        } else {
          return resendOriginalRequestWithNewToken(error);
        }
      case 403:
        showSnackbar(i18n.t('errors.permissionDenied').toString());
        break;
      case 404:
        // caller should handle this, should use function "showApiErrorSnackbar"
        break;
      case 406:
        // special confirmation error
        break;
      case 409:
        // special download error
        break;
      case 500:
        await router.push('/500');
        break;
      default:
        showErrorSnackbar(i18n.t('errors.unkownError', {
          errorCode: `-${error.response.status}`
        }).toString());
        break;
    }
    return Promise.reject(error);
  });


const ApiService = {

  get:( resource: string, config = {} as AxiosRequestConfig, individualURL?: string ) => {
    if ( individualURL) {
      axiosInstance.defaults.baseURL = individualURL;
    }
    return axiosInstance.get(resource, config);
  },

  post:(resource: string, data: unknown, config = {} as AxiosRequestConfig, individualURL?: string) => {
    if ( individualURL) {
      axiosInstance.defaults.baseURL = individualURL;
    }
    return axiosInstance.post(resource, data, config);
  },

  put:(resource: string, data: unknown, config= {} as AxiosRequestConfig, individualURL?: string) => {
    if ( individualURL) {
      axiosInstance.defaults.baseURL = individualURL;
    }
    return axiosInstance.put(resource, data, config);
  },

  patch:(resource: string, data: unknown) =>
    axiosInstance.patch(resource, data),

  delete:(resource: string) =>
    axiosInstance.delete(resource)
};

export default ApiService;
