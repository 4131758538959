import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import * as labs from 'vuetify/labs/components';
import { colors } from '@/styles/colors';

// // workaround missing ripple error
// import { Ripple } from 'vuetify/lib/directives';
// Vue.use(Vuetify, {
//   directives: {
//     Ripple
//   }
// });
// // \

export const vuetify = createVuetify({
  components: {
    ...components,
    ...labs
  },
  directives,
  display: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 1270,
      lg: 1840
    }
  },

  theme: {
    themes: {
      light: {
        colors: {
          primary: colors.primary, // the insys blue from the website
          secondary: colors.secondary,
          success: colors.success,
          warning: colors.warning,
          danger: colors.danger,
          info: colors.info,
          greyBody: colors.body,
          darkShadow: colors.darkShadow,
          mediumShadow: colors.mediumShadow,
          topHeader: colors.topHeader
        }
      },
      dark: {
        colors: {
          primary: '#369bc3'
        }
      }
    }
  }
});
