<template>
  <v-data-table
    :id="config.tableId"
    v-bind="{ ...$attrs, ...$props }"
    :items="filteredItems"
    :item-value="itemKey"
    :headers="enrichedHeaders"
    :height="calcHeight"
    fixed-header
    return-object
    select-strategy="page"
    :items-per-page="itemsPerPage"
    :loading="innerLoading || loading"
    :hide-default-footer="filteredItems.length < 25"
    :items-per-page-options="pageFooter.options"
    :items-per-page-text="$t('common.itemsPerPage')"
    :loading-text="$t('common.loading')"
    :row-props="rowProps"
    hover
    @click:row="handleRowClick"
    @update:current-items="getCurrentItems($event)"
  >
    <template #top>
      <DataTableToolbar
        v-if="config.toolbarConfig"
        :data-table-id="config.tableId"
        :config="config.toolbarConfig"
        :table-id="config.tableId"
        :num-selected-elements="selected.length"
        :max-count="maxCount"
        @update:filter="updateFilter"
        @update:search="updateSearch"
      />
      <FilterRow
        v-show="!!filter"
        :id="filterRowId"
        :mount="!!filter"
        v-bind="$attrs"
        :items="items"
        :loading="innerLoading"
        :table-id="config.tableId"
        :consider-selected="!$attrs['no-select']"
        :enriched-headers="enrichedHeaders"
        @collect-filter-values="collectFilterValues"
        @clear-filter="clearFilterValues"
      />
    </template>

    <template #header.data-table-select>
      <v-checkbox-btn
        v-if="$attrs['select-strategy'] !== 'single'"
        :model-value="selectionState"
        hide-details
        color="primary"
        @click="handleSelectAll"
      />
    </template>
    <template #item.data-table-select="{ item }">
      <v-checkbox-btn
        v-if="!$attrs['no-select']"
        :model-value="isSelected(item)"
        hide-details
        color="primary"
        @click="handleRowClick"
      />
    </template>

    <template #no-data>
      <NoData
        v-if="config.noDataConfig && !search && !filter"
        :config="config.noDataConfig"
      />
      <span v-else-if="search || filter">
        {{ $t('common.noMatchingData') }}</span
      >
      <span v-else> {{ $t('common.noData') }}</span>
    </template>
    <!-- pass through normal slots-->
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>

    <!-- toggleable appended column -->
    <template v-if="$attrs['three-dot-menu']" #item.three-dot-menu="{ item }">
      <ThreeDotMenu
        :config="config.toolbarConfig?.actionsButtonConfig ?? []"
        :item="item"
      />
    </template>
  </v-data-table>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { eventBus } from '@/main';
import {
  DataTableHeader,
  DataTable,
  THREE_DOT_MENU
} from '@/components/types/DataTable';
import FilterRow from './table/FilterRow.vue';
import { filterItems, transformItemValues } from '@/util/dataTable';
import NoData from './table/NoData.vue';
import ThreeDotMenu from './table/ThreeDotMenu.vue';
import DataTableToolbar from './table/DataTableToolbar.vue';
import { mapState } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';

export default defineComponent({
  name: 'ExtendedDataTable',
  components: { NoData, ThreeDotMenu, DataTableToolbar, FilterRow },
  props: {
    items: {
      type: Array as () => Record<string, unknown>[],
      required: true
    },
    config: {
      type: Object as () => DataTable,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array as () => DataTableHeader[],
      required: true
    },
    itemKey: {
      type: String,
      required: false,
      default: 'uuid'
    },
    customHeight: {
      type: String,
      default: ''
    },
    outerElementsHeight: {
      type: Number,
      default: 0
    },
    additionalSearchHeaderKeys: {
      type: Array<string>,
      default: () => []
    }
  },
  emits: ['update:selected'],
  data() {
    return {
      headerAndToolbarOffset: 200, // appbar 40 + toolbar 48 + padding 8 + tableHeader 42 + footer 65
      rowHeight: 52,
      filter: false,
      innerLoading: true,
      itemsPerPage: 25,
      search: '',
      filterValues: {},
      THREE_DOT_MENU,
      transformedItemsForFiltering: [] as Record<string, unknown>[],
      selected: [] as Record<string, unknown>[],
      filterRowId: this.config.tableId + '-filterRow',
      windowHeight: window.innerHeight,
      pageFooter: {
        options: [
          { value: 25, title: '25' },
          { value: 50, title: '50' },
          { value: 75, title: '75' },
          { value: 100, title: '100' }
        ]
      },
      currentPageItems: [] as Record<string, unknown>[]
    };
  },

  computed: {
    ...mapState(authenticationStore, ['numberOfAppBars']),
    filterRowHeight() {
      const filterRow = document.querySelector(`#${this.config.tableId} thead`);
      return filterRow ? filterRow.offsetHeight : 0;
    },
    calcHeight() {
      if (this.customHeight !== '' && this.filteredItems.length !== 0) {
        return this.customHeight;
      } else {
        const remainingSpaceForTableItems =
          this.windowHeight -
          this.headerAndToolbarOffset -
          this.outerElementsHeight -
          this.filterRowHeight -
          this.numberOfAppBars * 40;

        const possibleNumberOfElements =
          remainingSpaceForTableItems / this.rowHeight;

        if (this.filteredItems.length === 0) {
          return undefined;
        }

        return possibleNumberOfElements > this.filteredItems.length
          ? undefined
          : remainingSpaceForTableItems;
      }
    },
    enrichedHeaders(): DataTableHeader[] {
      const headerCopy = [...this.headers];
      headerCopy.forEach((header) => {
        // If no sortable is set, set it to true
        if (header.sortable === undefined) header.sortable = true;
      });
      headerCopy[0].title = headerCopy[0].title
        .split('(')[0]
        .concat(` (${this.currentCount})`);
      return this.$attrs['three-dot-menu']
        ? [
            ...headerCopy,
            ...[
              {
                title: '',
                value: 'three-dot-menu',
                sortable: false,
                width: 24
              } as DataTableHeader
            ]
          ]
        : headerCopy;
    },
    currentCount() {
      return this.filteredItems.length;
    },
    // TODO: still needed?
    maxCount() {
      return this.items.length;
    },

    filteredItems(): Record<string, unknown>[] {
      if (this.transformedItemsForFiltering.length === 0) {
        this.transformItems(this.items);
      }
      return filterItems(
        [...this.items],
        this.transformedItemsForFiltering,
        this.itemKey,
        this.search,
        this.headers,
        this.filterValues,
        this.additionalSearchHeaderKeys
      );
    },
    selectionState() {
      return (
        this.selected.length > 0 &&
        this.currentPageItems.every((item) => this.selected.includes(item))
      );
    }
  },
  watch: {
    filter(newFilter) {
      void this.$nextTick(() => {
        if (!newFilter) {
          this.filterValues = {};
        }
      });
    },
    items(newItems: Record<string, unknown>[]) {
      this.transformItems(newItems);
    },
    filteredItems() {
      // Shows loading spinner until all rows are rendered after filtering
      void this.$nextTick(() => {
        const tableBody = document.querySelector(
          `#${this.config.tableId} tbody`
        );
        if (tableBody) {
          const rows = tableBody.querySelectorAll('tr'); // Check for rendered rows
          if (this.filteredItems.length === 0) this.innerLoading = false;

          if (
            !rows[0].className.includes('loading') &&
            (rows.length === this.itemsPerPage ||
              rows.length === this.filteredItems.length ||
              this.filteredItems.length === 0)
          ) {
            this.innerLoading = false;
          }
        }
      });
    },
    selected() {
      this.$emit('update:selected', this.selected);
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowSizeChange.bind());
  },

  mounted() {
    this.moveFilterRowIntoHeader();
    eventBus.$on(
      'updateSelectedItemsOfExtendedDataTable',
      (tableId: string, selectedItems: Record<string, unknown>[]) => {
        if (tableId === this.config.tableId) this.selected = selectedItems;
      }
    );
    eventBus.$on('activateTableLoading', (tableId: string) => {
      if (tableId === this.config.tableId) this.innerLoading = true;
    });
  },

  unmounted() {
    eventBus.$off('updateSelectedItemsOfExtendedDataTable');
    eventBus.$off('activateTableLoading');
    window.removeEventListener(
      'resize',
      this.handleWindowSizeChange.bind(this)
    );
  },
  methods: {
    handleSelectAll() {
      if (this.selectionState) {
        this.selected = this.selected.filter(
          (item) =>
            !this.currentPageItems.some(
              (currentPageItem) =>
                currentPageItem[this.itemKey] === item[this.itemKey]
            )
        );
      } else {
        this.selected.push(...this.currentPageItems);
        // Convert selected to Set and then back to array to remove duplicates
        this.selected = [...new Set(this.selected)];
      }
    },
    isSelected(item: Record<string, unknown>): boolean {
      return this.selected
        .flatMap((selectedItem) => selectedItem[this.itemKey])
        .includes(item[this.itemKey]);
    },
    getCurrentItems($event) {
      this.currentPageItems = $event.map((item) => item.raw);
    },
    handleWindowSizeChange() {
      this.windowHeight = window.innerHeight;
    },
    rowProps(data) {
      const rowProps = { class: '' };
      if (this.$attrs['custom-row-props']) {
        rowProps.class = this.$attrs['custom-row-props'](data);
      }
      if (
        !this.$attrs['no-select'] &&
        this.selected
          .flatMap((item) => item[this.itemKey])
          .includes(data.item[this.itemKey])
      ) {
        rowProps.class = 'selected-row';
      }
      return rowProps;
    },
    handleRowClick(_, row) {
      this.$attrs['select-strategy'] === 'single'
        ? this.clickRowSingle(row)
        : this.clickRow(row);
    },
    clickRow(row) {
      const rowIndex = this.selected.findIndex(
        (item) => item[this.itemKey] === row.item[this.itemKey]
      );
      if (rowIndex > -1) {
        this.selected.splice(rowIndex, 1);
      } else {
        this.selected.push(row.item);
      }
      this.$emit('update:selected', this.selected);
    },
    clickRowSingle(row) {
      if (this.selected[0] === row.item) this.selected = [];
      else this.selected = [row.item];
      this.$emit('update:selected', this.selected);
    },
    transformItems(rawItems: Record<string, unknown>[]) {
      if (rawItems.length !== 0 && !this.$attrs.loading) {
        this.transformedItemsForFiltering = [];
        this.transformedItemsForFiltering = transformItemValues(
          [...rawItems],
          this.headers
        );
      }
    },
    updateFilter(filter: boolean): void {
      this.filter = filter;
    },
    updateSearch(search: string): void {
      this.search = search;
    },
    clearFilterValues(): void {
      this.filterValues = {};
    },
    collectFilterValues(header: string, values: string[]): void {
      this.filterValues = {
        ...this.filterValues,
        [header]: values
      };
    },
    moveFilterRowIntoHeader() {
      // Directly select the specific table's header using the table ID
      const explicitHeader = document.querySelector(
        `#${this.config.tableId} thead`
      );
      const meRow = document.getElementById(this.filterRowId);
      if (meRow && explicitHeader) explicitHeader.appendChild(meRow);
    }
  }
});
</script>

<style lang="scss">
@import '@/styles/colors.scss';
.selected-row {
  background: $selected-row !important;
}
</style>
