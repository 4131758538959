/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { User } from '@/store/types/identity';

export default {
  find() {
    return Api.get('identity/users');
  },
  getByUsername(username: string) {
    return Api.get('identity/users', { params: { username: username } });
  },
  createUser(user: Partial<User>) {
    return Api.post('/identity/users', user);
  },
  updateUser(user: User) {
    return Api.put('identity/users/' + user.uuid, user);
  },
  resetPassword(id: string, data: string) {
    return Api.put(`identity/users/${id}/password-reset`, {
      password: data
    });
  },
  changePassword(id: string, oldPassword: string, password: string) {
    return Api.put(`identity/users/${id}/password`, {
      oldPassword: oldPassword,
      password: password
    });
  },
  getUsersNotInAccount(id: string) {
    return Api.get(
      `ui/identity/administration/accounts/${id}?usersNotInAccount`
    );
  },
  getUserByResetPasswordCode(code: string) {
    return Api.get(
      `ui/identity/reset-password/${code}`,
      {
        headers: {
          Authorization: ''
        }
      }
    );
  },
  lockUser(id: string) {
    return Api.put(`identity/users/${id}/lock`, '');
  },
  unlockUser(id: string) {
    return Api.put(`identity/users/${id}/unlock`, '');
  },
  sendReceivedFeedback(feedback: string, rating: number) {
    return Api.post('ui/identity/feedback', {
      feedback: feedback,
      rating: rating
    });
  }
};
