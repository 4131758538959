import ApiService from '../ApiService';
import { TierLevelObject, CheckPasswordObject } from '@/util/accounts';
import AuthService from '../auth/AuthService';
/**
 * Return the LicenseTierLevel for a certain account when given uuid
 * When no uuid is given, returns tier level of logged in account
 *
 * Response: {"tier": "0" // highest tier.}
 * @uuid The uuid of an account
 * @returns The tier level
 */
export const getAccountTierLevel = async (
  uuid?: string
): Promise<TierLevelObject> =>
  (
    await ApiService.get('ui/licensing/license-tier', {
      params: { accountID: uuid }
    })
  ).data as TierLevelObject;

/**
 * Returns whether the entered password is correct for the current logged in account
 * Used as a bypass for ics account creation, will be replaced by oauth
 **/

export const checkIfPasswordIsCorrect = async (
  password: string
): Promise<CheckPasswordObject> =>
  (await AuthService.checkPassword(password)) as CheckPasswordObject;
