/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { UpdatePacketType } from '@/store/types';

export default {
  getById(id: string | any) {
    return Api.get(`ui/autoupdate/update-packets/${id}`);
  },
  find() {
    return Api.get('ui/autoupdate/update-packets');
  },
  create(updatePacket: UpdatePacketType | any) {
    return Api.post('autoupdate/update-packets', updatePacket);
  },
  upload(id: string, file: File | any) {
    const formData = new FormData();
    formData.append('file', file);

    return Api.post(`autoupdate/update-packets/${id}/upload`, formData);
  },
  download(id: string) {
    return Api.get(`autoupdate/update-packets/${id}/download`, {
      responseType: 'blob'
    });
  },
  delete(uuid: string) {
    return Api.delete(`autoupdate/update-packets/${uuid}`);
  },
  update(updatePackage: UpdatePacketType) {
    return Api.put(
      `autoupdate/update-packets/${updatePackage.uuid}`,
      updatePackage
    );
  }
};
