/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  ApiTokenActionType,
  ApiTokenStateType,
  CreateApiTokenType,
  TokenType
} from '@/store/types/admin';
import TokenApi from '@/services/admin/TokenApi';
import { ACCESS_TOKEN_TYPE_STATIC } from '@/shared/constants';
import { i18n } from '@/plugins/i18n';
import { showApiErrorSnackbar, showSnackbar, createErrorMessage } from '@/util/snackBarUtil';

export default {
  namespaced: true,

  // initial state
  state: {
    tokens: []
  },

  // getters
  getters: {
    tokens(state: ApiTokenStateType) {
      return state.tokens;
    },
    staticTokens(state: ApiTokenStateType) {
      return state.tokens.filter(
        (token: TokenType) => token.tokenType === ACCESS_TOKEN_TYPE_STATIC
      );
    }
  },

  // actions
  actions: {
    async load({ commit }: ApiTokenActionType) {
      try {
        const response = await TokenApi.find();
        commit('SET_TOKENS', response.data.members);
      } catch (error) {
        showApiErrorSnackbar(`${i18n.t('common.genericError')}`, error);
      }
    },
    async create({ commit }: ApiTokenActionType, payload: CreateApiTokenType) {
      try {
        const response = await TokenApi.create(payload);
        const token = response.data;
        token.show = true;
        commit('SET_TOKEN', token);
        showSnackbar(
          `${i18n.t('settings.tokenCreated', { name: payload.name })}`
        );
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },
    async delete({ commit }: ApiTokenActionType, payload: TokenType) {
      try {
        const response = await TokenApi.delete(payload.uuid);
        if (response.status === 204) {
          commit('DELETE_TOKEN', payload);
          showSnackbar(
            `${i18n.t('settings.tokenDeleted', { name: payload.name })}`
          );
        }
      } catch (error) {
        showApiErrorSnackbar(`${i18n.t('common.genericError')}`, error);
      }
    }
  },

  // mutations
  mutations: {
    ['SET_TOKENS'](state: ApiTokenStateType, payload: TokenType[]) {
      state.tokens = payload;
    },
    ['SET_TOKEN'](state: ApiTokenStateType, payload: TokenType) {
      state.tokens.push(payload);
    },
    ['DELETE_TOKEN'](state: ApiTokenStateType, payload: TokenType) {
      const index = state.tokens.findIndex(
        (token: TokenType) => token.uuid === payload.uuid
      );

      if (index !== -1) {
        state.tokens.splice(index, 1);
      }
    }
  }
};
