<template>
  <v-alert
    data-cy="list-alert-no-elements"
    variant="tonal"
    prominent
    color="primary"
    :icon="config.icon ?? 'mdi-file-plus'"
    class="no-data-alert my-4"
    @click="buttonAction"
  >
    <v-row align="center">
      <v-col>{{ $t(config.alertMessage) }}</v-col>
      <v-col class="button-col">
        <v-btn
          data-cy="list-alert-no-elements-action-button"
          class="text-none"
          color="primary"
          variant="flat"
          >{{ $t(config.actionButtonText) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { NoDataConfig } from '../../types/DataTable';

export default defineComponent({
  name: 'NoData',
  props: {
    config: {
      type: Object as () => NoDataConfig,
      required: true
    }
  },
  methods: {
    buttonAction(): void {
      this.config.callback ? this.config.callback() : undefined;
    }
  }
});
</script>

<style scoped>
.no-data-alert {
  cursor: pointer;
  border-radius: 5px;
}
.button-col {
  max-width: fit-content;
}
</style>
