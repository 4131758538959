<template>
  <div class="text-center ma-6">
    <v-snackbar
      v-for="snackbarItem in snackbarItems"
      :key="snackbarItem.id"
      :model-value="{ vuetifySucks: true }.vuetifySucks"
      :color="snackbarItem.error ? 'warning' : 'grey-darken-3'"
      class="text--black"
      :data-cy="snackbarItem.error ? 'snackbar-error' : 'snackbar'"
      :timeout="snackbarItem.timeout"
      :contained="true"
    >
      <div style="max-width: 550px; word-break: break-word">
        {{ snackbarItem.text }}
      </div>
      <template #actions>
        <v-btn
          variant="text"
          icon
          @click="removeSnackbarItem(snackbarItem.id)"
          >{{ snackbarItem.buttonText ?? 'Ok' }}</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { snackbarStore } from '../../store/pinia/SnackbarStore';
import { mapActions, storeToRefs } from 'pinia';

export default defineComponent({
  name: 'SnackbarContainer',
  data() {
    return {
      snackbarItems: storeToRefs(snackbarStore()).snackbarItems
    };
  },
  methods: {
    ...mapActions(snackbarStore, ['removeSnackbarItem'])
  }
});
</script>
