/* eslint-disable @typescript-eslint/naming-convention */
export interface ApiTokenStateType {
  tokens: TokenType[];
}

export interface ApiTokenActionType {
  commit: (mutation: string, payload?: any) => {};
  dispatch: (action: string, payload?: any) => Promise<any>;
  state: ApiTokenStateType;
}

export interface TokenType extends Record<string, unknown> {
  uuid: string;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  username: string;
  token: string;
  tokenType: string;
  expiresAt: string;
  show?: boolean;
}

export interface CreateApiTokenType {
  name: string;
}

// eslint-disable-next-line no-shadow
export enum AccountTierLevel {
  UNLICENSED = '',
  TRIAL = '0',
  FREE = '1',
  BASIC = '2',
  INTERNAL = '3'
}

export enum SiteName {
  LICENSE_INFORMATION = 'licenseInfo'
}
export enum CountryCode {
  DE = 'DE',
  EN = 'EN'
}
export interface Link {
  id: string;
  meta: SiteName;
  lang: CountryCode;
  url: string;
}
