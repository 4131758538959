/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { SubjectType } from '@/shared/updateWizardSteps';
import AutoUpdateServerApi from '../../services/autoupdateServer/AutoUpdateServerApi';
import UpdatePacketApi from '../../services/autoupdate/UpdatePacketApi';
import { DEPLOYMENT_UPDATE_PACKET_FLAVOR_AUTOUPDATE_FULL } from '@/shared/constants';
import {
  EditDeploymentSubjectActionType,
  EditDeploymentSubjectStateType,
  UpdatePacketFromServerPayloadType,
  UpdatePacketType
} from '@/store/types';

export default {
  namespaced: true,
  // initial state
  state: (): EditDeploymentSubjectStateType => ({
    type: SubjectType.USER_UPDATE_PACKETS,
    insysUpdatePackets: [],
    alertText: '',
    selectedUpdatePacket: null,
    alertNoPacket: false,
    updatePacketId: '',
    selectedUpdatePacketFlavor: DEPLOYMENT_UPDATE_PACKET_FLAVOR_AUTOUPDATE_FULL
  }),

  // getters
  getters: {
    type(state: EditDeploymentSubjectStateType) {
      return state.type;
    },
    insysUpdatePackets(state: EditDeploymentSubjectStateType) {
      return state.insysUpdatePackets;
    },
    selectedUpdatePacket(state: EditDeploymentSubjectStateType) {
      return state.selectedUpdatePacket;
    },
    alertNoPacket(state: EditDeploymentSubjectStateType) {
      return state.alertNoPacket;
    },
    updatePacketId(state: EditDeploymentSubjectStateType) {
      return state.updatePacketId;
    },
    selectedUpdatePacketFlavor(state: EditDeploymentSubjectStateType) {
      return state.selectedUpdatePacketFlavor;
    }
  },

  // actions
  actions: {
    updateType: (
      { commit }: EditDeploymentSubjectActionType,
      data: SubjectType
    ) => {
      commit('SET_TYPE', data);
    },
    getSoftwareList: async ({ commit }: EditDeploymentSubjectActionType) => {
      try {
        const response = await AutoUpdateServerApi.getSoftwareList();
        const data = response.data;
        commit('SET_INSYS_UPDATE_PACKETS', data.members);
      } catch (err) {
        commit('SET_ALERT_TEXT', err.message);
      }
    },
    updateAlertNoPacket: (
      { commit }: EditDeploymentSubjectActionType,
      data: boolean
    ) => {
      commit('SET_NO_PACKET_ALERT', data);
    },
    selectUpdatePacket: (
      { commit }: EditDeploymentSubjectActionType,
      data: UpdatePacketType
    ) => {
      commit('SELECT_UPDATE_PACKET', data);
    },
    unselectUpdatePacket: ({ commit }: EditDeploymentSubjectActionType) => {
      commit('UNSELECT_UPDATE_PACKET');
    },
    updateSelectedUpdatePacketFlavor: (
      { commit }: EditDeploymentSubjectActionType,
      data: string
    ) => {
      commit('SET_SELECTED_UPDATE_PACKET_FLAVOR', data);
    },
    setUpdatePacketId: (
      { commit }: EditDeploymentSubjectActionType,
      data: string
    ) => {
      commit('SET_UPDATE_PACKET_ID', data);
    },
    getUpdatePacketFromServer: async (
      { commit }: EditDeploymentSubjectActionType,
      payload: UpdatePacketFromServerPayloadType
    ) => {
      const updatePacket = {
        description: payload.name,
        fileName: payload.name + '.tar',
        objectPrefix: '/uploads'
      };

      const result = await AutoUpdateServerApi.downloadUpdatePacket(
        payload.url
      );
      const downloadedPacket = result.data;
      const resp = await UpdatePacketApi.create(updatePacket);
      const respData = resp.data;

      const uploadResult = UpdatePacketApi.upload(
        respData.uuid,
        downloadedPacket
      );
      const uploadResultData = (await uploadResult).data;
      commit('SET_UPDATE_PACKET_ID', uploadResultData.uuid);
      return uploadResult;
    },
    reset: ({ commit }: EditDeploymentSubjectActionType) => {
      commit('RESET');
    }
  },

  // mutations
  mutations: {
    ['SET_TYPE'](state: EditDeploymentSubjectStateType, payload: SubjectType) {
      state.type = payload;
      state.selectedUpdatePacket = null;
      state.alertNoPacket = false;
      state.selectedUpdatePacketFlavor =
        DEPLOYMENT_UPDATE_PACKET_FLAVOR_AUTOUPDATE_FULL;
    },
    ['SET_INSYS_UPDATE_PACKETS'](
      state: EditDeploymentSubjectStateType,
      payload: UpdatePacketType[]
    ) {
      state.insysUpdatePackets = payload;
    },
    ['SET_ALERT_TEXT'](state: EditDeploymentSubjectStateType, payload: string) {
      state.alertText = payload;
    },
    ['SET_NO_PACKET_ALERT'](
      state: EditDeploymentSubjectStateType,
      payload: boolean
    ) {
      state.alertNoPacket = payload;
    },
    ['SELECT_UPDATE_PACKET'](
      state: EditDeploymentSubjectStateType,
      payload: UpdatePacketType | null
    ) {
      state.selectedUpdatePacket = payload;
    },
    ['UNSELECT_UPDATE_PACKET'](state: EditDeploymentSubjectStateType) {
      state.selectedUpdatePacket = null;
    },
    ['SET_SELECTED_UPDATE_PACKET_FLAVOR'](
      state: EditDeploymentSubjectStateType,
      payload: string
    ) {
      state.selectedUpdatePacketFlavor = payload;
    },
    ['SET_UPDATE_PACKET_ID'](
      state: EditDeploymentSubjectStateType,
      payload: string
    ) {
      state.updatePacketId = payload;
    },
    ['RESET'](state: EditDeploymentSubjectStateType) {
      state.type = SubjectType.USER_UPDATE_PACKETS;
      state.insysUpdatePackets = [];
      state.alertText = '';
      state.selectedUpdatePacket = null;
      state.alertNoPacket = false;
      state.updatePacketId = '';
      state.selectedUpdatePacketFlavor =
        DEPLOYMENT_UPDATE_PACKET_FLAVOR_AUTOUPDATE_FULL;
    }
  }
};
