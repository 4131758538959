/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { DEPLOYMENT_SUBJECT_UPDATE_PACKET, DEPLOYMENT_SUBJECT_CLI_TEMPLATE } from '@/models/rollouts/constants';
import Api from '@/services/ApiService';
import { SubjectType, TargetType } from '@/shared/updateWizardSteps';
import { AutoUpdateServer } from '@/store/types/autoupdate/AutoUpdateServer';
import { AxiosRequestConfig } from 'axios';
import { TemplateRow } from '@/store/types';
import { omitKeys } from '@/util';

// Timeout increased because of long rebuild times, remove after refactoring of autoUpdateServer
export const THIRTY_MINUTES_REBUILD_API_TIMEOUT = 1000 * 60 * 30;  // conversion ms to seconds, to minutes, times 30

export default {
  getSoftwareList() {
    return Api.get(
      '/current-update-packets.json',
      {
        headers: {
          Authorization: ''
        }
      },
      import.meta.env.VITE_AUTO_UPDATE_SERVER_URL
    );
  },
  downloadUpdatePacket(url: string) {
    const config: AxiosRequestConfig = {
      responseType: 'blob'
    };
    return Api.get(
      url,
      config,
      import.meta.env.VITE_AUTO_UPDATE_SERVER_URL
    );
  },
  async getAutoUpdateServerList(): Promise<AutoUpdateServer[]> {
    const response = await Api.get('/ui/rollout/autoupdate-servers');
    return response.data.members;
  },
  delete(server: AutoUpdateServer) {
    return Api.delete(`/rollout/deployments/${server.uuid}`);
  },
  updateServer(server: AutoUpdateServer) {
    return Api.put(`/rollout/deployments/${server.uuid}`, server, { timeout: THIRTY_MINUTES_REBUILD_API_TIMEOUT });
  },
  async getUpdateServerProfile(server: AutoUpdateServer) {
    const config: AxiosRequestConfig = {
      responseType: 'blob'
    };
    return Api.get(
      `/ui/rollout/autoupdate-server/${server.uuid}/update-profile`,
      config
    );
  }
};

export const DeploymentTypeStringToSubjectType = (
  v:
  | typeof DEPLOYMENT_SUBJECT_UPDATE_PACKET
  | typeof DEPLOYMENT_SUBJECT_CLI_TEMPLATE
  | string
): SubjectType => {
  switch (v) {
    case DEPLOYMENT_SUBJECT_UPDATE_PACKET:
      return SubjectType.USER_UPDATE_PACKETS;
    case DEPLOYMENT_SUBJECT_CLI_TEMPLATE:
      return SubjectType.CLI_TEMPLATES;
  }
  return SubjectType.USER_UPDATE_PACKETS;
};

export const TemplateRowsToDeploymentValues = (
  targetType: TargetType,
  templateValues: TemplateRow[]
) => {
  const POSSIBLE_KEYS = ['commonValues', 'targetValues'] as const;
  const res: Record<string, Record<string, unknown>> = targetType ===
  TargetType.TYPE_ALL
    ? {
      commonValues: templateValues.reduce((acc, value) => {
        const { uuid, insysIcomTargetName, ...paramVals } = omitKeys(value, [
          'displayName'
        ]);
        return Object.assign(acc, paramVals);
      }, {})
    }
    : {
      targetValues: templateValues.reduce((acc, value) => {
        const { uuid, insysIcomTargetName, ...paramVals } = omitKeys(value, [
          'displayName'
        ]);
        if (uuid) {
          acc[uuid] = paramVals;
        }
        return acc;
      }, {})
    };
  POSSIBLE_KEYS.forEach((key) => {
    if (!Object.keys(res[key] || {})?.length) {
      delete res[key];
    }
  });
  return res;
};
