/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CertificateResponseType } from '@/store/types';
import { CertificateAuthorityType } from '@/store/types/pki';

export default {
  find(): Promise<AxiosResponse<CertificateResponseType>> {
    return Api.get('/pki/certificate-authorities');
  },
  import(
    file: File,
    name: string,
    password: string
  ): Promise<AxiosResponse<CertificateResponseType>> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    if (password && password !== '') {
      formData.append('password', password);
    }
    return Api.post('/pki/certificate-authorities/import', formData);
  },
  importExternal(
    file: File,
    name: string
  ): Promise<AxiosResponse<CertificateResponseType>> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);

    return Api.post('/pki/certificate-authorities/import-external', formData);
  },
  download(uuid: string): Promise<AxiosResponse<any>> {
    const config: AxiosRequestConfig = {
      responseType: 'blob'
    };
    return Api.get(`/pki/certificate-authorities/${uuid}/download`, config);
  },
  delete(certificateAuthority: CertificateAuthorityType) {
    return Api.delete(
      `/pki/certificate-authorities/${certificateAuthority.uuid}`
    );
  },
  updateCA(
    certificateAuthority: CertificateAuthorityType
  ): Promise<AxiosResponse<CertificateAuthorityType>> {
    return Api.put(
      `/pki/certificate-authorities/${certificateAuthority.uuid}`,
      certificateAuthority
    );
  }
};
