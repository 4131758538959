/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';

export default {
  run(id: string | any) {
    return Api.post(`rollout/update-jobs/${id}/run`, null);
  },
  retry(id: string | any) {
    return Api.post(`rollout/update-jobs/${id}/retry`, null);
  }
};
