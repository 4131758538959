/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { Organisation } from '@/store/types/identity';

export default {
  find() {
    return Api.get('identity/organisations');
  },
  getById(uuid: string) {
    return Api.get(`identity/organisations/${uuid}`);
  },
  create(org: Organisation) {
    return Api.post('identity/organisations', org);
  },
  delete(uuid: string) {
    return Api.delete(`identity/organisations/${uuid}`);
  },
  updateOrganisation(organisation: Organisation) {
    return Api.put(`identity/organisations/${organisation.uuid}`, organisation);
  }
};
