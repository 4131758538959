/* eslint-disable prefer-arrow/prefer-arrow-functions */
import DeploymentApi from '../../../services/rollout/DeploymentApi';
import {
  DeleteTargetStateType,
  DeleteTargetActionType,
  DeleteTargetAddTargetType,
  DeleteTargetAlterTimeoutType,
  Device,
  DeploymentType,
  DeploymentTypeCliTemplate
} from '@/store/types';

import { DEPLOYMENT_SUBJECT_CLI_TEMPLATE } from '@/models/rollouts/constants';

export default {
  namespaced: true,
  // initial state
  state: () => ({
    visibility: false,
    deploymentTargetsToDelete: {},
    timeoutFunction: {}
  }),

  // getters
  getters: {
    visibility(state: DeleteTargetStateType): boolean {
      return state.visibility;
    },
    targetsToDelete:
      (state: DeleteTargetStateType) =>
        (deploymentUuid: string): Device[] =>
          state.deploymentTargetsToDelete[deploymentUuid] || new Array<Device>(),
    deploymentChanging:
      (state: DeleteTargetStateType) =>
        (deploymentUuid: string): boolean =>
          state.timeoutFunction[deploymentUuid] !== undefined
  },

  // actions
  actions: {
    show: ({ commit }: DeleteTargetActionType) => {
      commit('SHOW_SNACKBAR');
    },
    hideAndClear: ({ commit }: DeleteTargetActionType, payload: string) => {
      commit('CLEAR_TIMEOUT', payload);
      commit('HIDE_SNACKBAR');
    },
    hideAndExecute: (
      { commit, dispatch }: DeleteTargetActionType,
      payload: string
    ) => {
      dispatch('deleteTargets', payload);
      commit('HIDE_SNACKBAR');
    },
    saveTimeout: (
      { commit, dispatch }: DeleteTargetActionType,
      deploymentUuid: string
    ) => {
      const timeoutId = setTimeout(() => {
        dispatch('deleteTargets', deploymentUuid);
      }, 10 * 1000);

      commit('SET_TIMEOUT', {
        deploymentUuid: deploymentUuid,
        timeoutId: timeoutId
      });
    },
    addTargetToDelete: (
      { commit, state, dispatch }: DeleteTargetActionType,
      payload: DeleteTargetAddTargetType
    ) => {
      commit('ADD_TARGET_TO_DELETE', payload);

      // if there is already a target to delete for this deployment
      // clear timeout
      if (state.deploymentTargetsToDelete[payload.deploymentUuid].length > 1) {
        dispatch('clearTimeout');
      }

      // set timeout for new target to delete
      dispatch('saveTimeout', payload.deploymentUuid);

      // show snackbar
      dispatch('show');
    },
    deleteTargets: (
      { state, dispatch, rootState }: DeleteTargetActionType,
      deploymentUuid: string
    ) => {
      // get the deployment from root state
      const deployment = rootState.updateJobs.updateJobs.find(
        (uj: DeploymentType) => uj.uuid === deploymentUuid
      );

      // check if deployment is set and has value in the deploymentTargetsToDelete list
      if (
        deployment === undefined ||
        state.deploymentTargetsToDelete[deploymentUuid] === undefined
      ) {
        return;
      }

      const newTargetList = deployment.target;
      const newUpdatePacketList = deployment.updatePacket?.targetUpdatePackets;

      state.deploymentTargetsToDelete[deploymentUuid].forEach((target) => {
        newTargetList.splice(newTargetList.indexOf(target.uuid), 1);
        if (newUpdatePacketList !== undefined) {
          Vue.delete(newUpdatePacketList, target.uuid);
        }
      });

      let body: DeploymentType | DeploymentTypeCliTemplate;

      if (deployment.subject === DEPLOYMENT_SUBJECT_CLI_TEMPLATE) {
        const template = deployment.cliTemplate;

        if (template !== undefined) {
          // find targets in template that are removed from deployment
          for (const id in template.targetValues) {
            // delete target values for removed targets
            if (!newTargetList.includes(id)) {
              delete template.targetValues[id];
            }
          }
        }

        body = {
          target: newTargetList,
          name: deployment.name,
          description: deployment.description,
          targetType: deployment.targetType,
          subject: deployment.subject,
          method: deployment.method,
          updateJob: deployment.updateJob,
          cliTemplate: template
        };
      } else {
        body = {
          target: newTargetList,
          name: deployment.name,
          description: deployment.description,
          targetType: deployment.targetType,
          subject: deployment.subject,
          method: deployment.method,
          updateJob: deployment.updateJob,
          updatePacket: {
            commonUpdatePacket: deployment.updatePacket?.commonUpdatePacket,
            targetUpdatePackets: newUpdatePacketList
          }
        };
      }

      return DeploymentApi.update(deployment.uuid, body)
        .then(() => {
          dispatch('removeTargets', deploymentUuid);
        })
        .then(() => {
          dispatch('updateJobs/load', null, { root: true });
        });
    },
    removeTargets: ({ commit }: DeleteTargetActionType, payload: string) => {
      commit('REMOVE_TARGET', payload);
      commit('CLEAR_TIMEOUT', payload);
    },
    clearTimeout: ({ commit }: DeleteTargetActionType, payload: string) => {
      commit('CLEAR_TIMEOUT', payload);
    }
  },

  // mutations
  mutations: {
    ['SHOW_SNACKBAR'](state: DeleteTargetStateType) {
      state.visibility = true;
    },
    ['HIDE_SNACKBAR'](state: DeleteTargetStateType) {
      state.visibility = false;
    },
    ['ADD_TARGET_TO_DELETE'](
      state: DeleteTargetStateType,
      payload: DeleteTargetAddTargetType
    ) {
    //   if (
    //     state.deploymentTargetsToDelete[payload.deploymentUuid] === undefined
    //   ) {
      // Vue.set(
      //   state.deploymentTargetsToDelete,
      //   payload.deploymentUuid,
      //   new Array<Device>()
      // );
    //   }
    //   Vue.set(
    //     state.deploymentTargetsToDelete[payload.deploymentUuid],
    //     state.deploymentTargetsToDelete[payload.deploymentUuid].length,
    //     payload.target
    //   );
      if (!state.deploymentTargetsToDelete[payload.deploymentUuid]) {
        state.deploymentTargetsToDelete[payload.deploymentUuid] = [];
      }
      state.deploymentTargetsToDelete[payload.deploymentUuid].push(payload.target);
    },
    ['REMOVE_TARGET'](state: DeleteTargetStateType, payload: string) {
      //   Vue.delete(state.deploymentTargetsToDelete, payload);
      delete state.deploymentTargetsToDelete[payload];
    },
    ['CLEAR_TIMEOUT'](state: DeleteTargetStateType, payload: string) {
      const timeoutId = state.timeoutFunction[payload];
      clearTimeout(timeoutId);
      // Vue.delete(state.timeoutFunction, payload);
      delete state.deploymentTargetsToDelete[payload];
    },
    ['SET_TIMEOUT'](
      state: DeleteTargetStateType,
      payload: DeleteTargetAlterTimeoutType
    ) {
      // Vue.set(state.timeoutFunction, payload.deploymentUuid, payload.timeoutId);
      state.timeoutFunction[payload.deploymentUuid] = payload.timeoutId;
    }
  }
};
