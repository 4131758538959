/* eslint-disable prefer-arrow/prefer-arrow-functions */
export class SoftwareVersion {
  public buildVersion: string;
  public buildTime: string;
  public buildHash: string;
  public serialNumber: string;
  public constructor({
    buildVersion = '',
    buildTime = '',
    buildHash = '',
    serialNumber = ''
  } = {}) {
    this.buildVersion = buildVersion;
    this.buildTime = buildTime;
    this.buildHash = buildHash;
    this.serialNumber = serialNumber;
  }
}

export function immutableSoftwareVersion(data) {
  return Object.freeze(new SoftwareVersion(data));
}
