/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { defineStore } from 'pinia';
import UsersApi from '@/services/identity/UsersApi';
import { i18n } from '@/plugins/i18n';
import { showSnackbar, showApiErrorSnackbar, createErrorMessage } from '@/util/snackBarUtil';
import { User } from '../types/identity/index';

interface State {
  users: User[];
  passwordError: Error | undefined;
}
export const userStore = defineStore('userStore', {
  state: (): State => ({
    users: [],
    passwordError: undefined
  }),

  getters: {
    getUsers: (state: State) => state.users,
    getPasswordError: (state: State) => state.passwordError
  },

  actions: {
    async fetchUsers(errorHandling = true) {
      try {
        this.users = (await UsersApi.find()).data.members as User[];
      } catch (error) {
        if (errorHandling) {
          showApiErrorSnackbar(createErrorMessage(i18n.tc('errors.fetchUsers')), error);
        }
        throw error;
      }
    },
    async updateUser(userToUpdate: User) {
      try {
        const response = await UsersApi.updateUser(userToUpdate);
        this.editUser(response.data);
        showSnackbar(
          i18n.t('settings.dialogEditedUser', {
            msg: userToUpdate.username
          }).toString()
        );
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },
    async createUser(newUser: Partial<User>) {
      try {
        const response = await UsersApi.createUser(newUser);
        this.editUser(response.data);
        showSnackbar(
          i18n.t('administration.dialogCreatedUser', {
            msg: newUser.username
          }).toString()
        );
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },
    async resetPassword(user: User, password: string) {
      try {
        await UsersApi.resetPassword(user.uuid, password);
        showSnackbar(
          i18n.t('administration.dialogResetPasswordForUser', {
            msg: user.username
          }).toString()
        );
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },
    async changePassword(user: User, oldPassword: string, newPassword: string) {
      try {
        await UsersApi.changePassword( user.uuid, oldPassword, newPassword );
        showSnackbar( i18n.t('administration.passwordChangeSuccess' ).toString() );
        this.resetPasswordError();
      } catch (error) {
        this.passwordError = error;
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },
    resetPasswordError() {
      this.passwordError = undefined;
    },
    async lockUser(userUuid: string) {
      try {
        const response = await UsersApi.lockUser(userUuid);
        this.editUser(response.data);
        showSnackbar(
          i18n.t('administration.lockUserSuccess', {
            msg: response.data.username
          }).toString()
        );
      } catch (error) {
        showApiErrorSnackbar(
          i18n.t('administration.errorLockUser', {
            msg: createErrorMessage(err)
          }).toString(), error
        );
      }
    },
    async unlockUser(userUuid: string) {
      try {
        const response = await UsersApi.unlockUser(userUuid);
        this.editUser(response.data);
        showSnackbar(
          i18n.t('administration.unlockUserSuccess', {
            msg: response.data.username
          }).toString()
        );
      } catch (error) {
        showApiErrorSnackbar(
          i18n.t('administration.errorUnlockUser', {
            msg: createErrorMessage(err)
          }).toString(), error
        );
      }
    },
    async sendReceivedFeedback(feedback: string, rating: number) {
      try {
        await UsersApi.sendReceivedFeedback(feedback, rating);
        showSnackbar(i18n.t('app.successFeedback').toString());
      } catch (error) {
        showApiErrorSnackbar(
          i18n.t('app.errorFeedback', {
            msg: createErrorMessage(error)
          }).toString(), error
        );
      }
    },

    editUser(updateUser: User) {
      const index = this.users.findIndex((user) => user.uuid === updateUser.uuid);
      if (index === -1) {
        this.users.push(updateUser);
      } else {
        this.users.splice(index, 1, updateUser);
      }
    }
  }
});
