/* eslint-disable prefer-arrow/prefer-arrow-functions */
import DeploymentApi from '../../../services/rollout/DeploymentApi';
import {
  DeleteUpdateServerTargetActionType,
  TargetDeleteType,
  DeleteUpdateServerTargetStateType
} from '@/store/types';
export default {
  namespaced: true,
  // initial state
  state: () => ({
    visibility: false,
    itemsToDelete: [],
    timeoutFunction: undefined
  }),

  // getters
  getters: {
    visibility(state: DeleteUpdateServerTargetStateType) {
      return state.visibility;
    },
    itemsToDelete(state: DeleteUpdateServerTargetStateType) {
      return state.itemsToDelete;
    }
  },

  // actions
  actions: {
    show: ({ commit }: DeleteUpdateServerTargetActionType) => {
      commit('SHOW_SNACKBAR');
    },
    hide: ({ commit }: DeleteUpdateServerTargetActionType) => {
      commit('HIDE_SNACKBAR');
    },
    addTargetToDelete: (
      { commit, state, dispatch }: DeleteUpdateServerTargetActionType,
      payload: TargetDeleteType
    ) => {
      commit('ADD_TARGET_TO_DELETE', payload);
      if (state.itemsToDelete.length > 1) {
        dispatch('clearTimeout');
        dispatch('deleteTarget');
      }

      dispatch('show');
      commit(
        'SET_TIMEOUT',
        setTimeout(() => {
          dispatch('deleteTarget');
        }, 36000)
      );
    },
    deleteTarget: ({ state, dispatch }: DeleteUpdateServerTargetActionType) => {
      const newTarget = state.itemsToDelete[0].deployment.target;
      newTarget.splice(
        state.itemsToDelete[0].deployment.target.indexOf(
          state.itemsToDelete[0].target.uuid
        ),
        1
      );

      return DeploymentApi.update(state.itemsToDelete[0].deployment.uuid, {
        target: newTarget,
        name: state.itemsToDelete[0].deployment.name,
        description: state.itemsToDelete[0].deployment.description,
        targetType: state.itemsToDelete[0].deployment.targetType,
        subject: state.itemsToDelete[0].deployment.subject,
        method: state.itemsToDelete[0].deployment.method,
        updatePacket: state.itemsToDelete[0].deployment.updatePacket,
        autoUpdate: state.itemsToDelete[0].deployment.autoUpdate
      }).then(() => {
        dispatch('removeTarget');
      });
    },
    removeTarget: ({ commit }: DeleteUpdateServerTargetActionType) => {
      commit('REMOVE_TARGET');
    },
    clearTimeout: ({ commit }: DeleteUpdateServerTargetActionType) => {
      commit('CLEAR_TIMEOUT');
    }
  },

  // mutations
  mutations: {
    ['SHOW_SNACKBAR'](state: DeleteUpdateServerTargetStateType) {
      state.visibility = true;
    },
    ['HIDE_SNACKBAR'](state: DeleteUpdateServerTargetStateType) {
      state.visibility = false;
    },
    ['ADD_TARGET_TO_DELETE'](
      state: DeleteUpdateServerTargetStateType,
      payload: TargetDeleteType
    ) {
      state.itemsToDelete.push(payload);
    },
    ['REMOVE_TARGET'](state: DeleteUpdateServerTargetStateType) {
      state.itemsToDelete.shift();
    },
    ['CLEAR_TIMEOUT'](state: DeleteUpdateServerTargetStateType) {
      clearTimeout(state.timeoutFunction);
    },
    ['SET_TIMEOUT'](state: DeleteUpdateServerTargetStateType, payload: number) {
      state.timeoutFunction = payload;
    }
  }
};
