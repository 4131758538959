/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { DeploymentType, DeploymentTypeCliTemplate } from '@/store/types';
import { THIRTY_MINUTES_REBUILD_API_TIMEOUT } from '../autoupdateServer/AutoUpdateServerApi';

export default {
  find() {
    return Api.get('rollout/deployments');
  },
  create(deployment: DeploymentType | DeploymentTypeCliTemplate) {
    return Api.post('rollout/deployments', deployment);
  },
  update(
    deploymentId: string | undefined,
    body: DeploymentType | DeploymentTypeCliTemplate
  ) {
    return Api.put(`rollout/deployments/${deploymentId}`, body);
  },
  rebuildUpdatePacket(deploymentId: string | undefined) {
    return Api.post(`/rollout/deployments/${deploymentId}/rebuild`, undefined, { timeout: THIRTY_MINUTES_REBUILD_API_TIMEOUT });
  },
  deleteById(deploymentId: string | undefined) {
    return Api.delete(`rollout/deployments/${deploymentId}`);
  }
};
