<template>
  <v-container class="pa-0">
    <v-row no-gutters :justify="alignment">
      <v-img
        :src="$filters.assetUrl('assets/de.svg')"
        max-width="28"
        data-cy="language-switch-de"
        class="pointer-cursor"
        @click="setLanguageToGerman"
      ></v-img>
      <v-img
        class="ml-2 pointer-cursor"
        :src="$filters.assetUrl('assets/en.svg')"
        max-width="28"
        data-cy="language-switch-en"
        @click="setLanguageToEnglish"
      ></v-img>
    </v-row>
  </v-container>
</template>

<script>
import { i18n } from '@/plugins/i18n';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LanguageButtons',
  props: {
    alignment: {
      type: String,
      default: 'end'
    }
  },
  methods: {
    setLanguageToEnglish() {
      localStorage.setItem('currentLang', 'en-GB');
      i18n.locale = 'en-GB';
    },
    setLanguageToGerman() {
      localStorage.setItem('currentLang', 'de-DE');
      i18n.locale = 'de-DE';
    }
  }
});
</script>

<style lang="scss" scoped>
.row {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}
</style>
