/* eslint-disable @typescript-eslint/naming-convention */
export const constants = {
  UPDATE_PACKET_STATUS_AVAILABLE: 'AVAILABLE',
  UPDATE_PACKET_STATUS_UPLOADING: 'UPLOADING',
  UPDATE_PACKET_STATUS_LINKED: 'LINKED',

  CONFIG_CHANGE_TEMPLATE_STATUS_AVAILABLE: 'AVAILABLE',
  CONFIG_CHANGE_TEMPLATE_STATUS_LINKED: 'LINKED',

  RESOURCE_STATUS_AVAILABLE: 'AVAILABLE',
  RESOURCE_STATUS_UPLOADING: 'UPLOADING',

  RESOURCE_FILE_TYPE_FULL_SOFTWARE_UPDATE: 'FULL_SOFTWARE_UPDATE',
  RESOURCE_FILE_TYPE_INCREMENTAL_SOFTWARE_UPDATE: 'INCREMENTAL_SOFTWARE_UPDATE',
  RESOURCE_FILE_TYPE_DSL_UPDATE: 'DSL_UPDATE',
  RESOURCE_FILE_TYPE_CONTAINER: 'CONTAINER',
  RESOURCE_FILE_TYPE_CONTAINER_CONFIGURATION: 'CONTAINER_CONFIGURATION',
  RESOURCE_FILE_TYPE_LICENCE: 'LICENCE',
  RESOURCE_FILE_TYPE_BINARY_CONFIGURATION: 'BINARY_CONFIGURATION',
  RESOURCE_FILE_TYPE_ASCII_CONFIG: 'ASCII_CONFIG',
  RESOURCE_FILE_TYPE_STORED_ASCII_CONFIG: 'STORED_ASCII_CONFIG',
  RESOURCE_FILE_TYPE_ASCII_CONFIG_ICS: 'ASCII_CONFIG_ICS',
  RESOURCE_FILE_TYPE_OEM_BRANDING: 'OEM_BRANDING',
  METHOD_AUTO_UPDATE: 'AUTO_UPDATE'
} as const;

// A map to parse file tapes into a readable format
export const READABLE_FILE_TYPE_MAP = {
  [constants.RESOURCE_FILE_TYPE_FULL_SOFTWARE_UPDATE]: 'Full Software Update',
  [constants.RESOURCE_FILE_TYPE_INCREMENTAL_SOFTWARE_UPDATE]:
    'Incr. Software Update',
  [constants.RESOURCE_FILE_TYPE_DSL_UPDATE]: 'DSL Update',
  [constants.RESOURCE_FILE_TYPE_CONTAINER]: 'Container',
  [constants.RESOURCE_FILE_TYPE_CONTAINER_CONFIGURATION]:
    'Container-Configuration',
  [constants.RESOURCE_FILE_TYPE_LICENCE]: 'License',
  [constants.RESOURCE_FILE_TYPE_BINARY_CONFIGURATION]: 'Binary-Configuration',
  [constants.RESOURCE_FILE_TYPE_ASCII_CONFIG]: 'ASCII-Configuration',
  [constants.RESOURCE_FILE_TYPE_STORED_ASCII_CONFIG]:
    'Stored ASCII-Configuration',
  [constants.RESOURCE_FILE_TYPE_ASCII_CONFIG_ICS]: 'ASCII-Configuration ICS',
  [constants.RESOURCE_FILE_TYPE_OEM_BRANDING]: 'OEM Branding'
} as const;

export const translateFileType = (value: string): string =>
  ({
    [constants.RESOURCE_FILE_TYPE_FULL_SOFTWARE_UPDATE]: 'Full Software Update',
    [constants.RESOURCE_FILE_TYPE_INCREMENTAL_SOFTWARE_UPDATE]:
    'Incr. Software Update',
    [constants.RESOURCE_FILE_TYPE_DSL_UPDATE]: 'DSL Update',
    [constants.RESOURCE_FILE_TYPE_CONTAINER]: 'Container',
    [constants.RESOURCE_FILE_TYPE_CONTAINER_CONFIGURATION]:
    'Container-Configuration',
    [constants.RESOURCE_FILE_TYPE_LICENCE]: 'License',
    [constants.RESOURCE_FILE_TYPE_BINARY_CONFIGURATION]: 'Binary-Configuration',
    [constants.RESOURCE_FILE_TYPE_ASCII_CONFIG]: 'ASCII-Configuration',
    [constants.RESOURCE_FILE_TYPE_STORED_ASCII_CONFIG]:
    'Stored ASCII-Configuration',
    [constants.RESOURCE_FILE_TYPE_ASCII_CONFIG_ICS]: 'ASCII-Configuration ICS',
    [constants.RESOURCE_FILE_TYPE_OEM_BRANDING]: 'OEM Branding'
  }[value] || '');
