/* eslint-disable prefer-arrow/prefer-arrow-functions, max-lines */
import {
  CliTemplateType,
  DeploymentType,
  DeploymentTypeCliTemplate,
  Device,
  DeviceGroup,
  UpdatePacketFromServerPayloadType,
  UpdatePacketType,
  UpdateWizardActionType,
  UpdateWizardStateType
} from '@/store/types';
import {
  ChooseMethod,
  ChooseSubject,
  TargetType,
  SubjectType,
  UpdateWizardStep,
  UpdateWizardStepState
} from '@/shared/updateWizardSteps';
import AutoUpdateServerApi from '@/services/autoupdateServer/AutoUpdateServerApi';
import DeploymentApi from '@/services/rollout/DeploymentApi';
import UpdatePacketApi from '@/services/autoupdate/UpdatePacketApi';
import router from '@/router';
import { DEPLOYMENT_UPDATE_PACKET_FLAVOR_AUTOUPDATE_FULL } from '@/shared/constants';

export default {
  namespaced: true,
  // initial state
  state: (): UpdateWizardStateType => ({
    step: UpdateWizardStep.CHOOSE_TARGET as UpdateWizardStep,
    chooseTargetState: UpdateWizardStepState.INIT,
    chooseSubjectState: UpdateWizardStepState.INIT,
    chooseMethodState: UpdateWizardStepState.INIT,
    subjectType: null,
    firmwareOrSoftwareType: SubjectType.USER_UPDATE_PACKETS,
    targetType: TargetType.TYPE_MANAGED_DEVICES,
    selectedDevices: [],
    selectedGroups: [],
    alertNoDevices: false,
    alertNoGroups: false,
    alertNoPacket: false,
    alertNoTemplate: false,
    methodType: null,
    insysUpdatePackets: [],
    selectedUpdatePacket: null,
    selectedUpdatePacketFlavor: DEPLOYMENT_UPDATE_PACKET_FLAVOR_AUTOUPDATE_FULL,
    selectedCliTemplate: null,
    cliTableRows: [],
    updateJobName: '',
    alertText: '',
    updatePacketId: '',
    serverAlias: '',
    updating: false,
    autoUpdateTime: '0:00',
    startUpdateJobImmediately: false,
    finished: false,
    allStepsStateSuccess: false
  }),

  // getters
  getters: {
    step(state: UpdateWizardStateType) {
      return state.step;
    },
    chooseTargetState(state: UpdateWizardStateType) {
      return state.chooseTargetState;
    },
    chooseSubjectState(state: UpdateWizardStateType) {
      return state.chooseSubjectState;
    },
    chooseMethodState(state: UpdateWizardStateType) {
      return state.chooseMethodState;
    },
    subjectType(state: UpdateWizardStateType) {
      return state.subjectType;
    },
    firmwareOrSoftwareType(state: UpdateWizardStateType) {
      return state.firmwareOrSoftwareType;
    },
    targetType(state: UpdateWizardStateType) {
      return state.targetType;
    },
    selectedDevices(state: UpdateWizardStateType) {
      return state.selectedDevices;
    },
    selectedGroups(state: UpdateWizardStateType) {
      return state.selectedGroups;
    },
    alertNoDevices(state: UpdateWizardStateType) {
      return state.alertNoDevices;
    },
    alertNoGroups(state: UpdateWizardStateType) {
      return state.alertNoGroups;
    },
    alertNoPacket(state: UpdateWizardStateType) {
      return state.alertNoPacket;
    },
    alertNoTemplate(state: UpdateWizardStateType) {
      return state.alertNoTemplate;
    },
    methodType(state: UpdateWizardStateType) {
      return state.methodType;
    },
    insysUpdatePackets(state: UpdateWizardStateType) {
      return state.insysUpdatePackets;
    },
    selectedUpdatePacket(state: UpdateWizardStateType) {
      return state.selectedUpdatePacket;
    },
    selectedCliTemplate(state: UpdateWizardStateType) {
      return state.selectedCliTemplate;
    },
    cliTableRows(state: UpdateWizardStateType) {
      return state.cliTableRows;
    },
    cliTableRowsWithMissingCert:
      (state: UpdateWizardStateType) => (certificateName: string) =>
        state.cliTableRows.filter((cliTableRow) => {
          const hasProperty = Object.prototype.hasOwnProperty.call(
            cliTableRow,
            certificateName
          );
          return hasProperty && cliTableRow[certificateName] === '';
        }),
    updateJobName(state: UpdateWizardStateType) {
      return state.updateJobName;
    },
    updatePacketId(state: UpdateWizardStateType) {
      return state.updatePacketId;
    },
    serverAlias(state: UpdateWizardStateType) {
      return state.serverAlias;
    },
    updating(state: UpdateWizardStateType) {
      return state.updating;
    },
    autoUpdateTime(state: UpdateWizardStateType) {
      return state.autoUpdateTime;
    },
    startUpdateJobImmediately(state: UpdateWizardStateType) {
      return state.startUpdateJobImmediately;
    },
    selectedUpdatePacketFlavor(state: UpdateWizardStateType) {
      return state.selectedUpdatePacketFlavor;
    },
    finished(state: UpdateWizardStateType) {
      return state.finished;
    },
    allStepsStateSuccess(state: UpdateWizardStateType) {
      return state.allStepsStateSuccess;
    }
  },

  // actions
  actions: {
    updateStep: (
      { commit }: UpdateWizardActionType,
      data: UpdateWizardStep
    ) => {
      commit('SET_STEP', data);
    },
    updateSubjectType: (
      { commit }: UpdateWizardActionType,
      data: ChooseSubject | null
    ) => {
      commit('SET_SUBJECT_TYPE', data);
    },
    updateFirmwareOrSoftwareType: (
      { commit }: UpdateWizardActionType,
      data: SubjectType
    ) => {
      commit('SET_FIRMWARE_OR_SOFTWARE_TYPE', data);
      // TODO unselect when selecting an cli template or update packets
      // commit('UNSELECT_UPDATE_PACKET');
      // commit('UNSELECT_CLI_TEMPLATE');
    },
    setSelectedDevices: (
      { commit }: UpdateWizardActionType,
      data: Device[]
    ) => {
      commit('SET_SELECTED_DEVICES', data);
    },
    updateAlertNoDevices: (
      { commit }: UpdateWizardActionType,
      data: boolean
    ) => {
      commit('SET_NO_DEVICES_ALERT', data);
    },
    updateAlertNoGroups: (
      { commit }: UpdateWizardActionType,
      data: boolean
    ) => {
      commit('SET_NO_GROUPS_ALERT', data);
    },
    updateAlertNoPacket: (
      { commit }: UpdateWizardActionType,
      data: boolean
    ) => {
      commit('SET_NO_PACKET_ALERT', data);
    },
    updateAlertNoTemplate: (
      { commit }: UpdateWizardActionType,
      data: boolean
    ) => {
      commit('SET_NO_TEMPLATE_ALERT', data);
    },
    selectDevice: ({ commit }: UpdateWizardActionType, data: Device) => {
      commit('ADD_SELECTED_DEVICE', data);
    },
    unselectDevice: ({ commit }: UpdateWizardActionType, data: Device) => {
      commit('REMOVE_SELECTED_DEVICE', data);
    },
    updateTargetType: (
      { commit }: UpdateWizardActionType,
      payload: TargetType
    ) => {
      commit('SET_TARGET_TYPE', payload);
    },
    setSelectedGroups: (
      { commit }: UpdateWizardActionType,
      data: DeviceGroup[]
    ) => {
      commit('SET_SELECTED_GROUPS', data);
    },
    selectGroup: ({ commit }: UpdateWizardActionType, data: DeviceGroup) => {
      commit('ADD_SELECTED_GROUP', data);
    },
    unselectGroup: ({ commit }: UpdateWizardActionType, data: DeviceGroup) => {
      commit('REMOVE_SELECTED_GROUP', data);
    },
    updateMethodType: (
      { commit }: UpdateWizardActionType,
      data: ChooseMethod | null
    ) => {
      commit('SET_METHOD_TYPE', data);
    },
    getSoftwareList: async ({ commit }: UpdateWizardActionType) => {
      try {
        const response = await AutoUpdateServerApi.getSoftwareList();
        const data = response.data;

        commit('SET_INSYS_UPDATE_PACKETS', data.members);
      } catch (err) {
        commit('SET_ALERT_TEXT', err.message);
      }
    },
    selectUpdatePacket: (
      { commit }: UpdateWizardActionType,
      data: UpdatePacketType
    ) => {
      commit('SELECT_UPDATE_PACKET', data);
    },
    unselectUpdatePacket: ({ commit }: UpdateWizardActionType) => {
      commit('UNSELECT_UPDATE_PACKET');
    },
    selectCliTemplate: (
      { commit }: UpdateWizardActionType,
      data: CliTemplateType
    ) => {
      commit('SELECT_CLI_TEMPLATE', data);
    },
    unselectCliTemplate: ({ commit }: UpdateWizardActionType) => {
      commit('UNSELECT_CLI_TEMPLATE');
    },
    setSelectedCliTemplateCommonValues: (
      { commit }: UpdateWizardActionType,
      data: string
    ) => {
      commit('SET_SELECTED_CLI_TEMPLATE_COMMON_VALUES', data);
    },
    setSelectedCliTemplateTargetValues: (
      { commit }: UpdateWizardActionType,
      data: string
    ) => {
      commit('SET_SELECTED_CLI_TEMPLATE_TARGET_VALUES', data);
    },
    setCliTableRows: ({ commit }: UpdateWizardActionType, data: any[]) => {
      commit('SET_CLI_TABLE_ROW_VALUES', data);
    },
    updateSelectedUpdatePacketFlavor: (
      { commit }: UpdateWizardActionType,
      data: string
    ) => {
      commit('SET_SELECTED_UPDATE_PACKET_FLAVOR', data);
    },
    resetSubject: ({ commit }: UpdateWizardActionType) => {
      commit('RESET_SUBJECT');
    },
    resetMethod: ({ commit }: UpdateWizardActionType) => {
      commit('RESET_METHOD');
    },
    updateUpdateJobName: ({ commit }: UpdateWizardActionType, data: string) => {
      commit('SET_UPDATE_JOB_NAME', data);
    },
    createDeployment: async (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: UpdateWizardActionType,
      data: DeploymentType | DeploymentTypeCliTemplate
    ) => DeploymentApi.create(data).then((r) => r.data),
    updateServerAlias: ({ commit }: UpdateWizardActionType, data: string) => {
      commit('SET_SERVER_ALIAS', data);
    },
    setUpdatePacketId: ({ commit }: UpdateWizardActionType, data: string) => {
      commit('SET_UPDATE_PACKET_ID', data);
    },
    reset: ({ commit }: UpdateWizardActionType) => {
      commit('RESET');
    },
    setChooseTargetState: (
      { dispatch, commit }: UpdateWizardActionType,
      data: UpdateWizardStateType
    ) => {
      commit('SET_CHOOSE_TARGET_STATE', data);
      dispatch('setAllStepsStateSuccess', data);
    },
    setChooseSubjectState: (
      { dispatch, commit }: UpdateWizardActionType,
      data: UpdateWizardStateType
    ) => {
      commit('SET_CHOOSE_SUBJECT_STATE', data);
      dispatch('setAllStepsStateSuccess', data);
    },
    setChooseMethodState: (
      { dispatch, commit }: UpdateWizardActionType,
      data: UpdateWizardStateType
    ) => {
      commit('SET_CHOOSE_METHOD_STATE', data);
      dispatch('setAllStepsStateSuccess', data);
    },
    setAutoUpdateTime: ({ commit }: UpdateWizardActionType, data: string) => {
      commit('SET_AUTO_UPDATE_TIME', data);
    },
    setStartUpdateJobImmediately: (
      { commit }: UpdateWizardActionType,
      data: boolean
    ) => {
      commit('SET_START_UPDATE_JOB_IMMEDIATELY', data);
    },
    setUpdating({ commit }: UpdateWizardActionType, data: boolean) {
      commit('SET_UPDATING', data);
    },
    getUpdatePacketFromServer: async (
      { commit }: UpdateWizardActionType,
      payload: UpdatePacketFromServerPayloadType
    ) => {
      const updatePacket = {
        description: payload.name,
        fileName: payload.name + '.tar',
        objectPrefix: '/uploads'
      };

      let downloadedPacket: UpdatePacketType | null = null;

      const result = await AutoUpdateServerApi.downloadUpdatePacket(
        payload.url
      );
      const data = result.data;
      downloadedPacket = data;
      const resp = await UpdatePacketApi.create(updatePacket);
      const respData = resp.data;

      const uploadResult = UpdatePacketApi.upload(
        respData.uuid,
        downloadedPacket
      );
      const uploadResultData = (await uploadResult).data;
      commit('SET_UPDATE_PACKET_ID', uploadResultData.uuid);
      return uploadResult;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFinished({ dispatch, commit }: UpdateWizardActionType, data: boolean) {
      commit('SET_FINISHED', data);
    },
    setAllStepsStateSuccess({ commit }: UpdateWizardActionType, data: boolean) {
      commit('SET_ALL_STEPS_STATE_SUCCESS', data);
    },
    closeToRoute({ commit }: UpdateWizardActionType, data: string) {
      router.push(data).then(() => {
        commit('RESET');
      });
    }
  },

  // mutations
  mutations: {
    ['SET_STEP'](state: UpdateWizardStateType, payload: UpdateWizardStep) {
      state.step = payload;
    },
    ['SET_SUBJECT_TYPE'](
      state: UpdateWizardStateType,
      payload: ChooseSubject | null
    ) {
      state.subjectType = payload;
    },
    ['SET_FIRMWARE_OR_SOFTWARE_TYPE'](
      state: UpdateWizardStateType,
      payload: SubjectType
    ) {
      state.firmwareOrSoftwareType = payload;
    },
    ['SET_TARGET_TYPE'](state: UpdateWizardStateType, payload: TargetType) {
      state.targetType = payload;
    },
    ['SET_SELECTED_DEVICES'](state: UpdateWizardStateType, payload: Device[]) {
      state.selectedDevices = payload;
    },
    ['SET_NO_DEVICES_ALERT'](state: UpdateWizardStateType, payload: boolean) {
      state.alertNoDevices = payload;
    },
    ['SET_NO_GROUPS_ALERT'](state: UpdateWizardStateType, payload: boolean) {
      state.alertNoGroups = payload;
    },
    ['SET_NO_PACKET_ALERT'](state: UpdateWizardStateType, payload: boolean) {
      state.alertNoPacket = payload;
    },
    ['SET_NO_TEMPLATE_ALERT'](state: UpdateWizardStateType, payload: boolean) {
      state.alertNoTemplate = payload;
    },
    ['ADD_SELECTED_DEVICE'](state: UpdateWizardStateType, payload: Device) {
      state.selectedDevices.push(payload);
    },
    ['REMOVE_SELECTED_DEVICE'](state: UpdateWizardStateType, payload: Device) {
      state.selectedDevices.splice(state.selectedDevices.indexOf(payload), 1);
    },
    ['SET_SELECTED_GROUPS'](state: UpdateWizardStateType, payload: Device[]) {
      state.selectedGroups = payload;
    },
    ['ADD_SELECTED_GROUP'](state: UpdateWizardStateType, payload: Device) {
      state.selectedGroups.push(payload);
    },
    ['REMOVE_SELECTED_GROUP'](state: UpdateWizardStateType, payload: Device) {
      state.selectedGroups.splice(state.selectedGroups.indexOf(payload), 1);
    },
    ['SET_METHOD_TYPE'](
      state: UpdateWizardStateType,
      payload: ChooseMethod | null
    ) {
      state.methodType = payload;
    },
    ['SET_INSYS_UPDATE_PACKETS'](
      state: UpdateWizardStateType,
      payload: UpdatePacketType[]
    ) {
      state.insysUpdatePackets = payload;
    },
    ['SELECT_UPDATE_PACKET'](
      state: UpdateWizardStateType,
      payload: UpdatePacketType | null
    ) {
      state.selectedUpdatePacket = payload;
    },
    ['UNSELECT_UPDATE_PACKET'](state: UpdateWizardStateType) {
      state.selectedUpdatePacket = null;
    },
    ['SELECT_CLI_TEMPLATE'](
      state: UpdateWizardStateType,
      payload: CliTemplateType | null
    ) {
      state.selectedCliTemplate = payload;
    },
    ['UNSELECT_CLI_TEMPLATE'](state: UpdateWizardStateType) {
      state.selectedCliTemplate = null;
    },
    ['SET_CLI_TABLE_ROW_VALUES'](state: UpdateWizardStateType, payload: any[]) {
      state.cliTableRows = payload;
    },
    ['SET_SELECTED_UPDATE_PACKET_FLAVOR'](
      state: UpdateWizardStateType,
      payload: string
    ) {
      state.selectedUpdatePacketFlavor = payload;
    },
    ['RESET_SUBJECT'](state: UpdateWizardStateType) {
      state.subjectType = null;
      state.insysUpdatePackets = [];
      state.selectedUpdatePacket = null;
      state.selectedCliTemplate = null;
      state.selectedUpdatePacketFlavor = '';
      state.cliTableRows = [];
    },
    ['RESET_METHOD'](state: UpdateWizardStateType) {
      state.methodType = null;
      state.autoUpdateTime = '0:00';
      state.startUpdateJobImmediately = false;
    },
    ['SET_UPDATE_JOB_NAME'](state: UpdateWizardStateType, payload: string) {
      state.updateJobName = payload;
    },
    ['SET_ALERT_TEXT'](state: UpdateWizardStateType, payload: string) {
      state.alertText = payload;
    },
    ['SET_SERVER_ALIAS'](state: UpdateWizardStateType, payload: string) {
      state.serverAlias = payload;
    },
    ['SET_UPDATE_PACKET_ID'](state: UpdateWizardStateType, payload: string) {
      state.updatePacketId = payload;
    },
    ['RESET'](state: UpdateWizardStateType) {
      state.step = UpdateWizardStep.CHOOSE_TARGET;
      state.chooseTargetState = UpdateWizardStepState.INIT;
      state.chooseSubjectState = UpdateWizardStepState.INIT;
      state.chooseMethodState = UpdateWizardStepState.INIT;
      state.subjectType = ChooseSubject.FIRMWARE_OR_SOFTWARE;
      state.firmwareOrSoftwareType = SubjectType.USER_UPDATE_PACKETS;
      state.targetType = TargetType.TYPE_MANAGED_DEVICES;
      state.selectedDevices = [];
      state.selectedGroups = [];
      state.alertNoDevices = false;
      state.alertNoGroups = false;
      state.alertNoPacket = false;
      state.alertNoTemplate = false;
      state.methodType = ChooseMethod.UPDATE_JOB;
      state.insysUpdatePackets = [];
      state.selectedUpdatePacket = null;
      state.selectedCliTemplate = null;
      state.selectedUpdatePacketFlavor = '';
      state.cliTableRows = [];
      state.updateJobName = '';
      state.alertText = '';
      state.updatePacketId = '';
      state.serverAlias = '';
      state.autoUpdateTime = '0:00';
      state.startUpdateJobImmediately = false;
      state.updating = false;
      state.allStepsStateSuccess = false;
    },
    ['SET_CHOOSE_TARGET_STATE'](
      state: UpdateWizardStateType,
      payload: UpdateWizardStepState
    ) {
      state.chooseTargetState = payload;
    },
    ['SET_CHOOSE_SUBJECT_STATE'](
      state: UpdateWizardStateType,
      payload: UpdateWizardStepState
    ) {
      state.chooseSubjectState = payload;
    },
    ['SET_CHOOSE_METHOD_STATE'](
      state: UpdateWizardStateType,
      payload: UpdateWizardStepState
    ) {
      state.chooseMethodState = payload;
    },
    ['SET_UPDATING'](state: UpdateWizardStateType, payload: boolean) {
      state.updating = payload;
    },
    ['SET_AUTO_UPDATE_TIME'](state: UpdateWizardStateType, payload: string) {
      state.autoUpdateTime = payload;
    },
    ['SET_START_UPDATE_JOB_IMMEDIATELY'](
      state: UpdateWizardStateType,
      payload: boolean
    ) {
      state.startUpdateJobImmediately = payload;
    },
    ['SET_FINISHED'](state: UpdateWizardStateType, payload: boolean) {
      state.finished = payload;
    },
    ['SET_ALL_STEPS_STATE_SUCCESS'](
      state: UpdateWizardStateType,
      payload: boolean
    ) {
      if (
        state.chooseTargetState === UpdateWizardStepState.SUCCESS &&
        state.chooseSubjectState === UpdateWizardStepState.SUCCESS &&
        state.chooseMethodState === UpdateWizardStepState.SUCCESS &&
        payload &&
        !state.finished &&
        !state.updating
      ) {
        state.allStepsStateSuccess = true;
      } else {
        state.allStepsStateSuccess = false;
      }
    }
  }
};
