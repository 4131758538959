/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { CreateApiTokenType } from '@/store/types/admin';

export default {
  find() {
    return Api.get('admin/access-tokens');
  },
  create(token: CreateApiTokenType) {
    return Api.post('admin/access-tokens', token);
  },
  delete(id: string) {
    return Api.delete(`admin/access-tokens/${id}`);
  }
};
