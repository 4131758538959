/* eslint-disable @typescript-eslint/naming-convention, prefer-arrow/prefer-arrow-functions */
import UpdatePacketApi from '@/services/autoupdate/UpdatePacketApi';
import { constants } from '@/models/autoupdate/constants';
import { findItemIndexById } from '@/store/utils';
import {
  UpdatePacketActionType,
  UpdatePacketContentType,
  UpdatePacketStateType,
  UpdatePacketType,
  UUID
} from '@/store/types';
import { i18n } from '@/plugins/i18n';
import { showApiErrorSnackbar, showSnackbar, createErrorMessage } from '@/util/snackBarUtil';
import { downloadFile } from '@/util/downloadUtil';

export default {
  namespaced: true,

  // initial state
  state: (): UpdatePacketStateType => ({
    updatePackets: [],
    selectedFiles: [],
    uploadPackageList: [],
    updatePacketIds: [],
    loading: false,
    filteredUpdatePackets: [],
    visibilityFilter: false,
    selectedPackets: [],
    updatePacket: null,
    visibilityResources: false,
    visibleUpdatePackets: [],
    contentsFileType: new Map(),
    search: ''
  }),

  // getters
  getters: {
    loading(state: UpdatePacketStateType) {
      return state.loading;
    },
    updatePackages(state: UpdatePacketStateType) {
      // Extract filetypes of content of updatePackets to make filtering easy
      state.updatePackets.forEach((updatePacket) => {
        updatePacket.fileTypes = updatePacket.contents.map(
          (content) => content.fileType
        );
      });
      return state.updatePackets;
    },
    updatePackagesType(state: UpdatePacketStateType) {
      return state.updatePackets;
    },
    availableUpdatePackages(state: UpdatePacketStateType) {
      return state.updatePackets.filter(
        (up) =>
          up.status !== undefined &&
          up.status !== constants.UPDATE_PACKET_STATUS_UPLOADING
      );
    },
    selectedFiles(state: UpdatePacketStateType) {
      return state.selectedFiles;
    },
    uploadList(state: UpdatePacketStateType) {
      return state.uploadPackageList;
    },
    packetsSelected(state: UpdatePacketStateType) {
      return state.selectedPackets;
    },
    showDetails(state: UpdatePacketStateType) {
      return state.selectedPackets.length > 0;
    },
    contentsFileType(state: UpdatePacketStateType) {
      return state.contentsFileType;
    }
  },

  // actions
  actions: {
    async loadUpdatePackets({ dispatch, commit }: UpdatePacketActionType) {
      await dispatch('isLoading');
      await dispatch('resetSelected');
      try {
        const response = await UpdatePacketApi.find();
        const data = await response.data;
        commit('SET_UPDATE_PACKAGES', data.members);
        commit('UPDATE_FILETYPES');
        await dispatch('clearLoading');
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },
    isLoading: ({ commit }: UpdatePacketActionType) => {
      commit('LOADING');
    },
    clearLoading({ commit }: UpdatePacketActionType) {
      commit('CLEAR_LOADING');
    },
    selectFiles: ({ commit }: UpdatePacketActionType, data: File[]) => {
      commit('SELECT_FILES', data);
    },
    uploadPackageList: ({ commit }: UpdatePacketActionType, data: []) => {
      commit('UPLOAD_PACKAGE_LIST', data);
    },
    async uploadUpdatePackets({
      dispatch,
      commit,
      state
    }: UpdatePacketActionType) {
      await dispatch('isLoading');
      await dispatch('resetSelected');
      for (const { index, updatePacket } of state.uploadPackageList.map(
        (updPacket: UpdatePacketType, idx: number) => ({
          index: idx,
          updatePacket: updPacket
        })
      )) {
        let data = null;
        try {
          const response = await UpdatePacketApi.create(updatePacket);
          data = await response.data;
        } catch (error) {
          showApiErrorSnackbar(createErrorMessage(error));
          continue;
        }
        const fileToUpload = state.selectedFiles[index];
        try {
          const uploadResponse = await UpdatePacketApi.upload(
            (data as any)?.uuid || '', // TODO:
            fileToUpload
          );

          const packetResponse = await UpdatePacketApi.getById(
            (data as any).uuid || ''
          ); // TODO:
          const uploadData = await uploadResponse.data;
          commit('SET_UPDATE_PACKET_IDS', uploadData.uuid);
          commit('ADD_PACKAGE', packetResponse.data);
          showSnackbar(
            `${i18n.t('updatePackages.uploadUpdatePackageSuccess', {
              msg: fileToUpload.name
            })}`
          );
        } catch (error) {
          try {
            await UpdatePacketApi.delete((data as any)?.uuid || ''); // TODO:
          } catch (e) {
            // do not display anything, this can fail silently.
            // all that happens is a UpdatePackage with status UPLOADING
            // showApiErrorSnackbar(createErrorMessage(error));
          }
          showApiErrorSnackbar(createErrorMessage(error), error);
        }
      }
      // await dispatch('loadUpdatePackets');
      await dispatch('clearLoading');
    },
    async createUpdatePacket(
      { dispatch }: UpdatePacketActionType,
      payload: UpdatePacketType
    ) {
      try {
        await UpdatePacketApi.create(payload);
        await dispatch('loadUpdatePackets');
        showSnackbar(
          i18n.t('resources.dialogCreatedUpdatePackages', {
            msg: payload.name
          }).toString()
        );
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },
    setAlertText: ({ commit }: UpdatePacketActionType, payload: string) => {
      commit('SET_ALERT_TEXT', payload);
    },
    selectPackages: (
      { commit }: UpdatePacketActionType,
      payload: UpdatePacketType[]
    ) => {
      commit('SELECT_PACKAGES', payload);
    },
    unselectPackage: (
      { commit }: UpdatePacketActionType,
      payload: UpdatePacketType
    ) => {
      commit('UNDO_SELECT_PACKET', payload);
    },
    resetSelected: ({ commit }: UpdatePacketActionType) => {
      commit('RESET_SELECTED_PACKETS');
    },
    async updatePackage(
      { commit }: UpdatePacketActionType,
      payload: UpdatePacketType
    ) {
      try {
        const result = await UpdatePacketApi.update(payload);
        const data = await result.data;
        commit('ADD_PACKAGE', data);
        commit('ADD_SELECTED_PACKAGE', data);
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },
    async delete({ dispatch }: UpdatePacketActionType, payload: UpdatePacketType[]) {
      const promises: Promise<any>[] = [];
      payload.filter(
        (updatePackage: UpdatePacketType) =>
          updatePackage.status !== constants.UPDATE_PACKET_STATUS_LINKED
      )
        .forEach((updatePacket: UpdatePacketType) => {
          promises.push(UpdatePacketApi.delete(updatePacket.uuid));
        });
      return Promise.all(promises).then(() => {
        dispatch('loadUpdatePackets');
        dispatch('resetSelected');
      });
    },
    sortPackagesSelectedFirst({ commit }: UpdatePacketActionType) {
      commit('SORT_PACKAGES_SELECTED_FIRST');
    },
    download(_: any, payload: UpdatePacketType) {
      return UpdatePacketApi.download(payload.uuid)
        .then((r) => r.data)
        .then((data) => {
          const fileName = payload.fileName;
          downloadFile(data, fileName);
        });
    }
  },
  // mutations
  mutations: {
    ['LOADING'](state: UpdatePacketStateType) {
      state.loading = true;
    },
    ['CLEAR_LOADING'](state: UpdatePacketStateType) {
      state.loading = false;
    },
    ['SET_UPDATE_PACKAGES'](
      state: UpdatePacketStateType,
      payload: UpdatePacketType[]
    ) {
      // any needed mapping here
      state.updatePackets = payload.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      );
      state.filteredUpdatePackets = payload;
    },
    ['SELECT_FILES'](state: UpdatePacketStateType, payload: File[]) {
      const uploadPackages = Array.from(payload).map((file) => ({
        uuid: '',
        fileName: file.name,
        description: '',
        objectPrefix: '/',
        contents: []
      }));
      state.selectedFiles = payload;
      state.uploadPackageList = uploadPackages;
    },
    ['UNSELECT_FILES'](state: UpdatePacketStateType) {
      state.selectedFiles = [];
    },
    ['UNSELECT_FILE'](state: UpdatePacketStateType, payload: number) {
      Array.from(state.selectedFiles).splice(payload, 1);
    },
    ['UPLOAD_PACKAGE_LIST'](
      state: UpdatePacketStateType,
      payload: UpdatePacketType[]
    ) {
      state.uploadPackageList = payload;
    },
    ['SET_UPDATE_PACKET_IDS'](state: UpdatePacketStateType, payload: UUID) {
      state.updatePacketIds.push(payload);
    },
    ['SELECT_PACKAGES'](
      state: UpdatePacketStateType,
      payload: UpdatePacketType[]
    ) {
      state.selectedPackets = payload;
    },
    ['UNDO_SELECT_PACKET'](
      state: UpdatePacketStateType,
      payload: UpdatePacketType
    ) {
      state.selectedPackets.splice(state.selectedPackets.indexOf(payload), 1);
    },
    ['RESET_SELECTED_PACKETS'](state: UpdatePacketStateType) {
      state.selectedPackets = [];
    },
    ['UPDATE_PACKET'](state: UpdatePacketStateType, payload: UpdatePacketType) {
      state.updatePacket = payload;
    },
    ['ADD_PACKAGE'](state: UpdatePacketStateType, payload: UpdatePacketType) {
      const index = findItemIndexById(state.updatePackets, payload.uuid);
      if (index === -1) {
        state.updatePackets.push(payload);
      } else {
        state.updatePackets.splice(index, 1, payload);
      }
      state.updatePackets = state.updatePackets.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      );
    },
    ['ADD_SELECTED_PACKAGE'](
      state: UpdatePacketStateType,
      payload: UpdatePacketType
    ) {
      const index = findItemIndexById(state.selectedPackets, payload.uuid);
      if (index === -1) {
        state.selectedPackets.push(payload);
      } else {
        state.selectedPackets.splice(index, 1, payload);
      }
    },
    ['SORT_PACKAGES_SELECTED_FIRST'](state: UpdatePacketStateType) {
      state.updatePackets = state.updatePackets.sort((a, b) =>
        state.selectedPackets.includes(a) === state.selectedPackets.includes(b)
          ? 0
          : state.selectedPackets.includes(a)
            ? -1
            : 1
      );
    },
    ['UPDATE_FILETYPES'](state: UpdatePacketStateType) {
      const contentFileTypes = new Map();
      let contentFirmware: string[] = [];
      let contentContainer: string[] = [];
      let contentConfiguration: string[] = [];
      let contentLicence: string[] = [];

      state.updatePackets.forEach((UpdatePaket: UpdatePacketType) => {
        UpdatePaket.contents?.forEach((content: UpdatePacketContentType) => {
          if (
            content.fileType ===
              constants.RESOURCE_FILE_TYPE_INCREMENTAL_SOFTWARE_UPDATE ||
            content.fileType ===
              constants.RESOURCE_FILE_TYPE_FULL_SOFTWARE_UPDATE
          ) {
            contentFirmware.push(content.fileType);
          } else if (
            content.fileType === constants.RESOURCE_FILE_TYPE_CONTAINER ||
            content.fileType ===
              constants.RESOURCE_FILE_TYPE_CONTAINER_CONFIGURATION
          ) {
            contentContainer.push(content.fileType);
          } else if (
            content.fileType ===
              constants.RESOURCE_FILE_TYPE_BINARY_CONFIGURATION ||
            content.fileType === constants.RESOURCE_FILE_TYPE_ASCII_CONFIG ||
            content.fileType ===
              constants.RESOURCE_FILE_TYPE_STORED_ASCII_CONFIG
          ) {
            contentConfiguration.push(content.fileType);
          } else if (
            content.fileType === constants.RESOURCE_FILE_TYPE_LICENCE
          ) {
            contentLicence.push(content.fileType);
          }
        });
      });

      contentFirmware = [...new Set(contentFirmware)];
      contentContainer = [...new Set(contentContainer)];
      contentConfiguration = [...new Set(contentConfiguration)];
      contentLicence = [...new Set(contentLicence)];

      contentFileTypes.set(i18n.t('common.filterByFirmware'), contentFirmware);
      contentFileTypes.set(
        i18n.t('common.filterByContainer'),
        contentContainer
      );
      contentFileTypes.set(
        i18n.t('common.filterByConfiguration'),
        contentConfiguration
      );
      contentFileTypes.set(i18n.t('common.filterByLicence'), contentLicence);
      state.contentsFileType = contentFileTypes;
    }
  }
};
